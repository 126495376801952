import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import {
    AUTOCOMPLETEFINANCIAL,
    DELETEFINANCIAL,
    GENEXCELSTATEMENT,
    GENPDFSTATEMENT,
    GET,
    GETDETAILFINANCIAL,
    INSERTDATAFINANCIAL,
    POST,
    SEARCHFINANCIAL,
    UPDATEDATAFINANCIAL,
    ip,
} from "../../service";
import FinancialList from "./components/FinancialList";
import DialogEdit from "./components/DialogEdit";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import { debounce } from "@mui/material";
import axios from "axios";

function FinancialStatement() {
    const [data, setData] = useState([]);
    const [refDoc, setRefDoc] = useState("");
    const [accountNo, setAccountNo] = useState("");
    const [accountName, setAccountName] = useState("");
    const [credit, setCredit] = useState("");
    const [debit, setDebit] = useState("");
    const [detail, setDetail] = useState("");
    const [financeDate, setFinanceDate] = useState("");
    const [loading, setLoading] = useState(false);
    const [dateStartSearch, setDateStartSearch] = useState(dayjs().startOf("month"));
    const [dateEndSearch, setDateEndSearch] = useState(dayjs());
    const [dateInput, setDateInput] = useState(dayjs());
    const [openEdit, setOpenEdit] = useState(false);
    const [dataEdit, setDataEdit] = useState({
        id: "",
        ref_doc: "",
        account_no: "",
        account_name: "",
        credit: "",
        debit: "",
        finance_date: "",
        detail: "",
    });
    const [search, setSearch] = useState("");
    const [dataAuto, setDataAuto] = useState([]);

    const token = localStorage.getItem("uuid");

    const getData = async (id) => {
        try {
            setLoading(true);
            let res = await POST(GETDETAILFINANCIAL, { date_start: dateStartSearch, date_end: dateEndSearch });
            if (res.success) {
                // console.log(res.result);
                setData(res.result);
                setLoading(false);
                if (id) {
                    setDataEdit(res.result.find((e) => e.id === id));
                }
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const getDataAutocomplete = async () => {
        try {
            setLoading(true);
            let res = await GET(AUTOCOMPLETEFINANCIAL);
            if (res.success) {
                setDataAuto(res.result);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const updateData = async (id) => {
        try {
            setLoading(true);
            let res = await POST(UPDATEDATAFINANCIAL, {
                ...dataEdit,
            });
            if (res.success) {
                setData(res.result);
                await refreshData();
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const refreshData = async () => {
        try {
            const response = await POST(GETDETAILFINANCIAL);
            if (response.success) {
                setData(response.result);
            }
        } catch (error) {
            console.error("อัพเดทข้อมูลล้มเหลว:", error);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        let res = await POST(INSERTDATAFINANCIAL, {
            ref_doc: refDoc,
            account_no: accountNo,
            account_name: accountName,
            debit,
            credit,
            detail,
            finance_date: dateInput,
        });
        setLoading(false);
        if (res.success) {
            await getData(); // เรียกข้อมูลใหม่
            resetForm();
        } else {
            Swal.fire({
                icon: "warning",
                text: `${res.message}`,
                showConfirmButton: true,
            });
        }
    };

    const resetForm = () => {
        setRefDoc("");
        setAccountName("");
        setAccountNo("");
        setDebit("");
        setCredit("");
        setDetail("");
    };

    const handleDelete = (id) => {
        try {
            setLoading(true);
            setLoading(false);
            Swal.fire({
                title: "ลบข้อมูล",
                text: "คุณต้องการลบข้อมูลหรือไม่?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "ยืนยัน",
                confirmButtonColor: "green",
                cancelButtonText: "ยกเลิก",
                reverseButtons: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let res = await POST(DELETEFINANCIAL, { id });
                    if (res.success) {
                        getData();
                        setLoading(false);
                        Swal.fire({
                            text: "ลบข้อมูลเรียบร้อย",
                            confirmButtonText: "ตกลง",
                        });
                    } else {
                        Swal.fire({
                            icon: "warning",
                            text: `${res.message}`,
                            showConfirmButton: true,
                        });
                    }
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire({
                        text: "ยกเลิกรายการแล้ว",
                        confirmButtonText: "ตกลง",
                    });
                }
            });
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const fetch = useMemo(
        () =>
            debounce(async (request) => {
                try {
                    setLoading(true);
                    const payload = { text: request, date_start: dateStartSearch, date_end: dateEndSearch };
                    let res = await POST(SEARCHFINANCIAL, payload);
                    if (res.success) {
                        setData(res.result);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        Swal.fire({
                            icon: "warning",
                            text: `${res.message}`,
                            showConfirmButton: true,
                        });
                    }
                } catch (error) {
                    setLoading(false);
                    Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
                }
            }, 1500),
        [search, dateStartSearch, dateEndSearch]
    );

    const handleClickOpenEdit = (id) => {
        getData(id);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const genExcel = async () => {
        setLoading(true);
        try {
            const res = await axios.post(
                ip + GENEXCELSTATEMENT,
                { date_start: dateStartSearch, date_end: dateEndSearch, text: search },
                {
                    responseType: "blob",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token || "token",
                    },
                }
            );

            const excelBlob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const fileURL = URL.createObjectURL(excelBlob);
            const a = document.createElement("a");
            a.href = fileURL;
            a.download = "financial_statement_report.xlsx";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(fileURL);
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error.response ? error.response.data : error.message}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const genPDF = async () => {
        try {
            await axios
                .post(
                    ip + GENPDFSTATEMENT,
                    { date_start: dateStartSearch, date_end: dateEndSearch, text: search },
                    {
                        responseType: "blob",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: token ? token : "token",
                        },
                    }
                )
                .then(async (res) => {
                    const pdfBlob = new Blob([res.data], {
                        type: "application/pdf",
                    });
                    const fileURL = URL.createObjectURL(pdfBlob);
                    window.open(fileURL);
                });
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    useEffect(() => {
        getData();
        getDataAutocomplete();
    }, [dateStartSearch, dateEndSearch]);

    useEffect(() => {
        if (search.length > 1) {
            fetch(search);
        } else if (!search) {
            getData();
        }
    }, [search, fetch, dateStartSearch, dateEndSearch]);

    return (
        <>
            <BackdropLoad loading={loading} />
            <FinancialList
                data={data}
                dateStartSearch={dateStartSearch}
                setDateStartSearch={setDateStartSearch}
                dateEndSearch={dateEndSearch}
                setDateEndSearch={setDateEndSearch}
                handleSubmit={handleSubmit}
                refDoc={refDoc}
                setRefDoc={setRefDoc}
                accountNo={accountNo}
                setAccountNo={setAccountNo}
                accountName={accountName}
                setAccountName={setAccountName}
                debit={debit}
                setDebit={setDebit}
                credit={credit}
                setCredit={setCredit}
                detail={detail}
                setDetail={setDetail}
                financeDate={financeDate}
                setFinanceDate={setFinanceDate}
                dateInput={dateInput}
                setDateInput={setDateInput}
                handleClickOpenEdit={handleClickOpenEdit}
                handleDelete={handleDelete}
                search={search}
                setSearch={setSearch}
                dataAuto={dataAuto}
                genExcel={genExcel}
                genPDF={genPDF}
            />
            <DialogEdit
                handleUpdate={updateData}
                open={openEdit}
                setEdit={setDataEdit}
                handleCloseEdit={handleCloseEdit}
                handleClose={handleCloseEdit}
                refDoc={refDoc}
                setRefDoc={setRefDoc}
                accountNo={accountNo}
                setAccountNo={setAccountNo}
                accountName={accountName}
                setAccountName={setAccountName}
                debit={debit}
                setDebit={setDebit}
                credit={credit}
                setCredit={setCredit}
                detail={detail}
                setDetail={setDetail}
                data={dataEdit}
            />
        </>
    );
}

export default FinancialStatement;
