import { Box, Container, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { VendorIcon } from "../../../untils/icons.js";
import GridTable from "./GridTable.js";

function INSCLCard({ data, header, setSearch, search, handleChangeText, message }) {
    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12} spacing={2}>
                    <Grid item xs={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <VendorIcon fontSize="medium" />
                            <Typography className="main-header">สิทธิการรักษา</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} className="flex-center">
                        <Container maxWidth="md" sx={{ position: "relative" }}>
                            <Stack width="100%" direction="row" spacing={2}>
                                <TextField
                                    fullWidth
                                    label="เลขบัตรประชาชน"
                                    name="id_card"
                                    type="tel"
                                    value={search}
                                    variant="outlined"
                                    onChange={(e) => setSearch(handleChangeText(e))}
                                    disabled
                                />
                            </Stack>
                        </Container>
                    </Grid>
                </Grid>
                <Grid item xs={12} my={2} className="flex-center">
                    <GridTable header={header} data={data} message={message} />
                </Grid>
            </Paper>
        </Box>
    );
}

export default INSCLCard;
