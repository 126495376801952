import { Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";

import { StyledTblK103 } from "../../../Theme";

export default function GridTable({ dataTbl2 }) {
    return (
        <Paper sx={{ width: "100%" }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTblK103 rowSpan={2} style={{ minWidth: 300, textAlign: "center" }}>
                                ชื่อ รพ.สต.
                            </StyledTblK103>

                            {dataTbl2.length > 0 &&
                                dataTbl2[0].datas?.map((label) => (
                                    <StyledTblK103 colSpan={2} style={{ minWidth: 300, textAlign: "center" }}>
                                        {label.header}
                                    </StyledTblK103>
                                ))}
                        </TableRow>
                        <TableRow>
                            {dataTbl2.length > 0 &&
                                dataTbl2[0].datas?.map((label) => (
                                    <>
                                        <StyledTblK103 style={{ minWidth: 150, textAlign: "center" }}>จำนวน</StyledTblK103>
                                        <StyledTblK103 style={{ minWidth: 150, textAlign: "center" }}>ร้อยละ</StyledTblK103>
                                    </>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataTbl2.map((item) => {
                            return (
                                <TableRow style={{ backgroundColor: "#FAFAFA" }}>
                                    <StyledTblK103 align="left">{item.hname}</StyledTblK103>
                                    {item.datas.map((item_data) => {
                                        return (
                                            <>
                                                <StyledTblK103 style={{ minWidth: 150, textAlign: "right" }}>{item_data.qty_status}</StyledTblK103>
                                                <StyledTblK103 style={{ minWidth: 150, textAlign: "right" }}>{item_data.percent}</StyledTblK103>
                                            </>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                {dataTbl2.length === 0 && (
                    <Grid container justifyContent="center" my={3}>
                        <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                    </Grid>
                )}
            </TableContainer>
        </Paper>
    );
}
