import { Grid } from "@mui/material";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function BarChartAge({ ageData }) {
    const options = {
        indexAxis: "y",
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            datalabels: {
                display: function (context) {
                    return context.chart.isDatasetVisible(context.datasetIndex);
                },
                borderRadius: 4,
                align: "end",
                anchor: "end",
                color: "black",
                backgroundColor: "#BEBEBD",
            },
            legend: {
                display: false,
            },
        },
        scales: {
            x1: {
                position: "right",
                ticks: {
                    callback: function (value) {
                        return this.getLabelForValue(value).split(";")[0];
                    },
                },
            },
            x: {
                ticks: {
                    color: "white",
                    beginAtZero: true,
                },
                grid: {
                    display: false, // Hide x grid
                },
            },
        },
    };

    const labels = ageData.map((e) => (e.groupAge !== "" ? e.groupAge.replace("โรงพยาบาลส่งเสริมสุขภาพตำบล", "รพ. สต.") : "ไม่ระบุ"));

    const data = {
        labels,
        datasets: [
            {
                label: "จำนวนครั้ง",
                data: ageData.map((e) => e.count),
                borderColor: "rgb(54, 162, 235)",
                backgroundColor: "rgba(54, 162, 235, 0.5)",
                yAxisID: "x1",
            },
        ],
    };
    return (
        <Grid container columns={12}>
            <Grid
                item
                xs={12}
                className="flex-center"
                style={{ overflowX: "auto", overflowY: "auto", minHeight: "315px", width: "100%" }}
            >
                {/* {information.length > 0 ?
                    <Bar data={data} options={options} />
                    :
                    <Box className="census-info-empty">
                        <Typography>
                            * ไม่มีข้อมูล *
                        </Typography>
                    </Box>
                } */}
                <Bar data={data} options={options} />
            </Grid>
        </Grid>
    );
}
