import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import GridTableHosp from "./GridTableHosp";
import GridTable from "./GridTable";
import BarChartVertical from "../K101/BarChartVertical";
import { Dashboard } from "../../../untils/icons";
import { headerTable } from "../../../untils/static";

function K101Card({
  profile,
  yearsData,
  year,
  setYear,
  tablePregnant,
  tablePregnantTarget,
  targetValue,
  hname,
  dataHname,
  setHname,
  onSelect,
}) {
  const [cleared, setCleared] = useState(false);

  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);
  console.log(year);
  return (
    <Container maxWidth="xl" sx={{ position: "relative" }}>
      <Box display="flex" justifyContent="center">
        <Paper className="paper-bg" elevation={0}>
          <Grid container columns={12} spacing={3}>
            <Grid item xs={12} className="flex-start">
              <Stack direction="row" spacing={2}>
                <Dashboard fontSize="medium" />
                <Typography className="main-header">
                  K101 ร้อยละของหญิงตั้งครรภ์และหญิงหลังคลอดได้รับบริการตามเกณฑ์คุณภาพ
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={3} mt={3}>
              <FormControl fullWidth>
                <InputLabel shrink>ปีงบประมาณ</InputLabel>
                <Select
                  name="year"
                  value={year}
                  label="เลือกปีงบประมาณ"
                  required
                  onChange={(e) => setYear(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    เลือกปีงบประมาณ
                  </MenuItem>
                  {yearsData.map((year) => (
                    <MenuItem key={year.value} value={year.value}>
                      {Number(year.value) + 543}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3} mt={3}>
              <Autocomplete
                disabled={profile.role_id !== 1 && profile.role_id !== 2}
                fullWidth
                getOptionLabel={(option) => option}
                options={dataHname}
                value={hname}
                onChange={(event, newValue) => {
                  onSelect("hname", newValue);
                }}
                renderInput={(params) => <TextField {...params} label="เลือกรพ.สต." />}
              />
            </Grid>

            {/* Bar Chart */}
            <Grid item xs={12}>
              <Box className="census-info-frame-header">
                <Typography className="text-info-header">
                  ร้อยละของหญิงตั้งครรภ์และหญิงหลังคลอดได้รับบริการตามเกณฑ์คุณภาพ
                </Typography>
              </Box>
              <Box className="census-info-frame">
                <BarChartVertical data={tablePregnantTarget} targetValue={targetValue} />
              </Box>
            </Grid>

            {/* Table */}
            <Grid item xs={12} my={3}>
              <GridTableHosp data={tablePregnant} header={headerTable.K101_TablePregnant} />
            </Grid>

            {/* Table ที่แสดงค่าเป้าหมาย 81*/}
            <Grid item xs={12}>
              <GridTable data={tablePregnantTarget} header={headerTable.K101_TablePregnantTarget} />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
}

export default K101Card;
