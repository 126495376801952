import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';

function createData(name, percent) {
  return { name, percent};
}

const rows = [
  createData('เมืองมุกดาหาร', 31.85),
  createData('นิคมคำสร้อย', 12.40),
  createData('ดอนตาล', 12.13),
  createData('ดงหลวง', 15.17),
  createData('คำชะอี', 11.81),
  createData('หว้านใหญ่', 10.55),
  createData('หนองสูง', 10.43),
];

export default function DataTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 150 }} aria-label="simple table">
        {/* <TableHead>
          <TableRow>
            <TableCell>อำเภอ</TableCell>
            <TableCell align="right">ความผิดปกติของข้อมูล(%)</TableCell>
          </TableRow>
        </TableHead> */}
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.percent.toLocaleString(undefined, { maximumFractionDigits: 2 })}%</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
