import { Autocomplete, Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import { Statement } from "../../../untils/icons";
import dayjs from "dayjs";
import FinalcialTable from "./FinancialTable";
import { headerTable } from "../../../untils/static";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import { Link } from "react-router-dom";

require("dayjs/locale/th");

function FinancialList({
    data,
    dateStartSearch,
    setDateStartSearch,
    dateEndSearch,
    setDateEndSearch,
    setCleared,
    handleSubmit,
    refDoc,
    setRefDoc,
    accountNo,
    setAccountNo,
    accountName,
    setAccountName,
    debit,
    setDebit,
    credit,
    setCredit,
    detail,
    setDetail,
    dateInput,
    setDateInput,
    handleClickOpenEdit,
    handleDelete,
    search,
    setSearch,
    dataAuto,
    genExcel,
    genPDF,
}) {
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    };

    const [refDocAll, setRefDocAll] = useState("");
    console.log("data:", data);
    // console.log("dateStartSearch:", dateStartSearch);
    // console.log("dateEndSearch:", dateEndSearch);

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12} spacing={2} mb={5}>
                    <Grid item xs={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <Statement fontSize="medium" />
                            <Typography className="main-header">รายการเงินสด</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Autocomplete
                            fullWidth
                            freeSolo
                            id="refDocAll"
                            disableClearable
                            options={dataAuto.map((option) => ({
                                ...option,
                                label: `${option.ref_doc} ${option.account_no} ${option.account_name}`,
                                value: `${option.ref_doc} ${option.account_no} ${option.account_name}`,
                            }))}
                            value={refDocAll}
                            onInputChange={(_, value) => {
                                setRefDocAll(value);
                            }}
                            onChange={(option, value) => {
                                setRefDoc(value.ref_doc);
                                setAccountNo(value.account_no);
                                setAccountName(value.account_name);
                            }}
                            onKeyPress={handleKeyPress}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="อ้างอิงเอกสาร / เลขบัญชี / ชื่อบัญชี"
                                    slotProps={{
                                        input: {
                                            ...params.InputProps,
                                            type: "search",
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container columns={12} spacing={1} mb={2} className="flex-start">
                            <Grid item xs={12} sm={12} md={2}>
                                <Stack width="100%" direction="row" spacing={1}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateInput)}>
                                            <DatePicker
                                                // fullWidth
                                                value={dateInput}
                                                slotProps={{
                                                    field: { clearable: true, onClear: () => setCleared(true) },
                                                }}
                                                label="วันที่"
                                                format="DD/MM/YYYY"
                                                onChange={(e) => setDateInput(e)}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={10}>
                                <Stack width="100%" direction="row" spacing={1}>
                                    <Autocomplete
                                        fullWidth
                                        freeSolo
                                        id="refDoc"
                                        disableClearable
                                        options={dataAuto.map((option) => ({ ...option, label: option.ref_doc, value: option.ref_doc }))}
                                        value={refDoc}
                                        onInputChange={(_, value) => {
                                            setRefDoc(value);
                                        }}
                                        onKeyPress={handleKeyPress}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="อ้างอิงเอกสาร"
                                                slotProps={{
                                                    input: {
                                                        ...params.InputProps,
                                                        type: "search",
                                                    },
                                                }}
                                            />
                                        )}
                                    />

                                    <Autocomplete
                                        fullWidth
                                        freeSolo
                                        id="accountNo"
                                        disableClearable
                                        options={dataAuto.map((option) => ({ ...option, label: option.account_no, value: option.account_no }))}
                                        value={accountNo}
                                        onInputChange={(_, value) => {
                                            setAccountNo(value);
                                        }}
                                        onKeyPress={handleKeyPress}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="เลขบัญชี"
                                                slotProps={{
                                                    input: {
                                                        ...params.InputProps,
                                                        type: "search",
                                                    },
                                                }}
                                            />
                                        )}
                                    />

                                    <Autocomplete
                                        fullWidth
                                        freeSolo
                                        id="accountName"
                                        disableClearable
                                        options={dataAuto.map((option) => ({
                                            ...option,
                                            label: option.account_name,
                                            value: option.account_name,
                                        }))}
                                        value={accountName}
                                        onInputChange={(_, value) => setAccountName(value)}
                                        onKeyPress={handleKeyPress}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="ชื่อบัญชี"
                                                slotProps={{
                                                    input: {
                                                        ...params.InputProps,
                                                        type: "search",
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container columns={12} spacing={1} className="flex-start">
                            <Grid item xs={12} sm={12} md={4}>
                                <Stack width="100%" direction="row" spacing={1}>
                                    <TextField
                                        fullWidth
                                        id="debit"
                                        variant="outlined"
                                        label="เดบิต"
                                        value={debit}
                                        onChange={(e) => setDebit(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                    />
                                    <TextField
                                        fullWidth
                                        id="credit"
                                        variant="outlined"
                                        label="เครดิต"
                                        value={credit}
                                        onChange={(e) => setCredit(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Stack width="100%" direction="row" spacing={1}>
                                    <TextField
                                        fullWidth
                                        id="detail"
                                        variant="outlined"
                                        label="หมายเหตุ"
                                        value={detail}
                                        onChange={(e) => setDetail(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Button variant="contained" color="success" size="large" onClick={handleSubmit}>
                                    บันทึก
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container columns={12}>
                    <Grid item xs={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <Typography style={{ fontSize: "24px", fontWeight: "bold" }}>ค้นหา</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <Grid container columns={12} spacing={1} mb={2} className="flex-start">
                            <Grid item xs={12} sm={12} md={2}>
                                <Stack width="100%" direction="row" spacing={1}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dateStartSearch}>
                                            <DatePicker
                                                // fullWidth
                                                value={dateStartSearch}
                                                slotProps={{
                                                    field: { clearable: true, onClear: () => setCleared(true) },
                                                }}
                                                label="วันที่เริ่มต้น"
                                                format="DD/MM/YYYY"
                                                onChange={(e) => {
                                                    setDateStartSearch(e);
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2}>
                                <Stack width="100%" direction="row" spacing={1}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dateEndSearch}>
                                            <DatePicker
                                                // fullWidth
                                                slotProps={{
                                                    field: { clearable: true, onClear: () => setCleared(true) },
                                                }}
                                                label="วันที่สิ้นสุด"
                                                format="DD/MM/YYYY"
                                                value={dateEndSearch}
                                                onChange={(e) => {
                                                    setDateEndSearch(e);
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Stack width="100%" direction="row" spacing={1}>
                                    <TextField
                                        fullWidth
                                        id="search"
                                        label="ค้นหารายการ อ้างอิงเอกสาร / เลขบัญชี / ชื่อบัญชี"
                                        type="search"
                                        variant="outlined"
                                        size="medium"
                                        value={search}
                                        onChange={(e) => {
                                            // console.log({ e });
                                            setSearch(e.target.value);
                                        }}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justifyContent="space-between">
                            <Grid item>
                                <Link to="/Finance/FinancialSum">
                                    <Button variant="contained" color="inherit" size="large" style={{ textDecoration: "underline" }}>
                                        สรุปรายรับ-รายจ่าย
                                    </Button>
                                </Link>
                            </Grid>
                            <Grid item>
                                <Stack direction="row" spacing={1}>
                                    <Button variant="contained" color="error" size="large" onClick={genPDF}>
                                        PDF
                                    </Button>
                                    <Button variant="contained" color="success" size="large" onClick={genExcel}>
                                        Excel
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} mb={2}>
                    <FinalcialTable
                        header={headerTable.FinancialStatement}
                        data={Array.isArray(data) ? data : []}
                        handleClickOpenEdit={handleClickOpenEdit}
                        handleDelete={handleDelete}
                    />
                </Grid>
            </Paper>
        </Box>
    );
}

export default FinancialList;
