import React, { useState, useEffect, useMemo } from "react";
import { GET, POST, GETUSERALL, UPDATESTATUSUSER, GETROLE, SEARCHUSER } from "../../service";
import Swal from "sweetalert2";
import { headerTable } from "../../untils/static";
import ManageUserCard from "./components/ManageUserCard";
import { Container, debounce } from "@mui/material";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";

function ManageUser() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [roleId, setRoleId] = useState("");
    const [dataRole, setDataRole] = useState([]);

    const getUser = async () => {
        try {
            setLoading(true);
            let res = await GET(GETUSERALL);
            if (res.success) {
                setData(res.result);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const getRole = async () => {
        try {
            setLoading(true);
            let res = await GET(GETROLE);
            if (res.success) {
                let role = res.result;
                role.unshift({
                    role_name: "ทั้งหมด",
                    role_id: "",
                });
                setDataRole(role);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            Swal.fire({
                title: "warning",
                text: error,
                icon: "warning",
                showConfirmButton: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const deleteUser = async (user_id) => {
        try {
            setLoading(true);
            let res = await POST(UPDATESTATUSUSER, { user_id, data: { status: 0 } });
            if (res.success) {
                Swal.fire({
                    icon: "success",
                    text: `ปิดบัญชีผู้ใช้งานสำเร็จ`,
                    confirmButtonText: "ตกลง",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `ปิดบัญชีผู้ใช้งานไม่สำเร็จ`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            alert("error", error);
        } finally {
            setLoading(false);
        }
    };

    const deleteAlert = (user_id) => {
        Swal.fire({
            title: "คุณต้องการปิดการใช้งานหรือไม่?",
            text: "ถ้าปิดการใช้งานแล้ว คุณไม่สามารถเปิดการใช้งานได้อีก",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "ปิดการใช้งาน",
            confirmButtonColor: "green",
            cancelButtonText: "ยกเลิก",
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUser(user_id);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire({
                    text: "ยกเลิกรายการแล้ว",
                    confirmButtonText: "ตกลง",
                });
            }
        });
    };

    const fetch = useMemo(
        () =>
            debounce(async (request, roleId) => {
                try {
                    const payload = {
                        text: request,
                        role_id: roleId,
                    };
                    let res = await POST(SEARCHUSER, payload);
                    if (res.success) {
                        setData(res.result);
                    } else {
                        Swal.fire({
                            icon: "warning",
                            text: `${res.message}`,
                            showConfirmButton: true,
                        });
                    }
                } catch (error) {
                    Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
                }
            }, 400),
        []
    );

    useEffect(() => {
        getRole();
    }, []);

    useEffect(() => {
        if (search.length >= 3 || roleId) {
            fetch(search, roleId);
        } else if (!search) {
            getUser();
            return undefined;
        }
    }, [roleId, search, fetch]);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <ManageUserCard
                data={data}
                header_table={headerTable.user_header}
                deleteAlert={deleteAlert}
                setSearch={setSearch}
                setRoleId={setRoleId}
                search={search}
                roleId={roleId}
                dataRole={dataRole}
            />
        </Container>
    );
}

export default ManageUser;
