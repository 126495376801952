import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Box, Grid, Typography } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DoughnutChart({ auditPass, auditNotPass }) {
    const options = {
        indexAxis: "x",
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            datalabels: {
                display: function (context) {
                    return context.chart.isDatasetVisible(context.datasetIndex);
                },
                borderRadius: 4,
                align: "center",
                anchor: "center",
                color: "black",
                backgroundColor: "#fff",
            },
            title: {
                display: true,
                text: "",
            },
            legend: {
                display: true,
            },
        },
    };

    const data = {
        labels: ["Audit ผ่าน", "Audit ไม่ผ่าน"],
        datasets: [
            {
                label: "จำนวน",
                data: [auditPass.count, auditNotPass.count],
                backgroundColor: ["rgba(27,94,32, 0.5)", "rgba(183,28,28, 0.5)"],
                borderColor: ["#1b5e20", "#b71c1c"],
                borderWidth: 1,
            },
        ],
    };
    return (
        <Grid container columns={12}>
            <Grid item xs={12} className="flex-center" style={{ maxHeight: "400px", width: "100%" }}>
                {auditPass.count !== 0 && auditNotPass.count !== 0 ? (
                    <Doughnut data={data} options={options} />
                ) : (
                    <Box className="census-info-empty">
                        <Typography>* ไม่มีข้อมูล *</Typography>
                    </Box>
                )}
            </Grid>
            <Grid item xs={6} className="flex-center">
                <Typography sx={{ color: "#1b5e20", fontWeight: "bold" }}>Audit ผ่านคิดเป็น: {auditPass.percent} %</Typography>
            </Grid>
            <Grid item xs={6} className="flex-center">
                <Typography sx={{ color: "#b71c1c", fontWeight: "bold" }}>Audit ไม่ผ่านคิดเป็น: {auditNotPass.percent} %</Typography>
            </Grid>
        </Grid>
    );
}
