import React from "react";
import { Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import L from "leaflet";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";

dayjs.extend(buddhistEra);
dayjs.locale("th");

const setIcon = (e) => L.icon({
    iconUrl: require(`../../../assets/images/location-pin (${e}).png`),
    iconSize: [40, 40],
    iconAnchor: [19, 39],
    popupAnchor: [1, -34],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    shadowSize: [60, 40],
});

export default function Markerwhatever(props) {
    return (
        <MarkerClusterGroup>
            {props.filteredMarkers.map((marker, index) => {

                let icon = setIcon(Number(marker.codechronic - 1));
                let array = marker.detail && marker.detail.map(e => dayjs(e.DATE_SERV).format('DD MMMM BBBB')).toString().replace(/,/g, "\n- ");
                return (
                    <Marker key={index} position={marker.position} icon={icon}>
                        <Popup>
                            <div>
                                <strong>{marker.title}</strong>
                                <br />
                                <strong>อายุ {marker.age} ปี</strong>
                                <br />
                                <strong>เพศ {marker.sex} </strong>
                                <br />
                                <strong>โรค {marker.th_name}  </strong>
                                <br />
                                <strong>ติดตามผล</strong>
                                <br />
                                <span style={{ whiteSpace: 'break-spaces' }}>{marker.detail ? '- ' + array : null}</span>
                                {/* <img src={marker.image} alt={marker.title} style={{ width: "100px", height: "auto" }} /> */}
                            </div>
                        </Popup>
                    </Marker>
                );
            })}
        </MarkerClusterGroup>
    );
}