import { Button, Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";
import { StyledTableCell } from "../../../Theme";
import { formatPhone, tofieds } from "../../../untils/shortcut";

export default function GridTable({ data, handleClickOpenEdit, header, handleDelete }) {
    console.log("header:", header);
    // console.log("data:", data);
    const pathapi = process.env.REACT_APP_SERVICE;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        {header.map((item) => {
                            return (
                                <>
                                    <StyledTableCell align="center">{item}</StyledTableCell>
                                    {/* <StyledTableCell align="left">{item.imgpath}</StyledTableCell>
                                    <StyledTableCell align="left">{item.hname}</StyledTableCell>
                                    <StyledTableCell align="left">{item.name}</StyledTableCell>
                                    <StyledTableCell align="center">{item.position}</StyledTableCell>
                                    <StyledTableCell align="center">{item.salary}</StyledTableCell>
                                    <StyledTableCell align="left">{item.address}</StyledTableCell>
                                    <StyledTableCell align="left">{item.tel}</StyledTableCell> */}
                                </>
                            );
                        })}
                        <StyledTableCell align="center">ดำเนินการ</StyledTableCell>
                    </TableHead>
                    <TableBody>
                        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            return (
                                <TableRow key={index + 1}>
                                    <StyledTableCell align="left">
                                        <img
                                            src={`${pathapi}${row.imgpath}`}
                                            alt="person"
                                            style={{
                                                width: "100px",
                                                height: "150px",
                                                objectFit: "fill",
                                            }}
                                        ></img>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{row.hosp}</StyledTableCell>
                                    <StyledTableCell align="left">{row.name}</StyledTableCell>
                                    <StyledTableCell align="center">{row.position}</StyledTableCell>
                                    <StyledTableCell align="center">{row.level}</StyledTableCell>
                                    <StyledTableCell align="left">{tofieds(row.salary)}</StyledTableCell>
                                    <StyledTableCell align="left">{row.address}</StyledTableCell>
                                    <StyledTableCell align="left">{formatPhone(row.tel)}</StyledTableCell>
                                    <StyledTableCell align="left">{row.parent}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Button variant="text" onClick={() => handleClickOpenEdit(row)}>
                                            แก้ไข
                                        </Button>
                                        <Button variant="text" color="error" onClick={() => handleDelete(row)}>
                                            ลบ
                                        </Button>
                                    </StyledTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length === 0 ? (
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                </Grid>
            ) : (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    // count={count}
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            )}
        </Paper>
    );
}
