import { Container } from "@mui/material";
import React from "react";
// import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import GridTable from "./DashboardHDA/GridTable";

function DashboardHDA() {
    // const [data, setData] = useState([]);
    // const [loading, setLoading] = useState(false);
    // const [subdistrictObj, setSubdistrictObj] = useState([]);
    // const [selectTambon, setSelectTambon] = useState('');

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            {/* <BackdropLoad loading={loading} /> */}
            <GridTable
                // subdistrictObj={subdistrictObj}
                // setSubdistrictObj={setSubdistrictObj}
            />
        </Container>
    );
}

export default DashboardHDA;