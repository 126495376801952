import { Grid } from "@mui/material";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export default function Barchart({ information }) {
    // console.log("information", information);
    const options = {
        indexAxis: "x",
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            datalabels: false,
        },
        legend: {
            display: false,
        },
    };
    const labels = information.map((e) => e.month);

    const data = {
        labels,
        datasets: [
            {
                label: "จำนวน",
                data: information.map((e) => e.response),
                borderColor: "rgb(0, 140, 238)",
                backgroundColor: "rgba(0, 140, 238, 0.5)",
            },
        ],
    };
    return (
        <Grid container columns={12}>
            <Grid item xs={12} className="census-info-chart-h">
                <Bar data={data} options={options} />
            </Grid>
        </Grid>
    );
}
