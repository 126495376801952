import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, GeoJSON, useMap, useMapEvents, Marker } from "react-leaflet";
import MarkerJS from "./Marker.js";
import "leaflet.heat";

import L from "leaflet";
import { CloseIcn } from "../../../untils/icons.js";
import { ButtonTeal, StyledTableCell, StyledTblK103 } from "../../../Theme.jsx";
import SearchIcon from '@mui/icons-material/Search';
import { POST, SEARCHINSCL } from '../../../service'

const removeDuplicate = (data) => {
     return data.filter((el, i) => data.indexOf(el) === i);
};

const HeatmapLayer = ({ points, currentZoom }) => {
     const map = useMap();
     let point_map = points.map((el) => el.position);
     useEffect(() => {
          if (point_map && point_map.length > 0) {
               const heat = L.heatLayer(point_map, {
                    radius: 20,
                    blur: 15,
                    maxZoom: 17,
                    minOpacity: 0.5,
               }).addTo(map);

               //  var markerClusterGroup = L.markerClusterGroup();
               var markerClusterGroup = L.markerClusterGroup({
                    polygonOptions: {
                         fillColor: "transparent",
                         color: "transparent",
                         weight: 0.5,
                         opacity: 1,
                         fillOpacity: 0.5,
                    },
                    iconCreateFunction: function (cluster) {
                         // var childCount = cluster.getChildCount();

                         // Create a custom div icon with transparent background
                         return L.divIcon({
                              // html: "<div>" + childCount + "</div>",
                              html: "", // ปิดการแสดงตัวเลข
                              className: "custom-cluster", // Custom class
                              iconSize: L.point(40, 40),
                         });
                    },
               });

               points.forEach(function (point) {
                    var lat = point.position[0];
                    var lng = point.position[1];

                    // Create a circleMarker for each heatmap point
                    var circleMarker = L.circleMarker([lat, lng], {
                         radius: 10, // Circle size
                         fillColor: "transparent", // Fill color
                         color: "transparent", // Border color
                         weight: 1, // Border width
                         fillOpacity: 0.6, // Fill opacity
                    });

                    // Optionally bind a popup to the circleMarker
                    circleMarker.bindPopup("<li>" + point.data.detail + "</li>");
                    circleMarker.on("mouseover", function (e) {
                         // Show a popup or any other action on hover
                         L.popup()
                              .setLatLng([lat, lng])
                              .setContent("<li>" + point.data.detail + "</li>")
                              .openOn(map);
                    });

                    // Optional: Close popup on mouseout
                    circleMarker.on("mouseout", function (e) {
                         map.closePopup();
                    });

                    // Add circleMarker to the marker cluster group
                    markerClusterGroup.addLayer(circleMarker);
               });

               function showClusterData(e) {
                    var cluster = e.layer;
                    var markers = cluster.getAllChildMarkers();
                    let markers_map = removeDuplicate(markers.map((el) => el.getPopup().getContent()));

                    // Prepare content for the popup
                    var content = "<b>โรคระบาด:</b><ul>";
                    markers_map.forEach(function (marker) {
                         content += "<li>" + marker + "</li>";
                    });
                    content += "</ul>";

                    // Bind a popup to the cluster and open it at the cluster location
                    var popup = L.popup().setLatLng(cluster.getLatLng()).setContent(content).openOn(map);
               }

               // Handle hover (mouseover and mouseout) events on clusters
               markerClusterGroup.on("clustermouseover", showClusterData);
               markerClusterGroup.on("clustermouseout", function () {
                    map.closePopup(); // Close the popup when the mouse leaves the cluster
               });

               // Add the marker cluster group to the map
               map.addLayer(markerClusterGroup);

               return () => {
                    map.removeLayer(heat); // ลบเลเยอร์ออกเมื่อ component ถูกทำลาย
                    map.removeLayer(markerClusterGroup);
               };
          }
     }, [map, points]);

     return null;
};

const defaultIcon = L.icon({
     iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
     iconSize: [25, 41],
     iconAnchor: [12, 41],
     popupAnchor: [1, -34],
     shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
     shadowSize: [41, 41],
});

const center = window.location.origin.includes("kk")
     ? [16.43192755724492, 102.82856609781523]
     : window.location.origin.includes("mdh")
          ? [16.59738946623057, 104.51104253757008]
          : window.location.origin.includes("spb")
               ? [14.651579065119135, 99.93989999520554]
               : [16.43192755724492, 102.82856609781523];

function MapChart({ data, subdistrictObj }) {
     const [map, setMap] = useState(null);
     const [showGeoJSON, setShowGeoJSON] = useState(true);
     const [showGeoJSON2, setShowGeoJSON2] = useState(true);
     const [showMarker, setShowMarker] = useState(false);
     const [zoom, setZoom] = useState(12);
     const timer = useRef();

     const [open, setOpen] = useState(false);
     const [state, setState] = useState({});
     const [datains, setData] = useState({});
     const [latlng, setLatLng] = useState({ lat: '', lng: '' });
     const [message, setMessage] = useState('')
     const [markerPosition, setMarkerPosition] = useState(null);
     const formRef = useRef();

     const handleSearchins = async () => {
          try {
               let obj = {
                    person_id: state.idCard,
               }
               let res = await POST(SEARCHINSCL, obj)
               if (res.success) {
                    setMessage(res.result.message ? res.result.message : '')
                    setData(res.result.data)
               }
          } catch (error) {

          }
     }

     const handleSave = async () => {

          // const isConfirmed = window.confirm('คุณต้องการบันทึกหรือไม่?');
          // if (!isConfirmed) {
          //      return;
          // }

          try {
               let res = await POST('', {
                    house: state.house,
                    village: state.village,
                    soimain: state.soimain,
                    road: state.road,
                    tambon: state.tambon,
                    ampur: state.ampur,
               });

               if (res.success) {
                    console.log('บันทึกสำเร็จ');
               } else {
                    console.error('บันทึกไม่สำเร็จ');
               }
          } catch (error) {
               console.error('เกิดข้อผิดพลาด:', error);
          }
     };

     const MapEventHandler = () => {
          let pressTimer;

          // ใช้ useMapEvents เพื่อตรวจสอบเหตุการณ์ต่าง ๆ บนแผนที่
          useMapEvents({
               mousedown(e) {
                    // เริ่มจับเวลาตอนผู้ใช้กดค้าง
                    pressTimer = setTimeout(() => {
                         // เมื่อกดค้างนานพอ จะตั้งค่า marker ตรงตำแหน่งนั้น
                         setMarkerPosition(e.latlng);
                         // console.log("Lat:", e.latlng.lat, "Lng:", e.latlng.lng);
                         if (e.latlng) {
                              setOpen(true)
                              setLatLng(e.latlng)
                         }
                    }, 500); // 500 ms ถือว่าเป็นการกดค้าง (long-press)
               },
               mouseup() {
                    // ยกเลิกตัวจับเวลาเมื่อปล่อยคลิก
                    clearTimeout(pressTimer);
               },
               mousemove() {
                    // ยกเลิกตัวจับเวลาเมื่อขยับเมาส์ เพื่อไม่ให้เป็น long-press
                    clearTimeout(pressTimer);
               }
          });

          return null;
     };

     const heatmapPoints = data
          .map((item) => {
               if (item.position[0] && item.position[1]) {
                    // ตรวจสอบว่าพิกัดถูกต้อง
                    return { position: [item.position[0], item.position[1], 0.15], data: item }; // กำหนด intensity เป็น 1 ถ้าไม่มีค่า
               }
               return null; // ถ้าพิกัดไม่ถูกต้อง ให้คืนค่า null
          })
          .filter((item) => item !== null); // กรองค่าที่เป็น null ออกไป

     const popupFeature = (e) => {
          let layer = e.target;
          const { count, tam_th } = e.target.feature.properties;

          if (count > 0) {
               const popupOptions = {
                    minWidth: 100,
                    maxWidth: 250,
                    className: "popup-classname",
               };

               layer.bindTooltip(() => {
                    return `<b>${tam_th} : จำนวนผู้ป่วย ${count}</b>`;
               }, popupOptions).openTooltip();
          }

          const originalStyle = {
               weight: layer.options.weight,
               color: layer.options.color,
               dashArray: layer.options.dashArray,
               fillOpacity: layer.options.fillOpacity,
          };

          layer.options.originalStyle = originalStyle;

          layer.setStyle({
               weight: 3,
               color: "#f00",
               dashArray: "",
               fillOpacity: 0.7,
          });
          layer.bringToFront();
     };

     const popupFeatureOut = (e) => {
          let layer = e.target;
          const originalStyle = layer.options.originalStyle;

          if (originalStyle) {
               layer.setStyle(originalStyle);
          }
          layer.bringToBack();
     };

     const onEachFeature = (feature, layer) => {
          layer.on({
               mouseover: popupFeature,
               mouseout: popupFeatureOut,
          });
     };

     const mapPolygonColorToDensity = (d) => {
          if (d > 10000) return "#800026";
          if (d > 5000) return "#BD0026";
          if (d > 2000) return "#E31A1C";
          if (d > 1000) return "#FC4E2A";
          if (d > 500) return "#FD8D3C";
          if (d > 200) return "#FEB24C";
          if (d > 100) return "#FED976";
          if (d >= 1) return "#FFEDA0";
          return "#000";
     };

     const style = (feature) => {
          return {
               fillColor: mapPolygonColorToDensity(feature.properties.count),
               weight: 1,
               opacity: 1,
               color: "white",
               dashArray: "2",
               fillOpacity: 0.5,
               transition: "5s ease-in-out",
               className: "marker marker-fade-in",
          };
     };

     useEffect(() => {
          if (map) {
               map.on("zoomend", () => {
                    const currentZoom = map.getZoom();
                    setZoom(currentZoom);
                    if (currentZoom < 12) {
                         setShowGeoJSON(currentZoom < 12);
                         setShowMarker(currentZoom >= 12);
                         timer.current = setTimeout(() => {
                              setShowGeoJSON2(true);
                         }, 300);
                    } else {
                         setShowGeoJSON2(currentZoom < 12);
                         setTimeout(() => {
                              setShowGeoJSON(false);
                              setShowMarker(true);
                         }, 300);
                    }
               });
          }
          return () => {
               clearTimeout(timer.current);
          };
     }, [map]);

     return (
          <>
               <Grid container columns={12}>
                    <Box className="box-info" sx={{ background: "#333333" }} width="100%">
                         <Stack spacing={1} className="flex-center" width="100%">
                              <Typography style={{ margin: 5 }}>แผนที่แสดงโรดระบาดเป็นรายบ้านแบบดาวเทียม</Typography>
                         </Stack>
                    </Box>
                    <Grid item xs={12} className={showGeoJSON2 ? "n" : ""}>
                         <MapContainer scrollWheelZoom={true} center={center} zoom={9} className="mapDiagnosis" ref={setMap} attributionControl={false}>
                              <TileLayer
                                   // ตรวจสอบสถานะ satelliteView
                                   maxZoom={22}
                                   minZoom={8}
                                   maxNativeZoom={18}
                                   url={
                                        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                   }
                              />

                              {showGeoJSON && (
                                   <GeoJSON
                                        data={subdistrictObj}
                                        style={(e) => ({
                                             ...style(e),
                                        })}
                                        onEachFeature={onEachFeature}
                                   />
                              )}
                              {/* {console.log("zoom", zoom)} */}
                              {showMarker &&
                                   (zoom < 17 ? <HeatmapLayer points={heatmapPoints} currentZoom={zoom} /> : <MarkerJS filteredMarkers={data} />)}
                              {/* {showMarker && <MarkerJS filteredMarkers={filteredMarkers} />} */}

                              {/* <MapEventHandler /> */}
                              {markerPosition && (
                                   <Marker position={markerPosition} icon={defaultIcon}
                                        eventHandlers={{
                                             click: () => setOpen(true), // ตั้งค่า event เมื่อคลิก Marker
                                        }} />
                              )}

                         </MapContainer>
                    </Grid>
                    <Dialog
                         fullWidth
                         maxWidth="sm"
                         open={open}
                         onClose={() => setOpen(false)}
                         className="bg-dialog"
                    >
                         <Box className='bg-gradiant'>
                              <DialogActions className="flex-end">
                                   <CloseIcn className="close-icn" onClick={() => setOpen(false)} />
                              </DialogActions>
                              <DialogTitle className="text-header">
                                   เพิ่มประชากร
                              </DialogTitle>
                              <DialogContent className="flex-center">
                                   <form ref={formRef} className="flex-center">
                                        <DialogContentText mt={2} className="container-bg" component={'span'}>
                                             <Grid>
                                                  {/* <Grid item xs={12} className="flex-center">
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          value={`${latlng.lat}, ${latlng.lng}`}
                        />
                      </Grid>
                    </Grid> */}
                                                  <Grid container spacing={1} item xs={12} className="flex-center">
                                                       {/* {console.log('state', state)} */}
                                                       <Grid item xs={10} >
                                                            <TextField
                                                                 required
                                                                 fullWidth
                                                                 label="เลขบัตรประชาชน"
                                                                 name="idCard"
                                                                 placeholder="กรุณากรอกเลขบัตรประชาชน"
                                                                 variant="outlined"
                                                                 value={state['idCard']}
                                                                 onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                                 inputProps={{ maxLength: 13 }}
                                                            />
                                                       </Grid>
                                                       <Grid item xs={2} >
                                                            <ButtonTeal sx={{ width: '100%', height: '100%' }} onClick={handleSearchins}>
                                                                 <SearchIcon />
                                                            </ButtonTeal>
                                                       </Grid>
                                                       {message && <strong style={{ marginTop: '1%', color: message.includes("สำเร็จ") ? "green" : 'red' }}>*{message}</strong>}
                                                  </Grid>
                                                  <Grid item xs={12} className="flex-center">
                                                       <TableContainer sx={{ maxHeight: 800 }}>
                                                            <Table stickyHeader aria-label="sticky table">
                                                                 <TableHead>
                                                                      <TableRow>
                                                                           <StyledTblK103 align="center">หัวข้อ</StyledTblK103>
                                                                           <StyledTblK103 align="center">รายละเอียดข้อมูล</StyledTblK103>
                                                                      </TableRow>
                                                                 </TableHead>
                                                                 <TableBody>
                                                                      <>
                                                                           <TableRow>
                                                                                <StyledTableCell align="left">ชื่อ - นามสกุล</StyledTableCell>
                                                                                <StyledTableCell align="left">{datains.fullName ? datains.fullName : "N/A"}</StyledTableCell>
                                                                           </TableRow>
                                                                           <TableRow>
                                                                                <StyledTableCell align="left">หมายเลขบัตร</StyledTableCell>
                                                                                <StyledTableCell align="left">{datains.pid ? datains.pid : "N/A"}</StyledTableCell>
                                                                           </TableRow>
                                                                           <TableRow>
                                                                                <StyledTableCell align="left">วัน/เดือน/ปี เกิด</StyledTableCell>
                                                                                <StyledTableCell align="left">{datains.birthDate ? datains.birthDate : "N/A"}</StyledTableCell>
                                                                           </TableRow>
                                                                           <TableRow>
                                                                                <StyledTableCell align="left">เพศ</StyledTableCell>
                                                                                <StyledTableCell align="left">{datains.sex ? datains.sex : "N/A"}</StyledTableCell>
                                                                           </TableRow>
                                                                           <TableRow>
                                                                                <StyledTableCell align="left">อายุ</StyledTableCell>
                                                                                <StyledTableCell align="left">{datains.age ? datains.age : "N/A"}</StyledTableCell>
                                                                           </TableRow>
                                                                           <TableRow>
                                                                                <StyledTableCell align="left">สัญชาติ</StyledTableCell>
                                                                                <StyledTableCell align="left">{datains.nationDescription ? datains.nationDescription : "N/A"}</StyledTableCell>
                                                                           </TableRow>
                                                                           <TableRow>
                                                                                <StyledTableCell align="left">สิทธิการรักษา</StyledTableCell>
                                                                                <StyledTableCell align="left">{datains.mainInscl ? datains.mainInscl : "N/A"}</StyledTableCell>
                                                                           </TableRow>
                                                                           <TableRow>
                                                                                <StyledTableCell align="left">หน่วยบริการปฐมภูมิ</StyledTableCell>
                                                                                <StyledTableCell align="left">
                                                                                     {datains.hospSub ? datains.hospSub : datains.hospNew ? datains.hospNew["hname"] : "N/A"}
                                                                                </StyledTableCell>
                                                                           </TableRow>
                                                                           <TableRow>
                                                                                <StyledTableCell align="left">หน่วยบริการหลัก</StyledTableCell>
                                                                                <StyledTableCell align="left">
                                                                                     {datains.hospMain ? datains.hospMain : datains.hospNew ? datains.hospNew["hname"] : "N/A"}
                                                                                </StyledTableCell>
                                                                           </TableRow>

                                                                           <TableRow>
                                                                                <StyledTableCell align="left">จังหวัด</StyledTableCell>
                                                                                <StyledTableCell align="left">{datains.provinceName ? datains.provinceName : "N/A"}</StyledTableCell>
                                                                           </TableRow>
                                                                      </>
                                                                 </TableBody>
                                                            </Table>
                                                       </TableContainer>
                                                  </Grid>
                                                  <Grid >
                                                       <DialogTitle style={{ color: 'black' }}>
                                                            ที่อยู่
                                                       </DialogTitle>
                                                  </Grid>
                                                  <Grid container spacing={1} item xs={12} className="flex-center">
                                                       <Grid item xs={6} >
                                                            <TextField
                                                                 required
                                                                 fullWidth
                                                                 label="บ้านเลขที่"
                                                                 name="house"
                                                                 placeholder="กรุณากรอกบ้านเลขที่"
                                                                 variant="outlined"
                                                                 value={state['house']}
                                                                 onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                            />
                                                       </Grid>
                                                       <Grid item xs={6} >
                                                            <TextField
                                                                 required
                                                                 fullWidth
                                                                 label="หมู่ที่"
                                                                 name="village"
                                                                 placeholder="กรุณากรอกหมู่บ้าน"
                                                                 variant="outlined"
                                                                 value={state['village']}
                                                                 onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                            />
                                                       </Grid>
                                                       <Grid item xs={6} >
                                                            <TextField
                                                                 required
                                                                 fullWidth
                                                                 label="ซอย"
                                                                 name="soimain"
                                                                 placeholder="กรุณากรอกซอย"
                                                                 variant="outlined"
                                                                 value={state['soimain']}
                                                                 onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                            />
                                                       </Grid>
                                                       <Grid item xs={6} >
                                                            <TextField
                                                                 required
                                                                 fullWidth
                                                                 label="ถนน"
                                                                 name="road"
                                                                 placeholder="กรุณากรอกถนน"
                                                                 variant="outlined"
                                                                 value={state['road']}
                                                                 onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                            />
                                                       </Grid>
                                                       <Grid item xs={6} >
                                                            <TextField
                                                                 required
                                                                 fullWidth
                                                                 label="ตำบล"
                                                                 name="tambon"
                                                                 placeholder="กรุณากรอกตำบล"
                                                                 variant="outlined"
                                                                 value={state['tambon']}
                                                                 onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                            />
                                                       </Grid>
                                                       <Grid item xs={6} >
                                                            <TextField
                                                                 required
                                                                 fullWidth
                                                                 label="อำเภอ"
                                                                 name="ampur"
                                                                 placeholder="กรุณากรอกอำเภอ"
                                                                 variant="outlined"
                                                                 value={state['ampur"']}
                                                                 onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                            />
                                                       </Grid>
                                                  </Grid>
                                             </Grid>
                                             <Button
                                                  sx={{ textAlign: "left", marginTop: "2%" }}
                                                  variant="contained"
                                                  color="success"
                                                  onClick={handleSave}
                                             >
                                                  บันทึก
                                             </Button>
                                        </DialogContentText>
                                   </form >
                              </DialogContent>
                              {/* <DialogActions className="flex-center">
                  <ButtonTeal
                    sx={{ width: { xs: "200px", sm: "50%", md: "30%" } }}
                    onClick={() => {
                      checkValidation();
                    }}
                  >
                    แก้ไขข้อมูล
                  </ButtonTeal>
                </DialogActions> */}
                         </Box>
                    </Dialog >
               </Grid>
          </>
     );
}

{
     /* <TileLayer
                                   // ตรวจสอบสถานะ satelliteView
                                   maxZoom={18}
                                   url={"https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"}
                                   attribution={
                                        "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                                   }
                              /> */
}
export default MapChart;