import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { GENEXCEL, GENEXCELSUM, GENPDF, GENPDFSUM, GETDATAFINANCIALSUM, INSERTFINANCIALSUM, POST, ip } from "../../service";
import FinancialSumList from "./components/FinanceSumList";
import axios from "axios";

function FinancialSum() {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState([]);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(dayjs());
    const token = localStorage.getItem("uuid");

    const getData = async () => {
        try {
            setLoading(true);
            let res = await POST(GETDATAFINANCIALSUM, { finance_date: date });
            if (res.success) {
                setData(res.result.processedData);
                setTotal(res.result.totals);
                setLoading(false);
            } else {
                setData([]);
                setTotal([]);
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const insertData = async () => {
        try {
            setLoading(true);
            let res = await POST(INSERTFINANCIALSUM, { finance_date: date });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: `${res.result}`,
                    showConfirmButton: true,
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const genPDF = async () => {
        try {
            await axios
                .post(
                    ip + GENPDFSUM,
                    { finance_date: date },
                    {
                        responseType: "blob",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: token ? token : "token",
                        },
                    }
                )
                .then(async (res) => {
                    const pdfBlob = new Blob([res.data], {
                        type: "application/pdf",
                    });
                    const fileURL = URL.createObjectURL(pdfBlob);
                    window.open(fileURL);
                });
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const genExcel = async () => {
        setLoading(true);
        try {
            const res = await axios.post(
                ip + GENEXCELSUM,
                { finance_date: date },
                {
                    responseType: "blob",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token || "token",
                    },
                }
            );

            const excelBlob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const fileURL = URL.createObjectURL(excelBlob);
            const a = document.createElement("a");
            a.href = fileURL;
            a.download = "finance_report.xlsx";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(fileURL);
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error.response ? error.response.data : error.message}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, [date]);

    return <FinancialSumList data={data} date={date} setDate={setDate} total={total} insertData={insertData} genPDF={genPDF} genExcel={genExcel} />;
}

export default FinancialSum;
