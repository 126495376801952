import "../src/css/color.css";
import "../src/css/colorTraining.css";
import "../src/css/global.css";
import "../src/css/map.css";
import "../src/css/legend.css";
import "../src/css/dialog.css";
import "../src/css/dashboard.css";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import Theme from "./Theme.jsx";
import RouterMain from "./pages/RouterMain";
import { v4 as uuid } from "uuid";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjsRelativeTime from "dayjs/plugin/relativeTime";
import ThemeTraining from "./ThemeTraining.jsx";
// import "dayjs/locale/th.js";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(dayjsRelativeTime);
// let tzlocal = dayjs.tz.guess();
// dayjs.tz.setDefault(tzlocal);
// console.log("tzlocal:", tzlocal);

function App() {
    const unique_id = uuid();
    // console.log(unique_id)

    useEffect(() => {
        // console.log({ local: localStorage.getItem('uuid') });
        if (localStorage.getItem("uuid")) return;
        // console.log({ try: unique_id });
        if (unique_id) localStorage.setItem("uuid", unique_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BrowserRouter>
            {/* <Theme>
                <CssBaseline />
                <RouterMain />
            </Theme> */}
            <ThemeTraining>
                <CssBaseline />
                <RouterMain />
            </ThemeTraining>
        </BrowserRouter>
    );
}

export default App;
