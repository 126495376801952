import React from 'react';
import {
    Grid,
    Box,
    Typography,
    Card,
    Container,
    CardContent,
    Stack,
    Button,
} from "@mui/material";
import { useLocation, useNavigate } from 'react-router-dom';

function Approve() {
    const navigate = useNavigate();
    const location = useLocation()
    const { detail } = location.state;

    const handleChange = () => {
        navigate("/Login")
    }

    const handleClick = () => {
        navigate("/Otp")
    }

    return (
        <>
            <Container maxWidth="sm" sx={{ position: "relative" }}>
                <Box
                    className="flex-center"
                    height="90vh"
                >
                    <Grid container columns={12} className="flex-center">
                        <Grid item xs={12} className="flex-center">
                            <Typography className="header" >อยู่ระหว่างองค์การบริหารส่วนจังหวัดอนุมัติ</Typography>
                        </Grid>
                        <Grid item my={5} xs={12} className="flex-center">
                            <Card sx={{ minWidth: 345 }} >
                                <CardContent sx={{ p: 5 }} className="card-bg">
                                    <Stack width="100%" className="flex-center" spacing={2}>
                                        <Typography className="text-subtitle">
                                            {detail.name}
                                        </Typography>
                                        <Typography className="text-subtitle">
                                            รหัสเครื่อง : {detail.uid}
                                        </Typography>
                                        <Typography sx={{ fontSize: '14px' }} align='center'>
                                            *กรุณาเข้ากดยืนยันตัวตนผ่าน อบจ. หลังได้รับการอนุมัติ หรือยืนยันตัวตนผ่าน Application หมอพร้อม
                                        </Typography>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item padding={1} mr={2}>
                            <Button color='success' variant="outlined" onClick={handleChange}>ยืนยันตัวตนผ่าน อบจ.</Button>
                        </Grid>
                        <Grid>
                            <Button sx={{ color: '#1976d2' }} variant="outlined" onClick={handleClick}>ยืนยันตัวตนผ่าน Application หมอพร้อม</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Container >
            <footer>
                <svg viewBox="0 -20 700 110" width="100%" height="110" preserveAspectRatio="none">
                    <path transform="translate(0, -20)" d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700" fill="var(--teal-100)" opacity="0.8" />
                    <path d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z" fill="var(--teal-A700)" />
                </svg>
            </footer>
        </>
    )
}

export default Approve;