import React from "react";
import { Grid, Box, Paper, Typography, Stack } from "@mui/material";

import { ClaimIcon, PersonIcon } from "../../../../untils/icons";

import GridTableDetail from "./GridTableDetail";

import { Item } from "../../../../Theme";
import dayjs from "dayjs";

function GridDetail(props) {
     return (
          <Box display="flex" justifyContent="center">
               <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12}>
                         <Grid item xs={12} mt={1} className="flex-start">
                              <Stack direction="row" spacing={2}>
                                   <ClaimIcon fontSize="medium" />
                                   <Typography className="main-header">รายละเอียดข้อมูล</Typography>
                              </Stack>
                         </Grid>
                         <Grid item xs={12} mt={5} className="flex-start">
                              <Stack width="100%" direction={{ xs: "column", sm: "column", md: "row" }} spacing={3}>
                                   <Item elevation={3}>
                                        <Stack direction="row" className="flex-start" spacing={3}>
                                             <Typography fontWeight="bold" fontSize={24} color="var(--teal-A800)">
                                                  HN/SEQ
                                             </Typography>
                                             <Grid>
                                                  <Typography fontWeight="bold" color="var(--teal-A800)">
                                                       HN
                                                  </Typography>
                                                  <Typography color="var(--teal-A800)">{props.detail.HN}</Typography>
                                             </Grid>
                                             <Grid>
                                                  <Typography fontWeight="bold" color="var(--teal-A800)">
                                                       SEQ
                                                  </Typography>
                                                  <Typography color="var(--teal-A800)">{props.detail.SEQ}</Typography>
                                             </Grid>
                                        </Stack>
                                   </Item>
                                   <Item elevation={3}>
                                        <Stack direction="row" className="flex-start" spacing={3}>
                                             <Typography fontWeight="bold" fontSize={24} color="var(--teal-A800)">
                                                  วันที่
                                             </Typography>
                                             <Grid>
                                                  <Typography fontWeight="bold" color="var(--teal-A800)">
                                                       วันที่เข้ารับบริการ
                                                  </Typography>
                                                  <Typography color="var(--teal-A800)">{dayjs(props.detail.DATEOPD).format("DD/MM/YYYY")}</Typography>
                                             </Grid>
                                        </Stack>
                                   </Item>
                                   <Item elevation={3}>
                                        <Stack direction="row" className="flex-start" spacing={3}>
                                             <PersonIcon size="larg" />
                                             <Grid>
                                                  <Typography fontWeight="bold" color="var(--teal-A800)">
                                                       ชื่อ - นามสกุล
                                                  </Typography>
                                                  <Typography color="var(--teal-A800)">
                                                       {props.detail.TITLE}{" "}
                                                       {props.detail.NAMEPAT ? props.detail.NAMEPAT.replace(`,${props.detail.TITLE}`, "") : "-"}
                                                  </Typography>
                                             </Grid>
                                        </Stack>
                                   </Item>
                                   <Item elevation={3}>
                                        <Stack direction="row" className="flex-start" spacing={3}>
                                             <Typography fontWeight="bold" fontSize={24} color="var(--teal-A800)">
                                                  INSCL
                                             </Typography>
                                             <Grid>
                                                  <Typography fontWeight="bold" color="var(--teal-A800)">
                                                       สิทธิ์
                                                  </Typography>
                                                  <Typography color="var(--teal-A800)">{props.detail.INSCL ? props.detail.INSCL : "-"}</Typography>
                                             </Grid>
                                             <Grid>
                                                  <Typography fontWeight="bold" color="var(--teal-A800)">
                                                       รหัส รพ.สต.
                                                  </Typography>
                                                  <Typography color="var(--teal-A800)">
                                                       {props.data.data_ins?.HOSPSUB ? props.data.data_ins?.HOSPSUB : "-"}
                                                  </Typography>
                                             </Grid>
                                        </Stack>
                                   </Item>
                              </Stack>
                         </Grid>
                         <Grid item xs={12} mt={5} mb={1}>
                              <Typography className="main-header">ค่าบริการ หัตถการ และยา</Typography>
                         </Grid>
                         <Grid item xs={12} sm={12} md={12}>
                              <GridTableDetail type="adp" header={props.header} data={props.data.data_adp} />
                         </Grid>
                         <Grid item xs={12} mt={5} mb={1}>
                              <Typography className="main-header">รหัสวินิจฉัย</Typography>
                         </Grid>
                         <Grid item xs={12} sm={12} md={12}>
                              <GridTableDetail type="odx" header={props.headerOdx} data={props.data.data_odx} />
                         </Grid>
                    </Grid>
               </Paper>
          </Box>
     );
}

export default GridDetail;
