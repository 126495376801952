import { styled } from "@mui/material/styles";

import FitbitIcon from "@mui/icons-material/Fitbit";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HomeIcon from "@mui/icons-material/Home";
import EditNoteIcon from "@mui/icons-material/EditNote";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FactoryIcon from "@mui/icons-material/Factory";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import DomainAddOutlinedIcon from "@mui/icons-material/DomainAddOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import AddHomeWorkOutlinedIcon from "@mui/icons-material/AddHomeWorkOutlined";
import RouterOutlinedIcon from "@mui/icons-material/RouterOutlined";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import SendIcon from "@mui/icons-material/Send";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import FeedIcon from "@mui/icons-material/Feed";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ViewListIcon from "@mui/icons-material/ViewList";
import TokenIcon from "@mui/icons-material/Token";
import GroupsIcon from "@mui/icons-material/Groups";
import Woman2Icon from "@mui/icons-material/Woman2";
import ManIcon from "@mui/icons-material/Man";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import PaymentsIcon from "@mui/icons-material/Payments";
import SettingsIcon from "@mui/icons-material/Settings";
import DragHandleRoundedIcon from "@mui/icons-material/DragHandleRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import AssessmentIcon from "@mui/icons-material/Assessment";
import StorageIcon from "@mui/icons-material/Storage";
import AddPhotoAlternateRoundedIcon from "@mui/icons-material/AddPhotoAlternateRounded";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import ContentPasteOffOutlinedIcon from "@mui/icons-material/ContentPasteOffOutlined";

export const AddImg = styled(AddPhotoAlternateRoundedIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const DatabaseIcn = styled(StorageIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const ReportIcn = styled(AssessmentIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const EditIcnBtn = styled(EditIcon)(() => ({
    color: "#fff",
}));

export const SaveIcn = styled(SaveRoundedIcon)(() => ({
    color: "#fff",
}));

export const DragIcn = styled(DragHandleRoundedIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const SettingsIcn = styled(SettingsIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const Statement = styled(PaymentsIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const BackIcn = styled(ArrowBackIosNewRoundedIcon)(() => ({
    color: "#fff",
}));

export const CloseIcn = styled(HighlightOffOutlinedIcon)(() => ({
    color: "#C0C0C0",
}));

export const LocaIcn = styled(AddHomeWorkOutlinedIcon)(() => ({
    color: "#fff",
}));

export const LocalHotelIcn = styled(LocalHotelIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const WomenIcn = styled(Woman2Icon)(() => ({
    color: "var(--teal-A800)",
}));

export const MenIcn = styled(ManIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const PeopleIcn = styled(GroupsIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const TokenIcn = styled(TokenIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const StockPage = styled(ViewListIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const PhotoIcon = styled(AddAPhotoIcon)(() => ({}));

export const NewsIcn = styled(FeedIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const SearchIcn = styled(SearchIcon)(() => ({
    color: "var(--white)",
}));

export const UserIcon = styled(AccountCircleIcon)(() => ({
    color: "var(--white)",
}));

export const Dashboard = styled(DashboardIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const PersonIcon = styled(PersonOutlineIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const SendIcn = styled(SendIcon)(() => ({
    color: "var(--white)",
}));

export const ClaimIcon = styled(VerifiedUserIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const Cancel = styled(CancelIcon)(() => ({
    color: "var(--white)",
}));

export const RoleIcon = styled(AdminPanelSettingsOutlinedIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const Del = styled(ClearIcon)(() => ({
    // color: "var(--teal-A800)",
}));

export const Confirm = styled(CheckCircleIcon)(() => ({
    // color: "var(--teal-A800)",
}));

export const Approve = styled(PendingActionsIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const ArrowUpIcon = styled(KeyboardArrowUpIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const ArrowDownIcon = styled(KeyboardArrowDownIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const ActionIcon = styled(MoreVertIcon)(() => ({
    color: "var(--teal-A800)",
    padding: 3,
}));

export const VendorIcon = styled(MedicalServicesOutlinedIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const DeviceIcon = styled(RouterOutlinedIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const LocaIcon = styled(AddHomeWorkOutlinedIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const ListIcon = styled(ListAltOutlinedIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const HospitalIcon = styled(DomainAddOutlinedIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const HistoryIcon = styled(HistoryOutlinedIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const DeleteIcon = styled(DeleteForeverOutlinedIcon)(() => ({
    color: "var(--red)",
}));

export const EditDataIcon = styled(EditIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const TypeIcon = styled(AssignmentIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const LocationIcon = styled(LocationOnIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const AddItemIcon = styled(AddIcon)(() => ({
    color: "var(--white)",
}));

export const CalendarIcon = styled(CalendarMonthIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const SpdIcon = styled(FactoryIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const CheckIcon = styled(CheckCircleOutlineIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const MoreIcon = styled(MoreHorizOutlinedIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const DocumentIcon = styled(DescriptionOutlinedIcon)(() => ({}));

export const PrintIcon = styled(PrintOutlinedIcon)(() => ({}));
export const MenuDrawerIcon = styled(MenuIcon)(() => ({}));

export const DrawerIcon = styled(FitbitIcon)(() => ({}));

export const ChevronLeftDrawerIcon = styled(ChevronLeftIcon)(() => ({}));

export const ChevronRightDrawerIcon = styled(ChevronRightIcon)(() => ({}));

export const HomeDrawerIcon = styled(HomeIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const EditNoteDrawerIcon = styled(EditNoteIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const ManageAccountsDrawerIcon = styled(ManageAccountsIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const LogoutDrawerIcon = styled(LogoutIcon)(() => ({
    color: "var(--red)",
}));

export const InventoryDrawerIcon = styled(Inventory2OutlinedIcon)(() => ({
    color: "var(--yellow)",
}));

export const DownloadFileIcon = styled(DownloadIcon)(() => ({
    color: "var(--white)",
}));

export const PassIcon = styled(AssignmentOutlinedIcon)(() => ({
    color: "var(--teal-A800)",
}));

export const NotPassIcon = styled(ContentPasteOffOutlinedIcon)(() => ({
    color: "var(--teal-A800)",
}));
