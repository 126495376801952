import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { GETDATASTATEMENT, GETDATASTATEMENT2, POST } from "../../service";
import GridCard from "./DashboardStatement2/GridCard";

function DashboardStatement2() {
    const [dateStart, setDateStart] = useState(dayjs().startOf("year").utc(true));
    const [dateEnd, setDateEnd] = useState(dayjs());
    const [cleared, setCleared] = useState(false);
    const [dataStateMent, setDataStateMent] = useState([]);
    const [dataStateMent2, setDataStateMent2] = useState([]);
    const [loading, setLoading] = useState(false);

    const getDataStateMent = async () => {
        try {
            setLoading(true);
            let res = await POST(GETDATASTATEMENT, { monthstart: dateStart, monthend: dateEnd });
            // console.log("res", res.result);
            if (res.success) {
                setDataStateMent(res.result);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `ค้นหาไม่สำเร็จ`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const getDataStateMent2 = async () => {
        try {
            setLoading(true);
            let res = await POST(GETDATASTATEMENT2, { monthstart: dateStart, monthend: dateEnd });
            if (res.success) {
                setDataStateMent2(res.result);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `ค้นหาไม่สำเร็จ`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getDataStateMent();
        getDataStateMent2();
    }, [dateStart, dateEnd]);

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => {};
    }, [cleared]);

    return (
        <>
            <GridCard
                dateStart={dateStart}
                dateEnd={dateEnd}
                setDateStart={setDateStart}
                setDateEnd={setDateEnd}
                cleared={cleared}
                setCleared={setCleared}
                data={dataStateMent}
                data2={dataStateMent2}
            />
        </>
    );
}

export default DashboardStatement2;
