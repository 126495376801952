import { useEffect, useState } from "react";
import GridCard from "./DashboardMapGIS2/GridCard";
import { POST, POSTMAP } from "../../service";
import Swal from "sweetalert2";
import { getToken } from "../../untils/shortcut";
import dayjs from "dayjs";

function DashboardMapGIS2() {
  const [loading, setLoading] = useState(false);
  const profile = getToken(localStorage.getItem("profile"));
  const [hcode, setHcode] = useState("");
  const [data, setData] = useState([]);
  const [proLocationName, setProLocationName] = useState("ทั้งหมด");
  const [selectLocation, setSelectLocation] = useState("");
  const [dataLocation, setDataLocation] = useState([]);
  const [dateStart, setDateStart] = useState(dayjs(new Date()).startOf("month").utc(true));
  const [dateEnd, setDateEnd] = useState(dayjs(new Date()));
  const [subdistrictObj, setSubdistrictObj] = useState([]);
  const [home, setHome] = useState([{ id: "", type: "ทั้งหมด" }]);
  const [idHome, setIdHome] = useState("");
  const [selectedHome, setSelectedHome] = useState(home[0]);
  const GetMapDashboard = async () => {
    try {
      setLoading(true);
      const res = await POST(POSTMAP, { hcode, dateStart, dateEnd, id: idHome });
      if (res.success) {
        if (profile.role_id !== 1 && profile.role_id !== 2) {
          setProLocationName(profile.location_name);
          setHcode(profile.location_code);
          setSubdistrictObj(res.result.countTambon.features);
          setData(res.result.data);
          const apiData = res.result.data_type || [];
          setHome([{ id: "", type: "ทั้งหมด" }, ...apiData]);
        } else {
          setData(res.result.data);
          setDataLocation(res.result.hname);
          setSubdistrictObj(res.result.countTambon.features);
          const apiData = res.result.data_type || [];
          setHome([{ id: "", type: "ทั้งหมด" }, ...apiData]);
        }
        setLoading(false);
      } else {
        Swal.fire({
          icon: "warning",
          text: `${res.message}`,
          confirmButtonText: "ตกลง",
        });
      }
    } catch (error) {
      Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetMapDashboard();
  }, [hcode, dateStart, dateEnd, idHome]);

  return (
    <GridCard
      profile={profile}
      data={data}
      proLocationName={proLocationName}
      dataLocation={dataLocation}
      setProLocationName={setProLocationName}
      dateEnd={dateEnd}
      dateStart={dateStart}
      setDateStart={(e) => setDateStart(e)}
      setDateEnd={(e) => setDateEnd(e)}
      hcode={hcode}
      setHcode={(e) => setHcode(e)}
      subdistrictObj={subdistrictObj}
      loading={loading}
      home={home}
      setHome={(e) => setHome(e)}
      setIdHome={(e) => setIdHome(e)}
      selectedHome={selectedHome}
      setSelectedHome={(e) => setSelectedHome(e)}
    />
  );
}

export default DashboardMapGIS2;
