import { useEffect, useState } from "react";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import TypeCard from "./components/TypeCard";
import { ADDTYPE, DELETETYPE, GET, GETDATATYPE, POST, UPDATETYPE } from "../../service";
import Swal from "sweetalert2";
import DialogEdit from "./components/DialogEdit";
import DialogAdd from "./components/DialogAdd";
import { headerTable } from "../../untils/static";

function ManageType() {
    const [loading, setLoading] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [checked, setChecked] = useState(false);
    const [dataType, setDataType] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [screenName, setScreenName] = useState("");
    const [type, setType] = useState("");
    const [icon, setIcon] = useState("");
    const [typeId, setTypeId] = useState("");

    const getDataType = async () => {
        try {
            setLoading(true);
            let res = await GET(GETDATATYPE);
            if (res.success) {
                setDataType(res.result);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleAdd = async () => {
        try {
            setLoading(true);
            let res = await POST(ADDTYPE, {
                screen_name: screenName,
                type: type,
                icon,
            });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: `เพิ่มข้อมูลสำเร็จ`,
                    confirmButtonText: "ตกลง",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleUpdate = async () => {
        try {
            setLoading(true);
            let res = await POST(UPDATETYPE, {
                screen_id: typeId,
                screen_name: screenName,
                type,
                icon,
            });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: `แก้ไขข้อมูลสำเร็จ`,
                    confirmButtonText: "ตกลง",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (typeId) => {
        try {
            setLoading(true);
            let res = await POST(DELETETYPE, {
                type_id: typeId,
            });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: `แก้ไขข้อมูลสำเร็จ`,
                    confirmButtonText: "ตกลง",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClickOpenAdd = () => {
        setScreenName("");
        setType("");
        setIcon("");
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setScreenName("");
        setType("");
        setIcon("");
        setOpenAdd(false);
    };

    const handleClickOpenEdit = (data) => {
        setScreenName(data.screen_name);
        setTypeId(data.type_id);
        setType(data.type);
        setIcon(data.icon);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    useEffect(() => {
        getDataType();
    }, []);
    return (
        <>
            <BackdropLoad loading={loading} />
            <TypeCard
                header={headerTable.ManageType}
                data={dataType}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                handleClickOpenEdit={handleClickOpenEdit}
                handleClickOpenAdd={handleClickOpenAdd}
                handleDelete={handleDelete}
            />

            <DialogAdd
                open={openAdd}
                handleClose={handleCloseAdd}
                handleAdd={handleAdd}
                data={dataType}
                type={type}
                setType={setType}
                screenName={screenName}
                setScreenName={setScreenName}
                icon={icon}
                setIcon={setIcon}
            />

            <DialogEdit
                open={openEdit}
                handleClose={handleCloseEdit}
                handleUpdate={handleUpdate}
                data={dataType}
                type={type}
                setType={setType}
                screenName={screenName}
                setScreenName={setScreenName}
                icon={icon}
                setIcon={setIcon}
                typeId={typeId}
            />
        </>
    );
}
export default ManageType;
