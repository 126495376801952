import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import {
    Button,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React from 'react';
import { AntSwitch, StyledTableCell } from "../../../Theme";
import { DragIcn } from "../../../untils/icons";


export default function GridTable({ header, data, handleChangeSwitch, handleDragEnd, handleClickOpenEdit }) {

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {header.map((column, i) => (
                                <StyledTableCell
                                    key={i + 1}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    <Typography fontWeight='bold' fontSize='14px'>{column.label}</Typography>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <Droppable droppableId="droppable-1" >
                        {(provider) => (
                            <TableBody ref={provider.innerRef} {...provider.droppableProps}>
                                {data.map((row, index) => (
                                    <Draggable key={row.name} draggableId={row.name} index={index}>
                                        {(provider) => (
                                            <TableRow
                                                key={row.name}
                                                {...provider.draggableProps}
                                                ref={provider.innerRef}
                                            >
                                                <StyledTableCell align="left">{row.name}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Stack direction="row" spacing={1} className="flex-center">
                                                        {/* <Typography>ปิด</Typography> */}
                                                        <AntSwitch
                                                            id={row.id}
                                                            checked={row.status}
                                                            onChange={(e) => handleChangeSwitch(e)}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                        {/* <Typography>เปิด</Typography> */}
                                                    </Stack>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">{row.sort}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Button variant='text' onClick={() => handleClickOpenEdit(row)}>
                                                        แก้ไข
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                    align="center"
                                                    {...provider.dragHandleProps}
                                                >
                                                    <DragIcn />
                                                </StyledTableCell>
                                            </TableRow>
                                        )}
                                    </Draggable>
                                ))}
                                {provider.placeholder}
                            </TableBody>
                        )}
                    </Droppable>
                </Table>
            </DragDropContext>
            {data.length === 0 &&
                <Grid container justifyContent="center" my={3}>
                    <Typography sortWrap >
                        * ไม่มีข้อมูลในตาราง *
                    </Typography>
                </Grid>
            }
        </Paper>
    );
}

