import { debounce } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import BackdropLoad from "../../../components/Backdrop/BackdropLoad.js";
import { GET, GETCLAIM, GETCLAIMTYPE, LASTDTUPDATE, POST, SENDCLAIM1, SENDCLAIM2 } from "../../../service";
import { getToken } from "../../../untils/shortcut.js";
import { headerTable } from "../../../untils/static";
import ClaimCard from "./components/ClaimCard";

function Claim16() {
    const [loading, setLoading] = useState(false);
    const [dateStart, setDateStart] = useState(dayjs(new Date()).startOf("month"));
    const [dateEnd, setDateEnd] = useState(dayjs(new Date()));
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState(3);
    const [dataType, setDataType] = useState([]);
    const [type, setType] = useState(98);
    const [statusSend, setStatusSend] = useState(99);
    const profile = getToken(localStorage.getItem("profile"));
    const [dateUpdate, setDateUpdate] = useState();

    const getDateUpdate = async () => {
        try {
            let res = await GET(LASTDTUPDATE);
            if (res.success) {
                /* console.log('มาสิวะ',res.result) */
                setDateUpdate(res.result.dt_update);
            } else {
                console.log(res.message);
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const getDataType = async () => {
        try {
            setLoading(true);
            let res = await GET(GETCLAIMTYPE);
            if (res.success) {
                let data = res.result;
                data.unshift(
                    {
                        name: "ทั้งหมด OPD",
                        id: 99,
                    },
                    {
                        name: "ทั้งหมด Free Schdule",
                        id: 98,
                    }
                );
                setDataType(data);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const getClaim = async () => {
        try {
            setLoading(true);
            const payload = {
                date_start: dateStart,
                date_end: dateEnd,
                status_c: status,
                text: search,
                claim_type: type,
                status_claim: statusSend,
            };
            let res = await POST(GETCLAIM, payload);
            if (res.success) {
                let data = [];
                let data_claim = res.result;

                data_claim.map((e, i) =>
                    data.push({
                        ...e,
                        check: false,
                        status: 0,
                        index: i,
                    })
                );

                setData(data);
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const handleSendClaim = (item) => {
        let res_claim = data.filter((e) => e.check === true);
        let data_send_claim = [];
        res_claim.map((e) =>
            data_send_claim.push({
                HN: e.HN,
                SEQ: e.SEQ,
                DATEOPD: e.DATEOPD,
                HCODE: e.HCODE,
                INSCL: e.INSCL,
                CID: e.CID,
                PERMITNO: e.PERMITNO,
                ins_out: e.ins_out,
            })
        );

        // console.log(data_send_claim);
        if (data_send_claim.length > 0) {
            if (item === "authen") {
                Swal.fire({
                    title: "ตรวจสอบยืนยันตัวตน",
                    text: "คุณต้องการตรวจสอบหรือไม่?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "ยืนยัน",
                    confirmButtonColor: "green",
                    cancelButtonText: "ยกเลิก",
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        SendAuthen(data_send_claim);
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire({
                            text: "ยกเลิกรายการแล้ว",
                            confirmButtonText: "ตกลง",
                        });
                    }
                });
            } else {
                if (statusSend > 0) {
                    Swal.fire({
                        title: "ส่งออกข้อมูล",
                        text: "ตรวจสอบรายการในเว็บ New Eclaim ก่อนจะส่งออกข้อมูลซ้ำ",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "ยืนยัน",
                        confirmButtonColor: "green",
                        cancelButtonText: "ยกเลิก",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            SendClaim(data_send_claim);
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.fire({
                                text: "ยกเลิกรายการแล้ว",
                                confirmButtonText: "ตกลง",
                            });
                        }
                    });
                } else {
                    const filtered = res_claim.filter((obj) => obj.PERMITNO === "" || obj.pre_audit.length > 0);
                    if (filtered.length > 0) {
                        Swal.fire({
                            title: "ส่งออกข้อมูล",
                            text: "คุณต้องการส่งออกข้อมูลที่ไม่ผ่านการ Pre Audit หรือไม่?",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "ยืนยัน",
                            confirmButtonColor: "green",
                            cancelButtonText: "ยกเลิก",
                            reverseButtons: true,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                SendClaim(data_send_claim);
                            } else if (result.dismiss === Swal.DismissReason.cancel) {
                                Swal.fire({
                                    text: "ยกเลิกรายการแล้ว",
                                    confirmButtonText: "ตกลง",
                                });
                            }
                        });
                    } else {
                        Swal.fire({
                            title: "ส่งออกข้อมูล",
                            text: "คุณต้องการส่งออกข้อมูลหรือไม่?",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "ยืนยัน",
                            confirmButtonColor: "green",
                            cancelButtonText: "ยกเลิก",
                            reverseButtons: true,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                SendClaim(data_send_claim);
                            } else if (result.dismiss === Swal.DismissReason.cancel) {
                                Swal.fire({
                                    text: "ยกเลิกรายการแล้ว",
                                    confirmButtonText: "ตกลง",
                                });
                            }
                        });
                    }
                }
            }
        } else {
            Swal.fire({
                icon: "warning",
                title: `ไม่มีข้อมูลส่งออก`,
                text: "กรุณาเลือกข้อมูลส่งออกอย่างน้อย 1 รายการ",
                confirmButtonText: "ตกลง",
            });
        }
    };

    const SendClaim = async (obj) => {
        if (profile.option_claim === null) {
            Swal.fire({
                icon: "warning",
                text: `กรุณาเลือกรูปแบบการเคลมก่อนส่งเคลม`,
                confirmButtonText: "ตกลง",
            });
        } else {
            try {
                setLoading(true);
                let res = await POST(profile.option_claim === 1 ? SENDCLAIM1 : SENDCLAIM2, {
                    date_start: dateStart,
                    date_end: dateEnd,
                    data: obj,
                    claim_type: type,
                });
                if (res.success) {
                    setLoading(false);
                    Swal.fire({
                        icon: "success",
                        text: `ส่งออกข้อมูลสำเร็จ`,
                        confirmButtonText: "ตกลง",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // window.location.reload();
                            getClaim();
                        }
                    });
                } else {
                    setLoading(false);
                    Swal.fire({
                        icon: "warning",
                        text: `${res.message}`,
                        showConfirmButton: true,
                    });
                }
            } catch (error) {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${error}`,
                    confirmButtonText: "ตกลง",
                });
            }
        }
    };

    const SendAuthen = async (item) => {
        try {
            setLoading(true);
            // let res = await POST("/opd/authen_permitno", { data: item.filter((e) => !e.PERMITNO) });
            let res = await POST("/opd/authen_permitno", { data: item });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: res.result,
                    confirmButtonText: "ตกลง",
                }).then((result) => {
                    if (result.isConfirmed) {
                        // window.location.reload();
                        getClaim();
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const handleChangeCheckbox = (event) => {
        let id = event.target.value;
        // let name = event.target.name;
        // console.log(event.target.name);

        if (event.target.name === "ทั้งหมด") {
            // let data = []
            data.map((e) =>
                setData((prevData) =>
                    prevData.map((item) =>
                        Number(item.index) === Number(e.index)
                            ? { ...item, check: event.target.checked, status: event.target.checked === true ? 1 : 0 }
                            : item
                    )
                )
            );
            // setData(data)
        } else {
            setData((prevData) =>
                prevData.map((item) =>
                    Number(item.index) === Number(id) ? { ...item, check: event.target.checked, status: event.target.checked === true ? 1 : 0 } : item
                )
            );
        }
    };

    const fetch = useMemo(
        () =>
            debounce(async (request, date_start, date_end, status, type, statusSend) => {
                try {
                    setLoading(true);
                    const payload = {
                        date_start: date_start,
                        date_end: date_end,
                        status_c: status,
                        text: request,
                        claim_type: type,
                        status_claim: statusSend,
                    };
                    let res = await POST(GETCLAIM, payload);
                    if (res.success) {
                        let data = [];
                        let data_claim = res.result;
                        data_claim.map((e, i) =>
                            data.push({
                                ...e,
                                check: false,
                                status: 0,
                                index: i,
                            })
                        );
                        setData(data);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        Swal.fire({
                            icon: "warning",
                            text: `${res.message}`,
                            showConfirmButton: true,
                        });
                    }
                } catch (error) {
                    setLoading(false);
                    Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
                }
            }, 1500),
        []
    );

    useEffect(() => {
        getDateUpdate();
        getDataType();
    }, []);

    useEffect(() => {
        if (search.length >= 3) {
            fetch(search, dateStart, dateEnd, status, type, statusSend);
        } else if (!search) {
            getClaim();
            return undefined;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, fetch, dateStart, dateEnd, status, type, statusSend]);

    return (
        <>
            {/* <Container maxWidth="xl" sx={{ position: "relative" }}> */}
            <BackdropLoad loading={loading} />
            <ClaimCard
                header={statusSend === 3 ? headerTable.claim_not : statusSend === 99 ? headerTable.claim_all : headerTable.claim}
                data={data}
                handleChangeCheckbox={handleChangeCheckbox}
                checkAll={data.findIndex((x) => x.status === 0)}
                dateStart={dateStart}
                dateEnd={dateEnd}
                setDateStart={(e) => setDateStart(e)}
                setDateEnd={(e) => setDateEnd(e)}
                handleSendClaim={handleSendClaim}
                setStatus={setStatus}
                status={status}
                search={search}
                setSearch={setSearch}
                type={type}
                setType={setType}
                dataType={dataType}
                statusSend={statusSend}
                setStatusSend={setStatusSend}
                dateUpdate={dateUpdate}
            />
            {/* </Container> */}
        </>
    );
}

export default Claim16;
