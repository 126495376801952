import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import { Grid } from "@mui/material";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, ChartDataLabels);

export default function Barchart2({ information }) {
    console.log("information:", information);
    const options = {
        responsive: true,
        interaction: {
            mode: "index",
            intersect: false,
        },
        stacked: false,
        plugins: {
            datalabels: {
                display: function (context) {
                    return context.chart.isDatasetVisible(context.datasetIndex);
                },
                borderRadius: 4,
                align: "end",
                anchor: "end",
                color: "black",
                backgroundColor: "#BEBEBD",
                formatter: function (value) {
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
            },
            legend: {
                display: true,
            },
            title: {
                display: true,
                text: "จำนวน",
            },
        },
        scales: {
            y: {
                type: "linear",
                display: true,
                position: "left",
            },
        },
    };

    const labels = information.map((e) => e.month);

    const data = {
        labels,
        datasets: [
            {
                label: "จำนวน",
                data: information.map((e) => e.total_responses),
                borderColor: "#ec407a",
                backgroundColor: "transparent",
            },
        ],
    };

    return (
        <Grid container spacing={2}>
            {/* <Grid item xs={12}>
                <Typography variant="h6" component="div" gutterBottom>
                %ข้อมูลที่ผิดปกติภาพรวมรายแฟ้ม
                </Typography>
            </Grid> */}
            <Grid item xs={12}>
                <Line data={data} options={options} style={{ overflowX: "auto", overflowY: "auto", maxHeight: "500px", width: "100%" }} />
            </Grid>
        </Grid>
    );
}
