import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, scales, Title, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useState } from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export default function BarChartTopDiagnose({ topData }) {
    const options = {
        indexAxis: "y",
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        // maintainAspectRatio: false,
        aspectRatio: 0.5,
        plugins: {
            datalabels: {
                display: function (context) {
                    return context.chart.isDatasetVisible(context.datasetIndex);
                },
                borderRadius: 4,
                align: "end",
                anchor: "end",
                color: "black",
                backgroundColor: "#BEBEBD",
                font: {
                    size: 7.75,
                },
            },
            legend: {
                display: false,
            },
            tooltip: {
                mode: "index",
                intersect: false,
                callbacks: {
                    label: function (context) {
                        var label = context.dataset.label || "";
                        var value = context.formattedValue;
                        return `${label} : ${value} `;
                    },
                },
            },
        },
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                ticks: {
                    callback: function (value) {
                        const label = this.getLabelForValue(value);
                        return label.length > 20 ? label.substr(0, 20).trim() + "..." : label;
                    },
                },
            },
        },
    };
    const labels = topData.map((e) => e.diagcode_th);
    const data = {
        labels,
        datasets: [
            {
                label: "จำนวน",
                data: topData.map((e) => e.count),
                borderColor: "rgb(54, 162, 235)",
                backgroundColor: "rgba(54, 162, 235, 0.5)",
                barPercentage: 1,
                // barThickness: 15,
            },
        ],
    };
    return (
        <Grid container columns={12}>
            <Grid item xs={12} class="chartWrapper" style={{ height: `${data.length * 10}px` }}>
                {topData.length > 0 ? (
                    <Box class="chartAreaWrapper">
                        <Bar data={data} options={options} className="chartWrapper-Bar" />
                    </Box>
                ) : (
                    <Box className="census-info-empty-dashboard">
                        <Typography>* ไม่มีข้อมูล *</Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}
