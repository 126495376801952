import { debounce } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import BackdropLoad from "../../../components/Backdrop/BackdropLoad";
import { ADDPREGNANT, DELETEPREGNANT, GET, GETPREGNANT, GETPREGNANTBY, GETPREGNANTMASTER, POST, UPDATEPREGNANT } from "../../../service";
import { getToken } from "../../../untils/shortcut";
import { headerTable } from "../../../untils/static";
import DialogAdd from "./component/DialogAdd";
import DialogEdit from "./component/DialogEdit";
import PregnantCard from "./component/PregnantCard";

function Pregnant() {

    const profile = getToken(localStorage.getItem("profile"));

    const [data, setData] = useState([]);
    const [dataMaster, setDataMaster] = useState([]);
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [pregnantId, setPregnantId] = useState('')
    const [pregnantMasterId, setPregnantMasterId] = useState('')

    const getPregnantMaster = async () => {
        try {
            setLoading(true);
            let res = await GET(GETPREGNANTMASTER);
            if (res.success) {
                setDataMaster(res.result)
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const getDataPregnant = async () => {
        try {
            setLoading(true);
            let res = await POST(GETPREGNANT, { text: search });
            if (res.success) {
                setData(res.result)
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const getDataPregnantById = async (id) => {
        try {
            setLoading(true);
            let res = await POST(GETPREGNANTBY, { drug_pregnant_id: id });
            if (res.success) {
                // setData(res.result)
                setPregnantId(res.result.drug_pregnant_id)
                setCode(res.result.drug_pregnant_code)
                setName(res.result.drug_pregnant_name)
                setPregnantMasterId(res.result.drug_pregnant_master_id)
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const handleAdd = async () => {
        try {
            setLoading(true);
            let res = await POST(ADDPREGNANT, { hcode: profile.location_code, drug_pregnant_name: name, drug_pregnant_code: code, drug_pregnant_master_id: pregnantMasterId });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    text: `เพิ่มข้อมูลสำเร็จ`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const handleUpdate = async (id) => {
        try {
            setLoading(true);

            let data = [];

            data.push({
                drug_pregnant_name: name,
                drug_pregnant_code: code,
                drug_pregnant_master_id: pregnantMasterId
            })

            let res = await POST(UPDATEPREGNANT, { drug_pregnant_id: id, data: data[0] });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    text: `แก้ไขข้อมูลสำเร็จ`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const handleDelete = (hcode, drug_pregnant_id) => {
        try {
            setLoading(true);
            // console.log(res);
            setLoading(false);
            Swal.fire({
                title: "ลบข้อมูล",
                text: "คุณต้องการลบข้อมูลหรือไม่?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "ยืนยัน",
                confirmButtonColor: "green",
                cancelButtonText: "ยกเลิก",
                reverseButtons: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let res = await POST(DELETEPREGNANT, { hcode, drug_pregnant_id });
                    if (res.success) {
                        setLoading(false);
                        getDataPregnant();
                    } else {
                        setLoading(false);
                        Swal.fire({
                            icon: "warning",
                            text: `${res.message}`,
                            showConfirmButton: true,
                        });
                    }
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire({
                        text: "ยกเลิกรายการแล้ว",
                        confirmButtonText: "ตกลง",
                    });
                }
            });
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClickOpenAdd = () => {
        setName('')
        setCode('')
        setPregnantMasterId('')
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setName('')
        setCode('')
        setPregnantMasterId('')
        setOpenAdd(false);
    };

    const handleClickOpenEdit = (id) => {
        getDataPregnantById(id);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const fetch = useMemo(
        () =>
            debounce(async (request) => {
                try {
                    setLoading(true)
                    const payload = { text: request };
                    let res = await POST(GETPREGNANT, payload)
                    if (res.success) {
                        setData(res.result)
                        setLoading(false)
                    } else {
                        setLoading(false)
                        Swal.fire({
                            icon: 'warning',
                            text: `${res.message}`,
                            showConfirmButton: true,
                        })
                    }
                } catch (error) {
                    setLoading(false)
                    Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
                }
            }, 1500),
        [],
    );

    useEffect(() => {
        getPregnantMaster();
    }, []);

    useEffect(() => {
        if (search.length > 3) {
            fetch(search);
        } else if (!search) {
            getDataPregnant();
            return undefined;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, fetch]);

    return (
        <>
            <BackdropLoad loading={loading} />
            <PregnantCard
                header={headerTable.pregnant_tbl}
                data={data}
                search={search}
                setSearch={setSearch}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleClickOpenAdd={handleClickOpenAdd}
                handleClickOpenEdit={handleClickOpenEdit}
                handleDelete={handleDelete}
            />
            <DialogAdd
                open={openAdd}
                handleClose={handleCloseAdd}
                setCode={setCode}
                code={code}
                handleAdd={handleAdd}
                dataMaster={dataMaster}
                setName={setName}
                name={name}
                setPregnantMasterId={setPregnantMasterId}
                pregnantMasterId={pregnantMasterId}
            />
            <DialogEdit
                open={openEdit}
                handleClose={handleCloseEdit}
                setCode={setCode}
                code={code}
                handleUpdate={handleUpdate}
                pregnantId={pregnantId}
                dataMaster={dataMaster}
                setName={setName}
                name={name}
                setPregnantMasterId={setPregnantMasterId}
                pregnantMasterId={pregnantMasterId}
            />
        </>
    );
}
export default Pregnant;
