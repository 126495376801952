import { Autocomplete, Box, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { Dashboard } from "../../../untils/icons";
import Barchart from "./Barchart";

function GridCard({ year, setYear, yearsData, dataVaccineRepDK, hname, setHname, setHcode, profile, dataHosp }) {
    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={5}>
                        <Grid item xs={12} className="flex-start">
                            <Stack direction="row" spacing={2}>
                                <Dashboard fontSize="medium" />
                                <Typography className="main-header">Dashboard รายงานการเงินวัคซีน Krungthai Digital</Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} mt={3}>
                            <Autocomplete
                                disabled={profile.role_id !== 1 && profile.role_id !== 2}
                                fullWidth
                                getOptionLabel={(option) => (typeof option === "string" ? option : `${option.hcode} ${option.hname}`)}
                                options={dataHosp}
                                autoComplete
                                includeInputInList
                                filterSelectedOptions
                                value={hname}
                                noOptionsText="No Hospital"
                                onChange={(event, value) => {
                                    setHname(value);
                                    if (!value) {
                                        return;
                                    }
                                    if (value) {
                                        console.log("value:", value.hcode);
                                        setHcode(value.hcode);
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="รพ.สต." />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} mt={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink>ปีงบประมาณ</InputLabel>
                                <Select
                                    name="year"
                                    value={year}
                                    label="เลือกปีงบประมาณ"
                                    required
                                    onChange={(e) => setYear(e.target.value)}
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled>
                                        เลือกปีงบประมาณ
                                    </MenuItem>
                                    {yearsData.map((year) => (
                                        <MenuItem key={year.value} value={year.value}>
                                            {Number(year.value) + 543}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* Chart */}
                        <Grid item xs={12}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">รายได้ตามปีงบประมาณ</Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <Barchart information={dataVaccineRepDK} />
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
}

export default GridCard;
