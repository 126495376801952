import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRoute } from '../../state/ManageRoute';

function Guard({ children }) {

    const router = useNavigate();
    const [isAuth, setIsAuth] = useState(false);
    const screen = JSON.parse(localStorage.getItem('screen'));
    let intersections_home = screen.filter((e) => e.type === 0);

    // const menu = JSON.parse(localStorage.getItem('menu'));

    const [setMenu, menu, setSystem] =
        useRoute((state) => [
            state.setMenu,
            state.menu,
            state.setSystem,
        ]);

    useEffect(() => {
        const init = () => {
            const path = window.location.pathname;
            const strs = path.split("/");
            if (screen) {
                if (screen.some((s) => s.url === `/${strs[1]}`)) {
                    setIsAuth(true)
                    return;
                }
                if (`/${strs[1]}` === "/Homepage") {
                    setIsAuth(true)
                    return;
                }
                router('/401');
            } else {
                router('/Login');
            }
            // setIsAuth(true)
            // return;
        }
        init();
    }, [])

    useEffect(() => {
        if (!menu) {
            setMenu(intersections_home)
            setSystem("หน้าหลัก")
            // window.localStorage.removeItem("menu");
            // window.localStorage.setItem("menu", JSON.stringify(menuSideBar.menu_home));
            // window.localStorage.removeItem("system");
            // window.localStorage.setItem("system", "หน้าหลัก");
        }
    }, []);

    return (
        <>
            {isAuth &&
                <>
                    {children}
                </>
            }
        </>
    )
}

export default Guard;