import React, { useEffect, useState } from "react";

import { Container } from "@mui/material";

import dayjs from "dayjs";
import Swal from "sweetalert2";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import { GETDATAPHYPER, POST, SELECTDATAHYPER } from "../../service";
import { getToken } from "../../untils/shortcut";
import { headerTable } from "../../untils/static";
import K111HypertensionCard from "./K111Hypertension/K111HypertensionCard";

function K111Hypertension() {
    const profile = getToken(localStorage.getItem("profile"));
    const year_length = new Date().getFullYear() - 1;
    const yearsData = Array.from({ length: 5 }, (_, index) => {
        return { value: year_length + index };
    });
    const [data, setData] = useState([]);
    const [dataSelect, setDataSelect] = useState([]);
    const [dataTbl2, setDataTbl2] = useState([]);
    const [dataGraph, setDataGraph] = useState([]);
    const [hname, setHname] = useState("");
    const [dataHname, setDataHname] = useState([]);
    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState(dayjs().year());
    const [searchData, setSearchData] = useState({ hname: "" });

    const onSelect = async (name, value) => {
        let searchDataTemp = { ...searchData };
        if (name === "hname") {
            searchDataTemp = { ...searchDataTemp, [name]: value };
        }
        getDataSelect({ ...searchDataTemp });
        setSearchData((Prev) => ({ ...Prev, ...searchDataTemp }));
    };

    const getK111Hypertension = async (data = {}) => {
        try {
            setLoading(true);
            let res = await POST(GETDATAPHYPER, { hname: data.hname !== "ทั้งหมด" ? data.hname : "", year_quarter: data.year ?? year });
            if (res.success) {
                setData(res.result.tableOne);
                setDataTbl2(res.result.tableTwo);
                setDataGraph(res.result.graph);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `ค้นหาไม่สำเร็จ`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const getDataSelect = async (data = {}) => {
        try {
            let res = await POST(SELECTDATAHYPER, { hname: data.hname ?? "" });
            if (res.success) {
                res.result.unshift("ทั้งหมด");
                let obj = res.result;

                setDataHname([...obj]);
                if (profile.role_id !== 1 && profile.role_id !== 2) {
                    let dataTemp = { hname: "" };
                    if (obj) {
                        dataTemp.hname = obj[1];
                    }
                    setSearchData((Prev) => ({ ...Prev, ...dataTemp }));
                    getK111Hypertension({ ...data, ...dataTemp });
                } else {
                    getK111Hypertension(data);
                }
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `ค้นหาไม่สำเร็จ`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    // useEffect(() => {
    //     getK111Pressure();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [hname, year]);

    useEffect(() => {
        getDataSelect(searchData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hname, year]);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <K111HypertensionCard
                profile={profile}
                data={data}
                dataTbl2={dataTbl2}
                setData={setData}
                header={headerTable.Hypertension_header}
                dataSelect={dataSelect}
                dataHname={dataHname}
                setDataSelect={setDataSelect}
                hname={searchData.hname}
                setHname={setHname}
                year={year}
                yearsData={yearsData}
                setYear={setYear}
                dataGraph={dataGraph}
                onSearch={onSelect}
            />
        </Container>
    );
}

export default K111Hypertension;
