import {
     Checkbox,
     Chip,
     FormControl,
     FormControlLabel,
     FormGroup,
     Grid,
     Paper,
     Table,
     TableBody,
     TableContainer,
     TableHead,
     TablePagination,
     TableRow,
     Typography,
} from "@mui/material";
import { StyledTableCell } from "../../../../Theme";
import { useState } from "react";
import moment from "moment";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";

export default function TableList({ header, data, statusSend, checkAll, handleChangeCheckbox }) {
     const [page, setPage] = useState(0);
     const [rowsPerPage, setRowsPerPage] = useState(10);

     const handleChangePage = (event, newPage) => {
          setPage(newPage);
     };

     const handleChangeRowsPerPage = (event) => {
          setRowsPerPage(+event.target.value);
          setPage(0);
     };

     return (
          <>
               <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ maxHeight: 800 }}>
                         <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                   <TableRow>
                                        {statusSend === 0 || statusSend === 1 || statusSend === 2 ? (
                                             <StyledTableCell align="left">
                                                  {data.length > 0 ? (
                                                       <FormControlLabel
                                                            label="ทั้งหมด"
                                                            control={
                                                                 <Checkbox
                                                                      checked={checkAll === -1 ? true : false}
                                                                      name="ทั้งหมด"
                                                                      onChange={(e) => handleChangeCheckbox(e)}
                                                                 />
                                                            }
                                                       />
                                                  ) : (
                                                       <FormControlLabel
                                                            label="ทั้งหมด"
                                                            control={
                                                                 <Checkbox checked={false} name="ทั้งหมด" onChange={(e) => handleChangeCheckbox(e)} />
                                                            }
                                                       />
                                                  )}
                                             </StyledTableCell>
                                        ) : (
                                             <StyledTableCell></StyledTableCell>
                                        )}

                                        {header.map((column, i) => (
                                             <StyledTableCell key={i + 1} align={column.align} style={{ minWidth: column.minWidth }}>
                                                  <Typography fontWeight="bold" fontSize="14px">
                                                       {column.label}
                                                  </Typography>
                                             </StyledTableCell>
                                        ))}
                                   </TableRow>
                              </TableHead>
                              <TableBody>
                                   {Array.isArray(data) &&
                                        data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                             return (
                                                  <TableRow key={i + 1}>
                                                       {statusSend === 0 || statusSend === 1 || statusSend === 2 ? (
                                                            <StyledTableCell align="left">
                                                                 <FormControl component="fieldset" variant="standard">
                                                                      <FormGroup>
                                                                           <FormControlLabel
                                                                                control={
                                                                                     <Checkbox
                                                                                          onChange={(e) => handleChangeCheckbox(e)}
                                                                                          checked={row.check}
                                                                                          value={row.index}
                                                                                          name={row.HN}
                                                                                     />
                                                                                }
                                                                           />
                                                                      </FormGroup>
                                                                 </FormControl>
                                                            </StyledTableCell>
                                                       ) : (
                                                            <StyledTableCell></StyledTableCell>
                                                       )}

                                                       <StyledTableCell align="center">{row.HN ? row.HN : ""}</StyledTableCell>
                                                       <StyledTableCell align="center">{row.NAMEPAT}</StyledTableCell>
                                                       <StyledTableCell align="center">{row.DETAIL}</StyledTableCell>
                                                       <StyledTableCell align="left">
                                                            {row.medicine && row.medicine.length > 0
                                                                 ? row.medicine.map((el, ind) => (
                                                                        <Grid key={ind + 1} style={{ marginBottom: 3, marginTop: 3 }}>
                                                                             <Chip
                                                                                  icon={<MedicalServicesIcon fontSize="small" />}
                                                                                  label={el.name}
                                                                                  variant="outlined"
                                                                                  color="primary"
                                                                             />
                                                                        </Grid>
                                                                   ))
                                                                 : null}
                                                       </StyledTableCell>
                                                       <StyledTableCell align="left">
                                                            {row.response ? (
                                                                 <Chip
                                                                      label={row.response}
                                                                      color={row.response.includes("สำเร็จ") ? "success" : "error"}
                                                                 />
                                                            ) : (
                                                                 <></>
                                                            )}
                                                       </StyledTableCell>
                                                       <StyledTableCell align="center">
                                                            {row.DATEOPD ? moment(row.DATEOPD).format("DD/MM/YYYY") : "N/A"}
                                                       </StyledTableCell>
                                                       <StyledTableCell align="center">
                                                            {statusSend === 1 ? (
                                                                 <Typography color="green">ผ่านการปิดสิทธิ์</Typography>
                                                            ) : statusSend === 2 ? (
                                                                 <Typography color="red">ไม่ผ่านการปิดสิทธิ์</Typography>
                                                            ) : statusSend === 3 ? (
                                                                 <Typography color="red">ข้อมูลซ้ำในระบบ</Typography>
                                                            ) : statusSend === 4 ? (
                                                                 <Typography color="red">ยกเลิกปิดสิทธิ์</Typography>
                                                            ) : (
                                                                 <Typography color="green">รอปิดสิทธิ์</Typography>
                                                            )}
                                                       </StyledTableCell>
                                                       <StyledTableCell align="center">{row.SEQ ? row.SEQ : "N/A"}</StyledTableCell>
                                                  </TableRow>
                                             );
                                        })}
                              </TableBody>
                         </Table>
                    </TableContainer>
                    {data.length === 0 ? (
                         <Grid container justifyContent="center" my={3}>
                              <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                         </Grid>
                    ) : (
                         <TablePagination
                              rowsPerPageOptions={[10, 25, 100]}
                              component="div"
                              count={data.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                         />
                    )}
               </Paper>
          </>
     );
}
