import { Autocomplete, Box, Container, FormControl, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { Dashboard, NotPassIcon, PassIcon } from "../../../untils/icons";
import { headerTable } from "../../../untils/static";
import GridTable from "./GridTable";
import GridTablePass from "./GridTablePass";

function GridCard({
    dateStart,
    dateEnd,
    setCleared,
    setDateStart,
    setDateEnd,
    dataNotPassClaim,
    dataPassClaim,
    setHcode,
    setHname,
    hname,
    profile,
    dataHosp,
    pageNotPass,
    rowsPerPageNotPass,
    pagePass,
    rowsPerPagePass,
    handleChangePagePass,
    handleChangeRowsPerPagePass,
    handleChangePageNotPass,
    handleChangeRowsPerPageNotPass,
    lengthNotPass,
    lengthPass,
}) {
    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={5}>
                        <Grid item xs={12} className="flex-start">
                            <Stack direction="row" spacing={2}>
                                <Dashboard fontSize="medium" />
                                <Typography className="main-header">Dashboard รายงานการส่งเคลมผ่านและไม่ผ่านตรวจสอบจาก สปสช.</Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <Autocomplete
                                disabled={profile.role_id !== 1 && profile.role_id !== 2}
                                fullWidth
                                getOptionLabel={(option) => (typeof option === "string" ? option : `${option.hcode} ${option.hname}`)}
                                options={dataHosp}
                                autoComplete
                                includeInputInList
                                filterSelectedOptions
                                value={hname}
                                noOptionsText="No Hospital"
                                onChange={(event, value) => {
                                    setHname(value);
                                    if (!value) {
                                        return;
                                    }
                                    if (value) {
                                        // console.log("value:", value.hcode);
                                        setHcode(value.hcode);
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="รพ.สต." />}
                            />
                        </Grid>

                        <Grid item xs={6} sm={6} md={3}>
                            <FormControl fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={dateStart}
                                        slotProps={{
                                            field: { clearable: true, onClear: () => setCleared(true) },
                                        }}
                                        label="วันที่เริ่มต้น"
                                        onChange={(e) => setDateStart(e)}
                                        views={["year", "month", "day"]}
                                        renderInput={(params) => <TextField {...params} helperText={null} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <FormControl fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={dateEnd}
                                        slotProps={{
                                            field: { clearable: true, onClear: () => setCleared(true) },
                                        }}
                                        label="วันที่สิ้นสุด"
                                        onChange={(e) => setDateEnd(e)}
                                        views={["year", "month", "day"]}
                                        renderInput={(params) => <TextField {...params} helperText={null} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>

                        {/* Chart */}
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                <PassIcon fontSize="medium" />
                                <Typography className="main-header">รายงานการส่งเคลมที่ผ่านการตรวจสอบ</Typography>
                            </Stack>
                            <Box>
                                <GridTablePass
                                    dataPassClaim={dataPassClaim}
                                    header={headerTable.Dashboard_Pass_Claim}
                                    handleChangePage={handleChangePagePass}
                                    handleChangeRowsPerPage={handleChangeRowsPerPagePass}
                                    rowsPerPage={rowsPerPagePass}
                                    page={pagePass}
                                    lengthPass={lengthPass}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                <NotPassIcon fontSize="medium" />
                                <Typography className="main-header">รายงานการส่งเคลมที่ไม่ผ่านการตรวจสอบ</Typography>
                            </Stack>
                            <Box>
                                <GridTable
                                    dataNotPassClaim={dataNotPassClaim}
                                    header={headerTable.Dashboard_NotPass_Claim}
                                    handleChangePage={handleChangePageNotPass}
                                    handleChangeRowsPerPage={handleChangeRowsPerPageNotPass}
                                    rowsPerPage={rowsPerPageNotPass}
                                    page={pageNotPass}
                                    lengthNotPass={lengthNotPass}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
}

export default GridCard;
