import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import MobileStepper from "@mui/material/MobileStepper";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import * as React from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { CloseIcn } from "../untils/icons";
const pathapi = process.env.REACT_APP_SERVICE;

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
     {
          label: "step1.png",
          imgPath: `${pathapi}/api/image/step1.png?time=${new Date()}`,
     },
     {
          label: "step2.png",
          imgPath: `${pathapi}/api/image/step2.png?time=${new Date()}`,
     },
     {
          label: "step3.png",
          imgPath: `${pathapi}/api/image/step3.png?time=${new Date()}`,
     },
     {
          label: "step4.png",
          imgPath: `${pathapi}/api/image/step4.png?time=${new Date()}`,
     },
     {
          label: "step5.png",
          imgPath: `${pathapi}/api/image/step5.png?time=${new Date()}`,
     },
];

function ModalStepper(props) {
     const theme = useTheme();
     const [activeStep, setActiveStep] = React.useState(0);
     const maxSteps = images.length;

     const handleNext = () => {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
     };

     const handleBack = () => {
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
     };

     const handleStepChange = (step) => {
          setActiveStep(step);
     };

     const handaleCheckBox = (e) => {
          if (e.target.checked) {
               window.localStorage.setItem("popup", dayjs(new Date()).add(7, "day").format("YYYY-MM-DD"));
          } else {
               window.localStorage.setItem("popup", "");
          }
     };

     return (
          <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
               <div style={{ textAlign: "right" }}>
                    <CloseIcn className="close-icn" onClick={props.handleClose} />
               </div>
               <AutoPlaySwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
               >
                    {images.map((step, index) => (
                         <div key={step.label}>
                              {Math.abs(activeStep - index) <= 2 ? (
                                   <Box
                                        component="img"
                                        sx={{
                                             height: 255,
                                             display: "block",
                                             maxWidth: 400,
                                             overflow: "hidden",
                                             width: "100%",
                                        }}
                                        src={step.imgPath}
                                        alt={step.label}
                                   />
                              ) : null}
                         </div>
                    ))}
               </AutoPlaySwipeableViews>
               <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                         <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                              Next
                              {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                         </Button>
                    }
                    backButton={
                         <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                              {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                              Back
                         </Button>
                    }
               />
               <div style={{ textAlign: "center", marginTop: 20 }}>
                    <Button fullWidth variant="contained" onClick={() => window.open(`${pathapi}/api/image/guidebook.pdf?time=${new Date()}`)}>
                         ดาวน์โหลดคู่มือการใช้งาน
                    </Button>
               </div>
               <div style={{ textAlign: "center", marginTop: 20 }}>
                    <Checkbox value={true} onChange={handaleCheckBox} /> ไม่แสดงอีก
               </div>
          </Box>
     );
}

export default ModalStepper;
