import {
     Checkbox,
     Chip,
     FormControl,
     FormControlLabel,
     FormGroup,
     Grid,
     Paper,
     Table,
     TableBody,
     TableContainer,
     TableHead,
     TablePagination,
     TableRow,
     Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { StyledTableCell } from "../../../../Theme";
export default function GridTable({ header, data, handleChangeCheckbox, checkAll, statusSend, type }) {
     const [page, setPage] = useState(0);
     const [rowsPerPage, setRowsPerPage] = useState(10);

     const handleChangePage = (event, newPage) => {
          setPage(newPage);
     };

     const handleChangeRowsPerPage = (event) => {
          setRowsPerPage(+event.target.value);
          setPage(0);
     };

     // const handleRoute = (path, detail) => {
     //      // console.log('dateStart', dateStart,dateEnd)
     //      return () => {
     //           // navigate(path, { state: { ...detail } });
     //           // console.log("detail", detail);
     //           window.open(
     //                `Claim16/${path}/${JSON.stringify({
     //                     HN: detail.HN,
     //                     SEQ: detail.SEQ,
     //                     DATEOPD: detail.DATEOPD,
     //                     HCODE: detail.HCODE,
     //                     TITLE: detail.TITLE,
     //                     NAMEPAT: detail.NAMEPAT,
     //                     INSCL: detail.INSCL,
     //                })}`,
     //                "_blank"
     //           );
     //      };
     // };

     return (
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
               <TableContainer sx={{ maxHeight: 800 }}>
                    <Table stickyHeader aria-label="sticky table">
                         <TableHead>
                              <TableRow>
                                   <StyledTableCell align="left">
                                        {data.length > 0 ? (
                                             <FormControlLabel
                                                  label="ทั้งหมด"
                                                  control={
                                                       <Checkbox
                                                            checked={checkAll === -1 ? true : false}
                                                            name="ทั้งหมด"
                                                            onChange={(e) => handleChangeCheckbox(e)}
                                                       />
                                                  }
                                             />
                                        ) : (
                                             <FormControlLabel
                                                  label="ทั้งหมด"
                                                  control={<Checkbox checked={false} name="ทั้งหมด" onChange={(e) => handleChangeCheckbox(e)} />}
                                             />
                                        )}
                                   </StyledTableCell>
                                   {header.map((column, i) => (
                                        <StyledTableCell key={i + 1} align={column.align} style={{ minWidth: column.minWidth }}>
                                             <Typography fontWeight="bold" fontSize="14px">
                                                  {column.label}
                                             </Typography>
                                        </StyledTableCell>
                                   ))}
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                   return (
                                        <TableRow key={i + 1}>
                                             <StyledTableCell align="left">
                                                  <FormControl component="fieldset" variant="standard">
                                                       <FormGroup>
                                                            <FormControlLabel
                                                                 control={
                                                                      <Checkbox
                                                                           onChange={(e) => handleChangeCheckbox(e)}
                                                                           checked={row.check}
                                                                           value={row.index}
                                                                           name={row.HN}
                                                                      />
                                                                 }
                                                            />
                                                       </FormGroup>
                                                  </FormControl>
                                                  {/* <MoreIcon sx={{ cursor: "pointer", mt: "10px" }} onClick={handleRoute(`Detail`, row)} /> */}
                                             </StyledTableCell>
                                             <StyledTableCell align="left">{row.hn ? row.hn : ""}</StyledTableCell>
                                             <StyledTableCell align="left">{row.name ? row.name : ""}</StyledTableCell>{" "}
                                             {(statusSend === 3 || statusSend === 99) && (
                                                  <StyledTableCell align="left">
                                                       {row.problem && <Chip label={row.problem} color="error" />}
                                                  </StyledTableCell>
                                             )}
                                             <StyledTableCell align="center">
                                                  {row.XXXXX ? moment(row.XXXXX).format("DD/MM/YYYY") : ""}
                                             </StyledTableCell>
                                             <StyledTableCell align="center">{row.date ? moment(row.date).format("DD/MM/YYYY") : ""}</StyledTableCell>
                                             {statusSend !== 3 && (
                                                  <StyledTableCell align="center">
                                                       {row.status_claim === 1 ? (
                                                            <Typography color="green">กำลังตรวจสอบ</Typography>
                                                       ) : row.status_claim === 2 ? (
                                                            <Typography color="green">ผ่านการตรวจสอบ</Typography>
                                                       ) : row.status_claim === 3 ? (
                                                            <Typography color="red">ไม่ผ่านการตรวจสอบ</Typography>
                                                       ) : (
                                                            <Typography color="skyblue">รอส่ง</Typography>
                                                       )}
                                                  </StyledTableCell>
                                             )}
                                             {/* <StyledTableCell align="right">{row.age ? row.age : ""}</StyledTableCell> */}
                                             <StyledTableCell align="center">{row.invno ? row.invno : ""}</StyledTableCell>
                                             <StyledTableCell align="center">{row.claimAmt ? row.claimAmt : ""}</StyledTableCell>
                                        </TableRow>
                                   );
                              })}
                         </TableBody>
                    </Table>
               </TableContainer>
               {data.length === 0 ? (
                    <Grid container justifyContent="center" my={3}>
                         <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                    </Grid>
               ) : (
                    <TablePagination
                         rowsPerPageOptions={[10, 25, 100]}
                         component="div"
                         count={data.length}
                         rowsPerPage={rowsPerPage}
                         page={page}
                         onPageChange={handleChangePage}
                         onRowsPerPageChange={handleChangeRowsPerPage}
                         labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                    />
               )}
          </Paper>
     );
}
