import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid, Skeleton, Stack } from "@mui/material";

export default function QualityDataCard() {
    return (
        <Card sx={{ minWidth: "100%", justifyContent: "space-between" }}>
            <CardContent>
                <Typography variant="h3" component="div" align="center" color='green'>
                    78.53%
                </Typography>
                <Skeleton animation={false} />
                <Grid container sx={12} sm={12} justifyContent="center">
                    <Stack direction="row" spacing={7}>
                        <Grid align="center">
                            <Typography variant="body2" fontWeight='bold'>
                                ข้อมูลทั้งหมด
                                <br />
                                {"14,902,279"}
                            </Typography>
                        </Grid>
                        <Grid align="center">
                            <Typography variant="body2" fontWeight='bold'>
                                คุณภาพของข้อมูล
                                <br />
                                {"11,703,221"}
                            </Typography>
                        </Grid>
                        <Grid align="center">
                            <Typography variant="body2" fontWeight='bold'>
                                คิดเป็น %
                                <br />
                                {"78.53"}
                            </Typography>
                        </Grid>
                    </Stack>
                </Grid>
            </CardContent>
        </Card>
    );
}
