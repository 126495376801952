import React, { useEffect, useMemo, useState } from "react";

import { debounce } from "@mui/material";

import dayjs from "dayjs";
import Swal from "sweetalert2";
import BackdropLoad from "../../../components/Backdrop/BackdropLoad.js";
import { GETDMHTALL, POST } from "../../../service";
import { headerTable } from "../../../untils/static";
import ClaimCard from "./components/ClaimCard.js";

function DMHT() {
     const [loading, setLoading] = useState(false);
     const [dateStart, setDateStart] = useState(dayjs(new Date()).startOf("month"));
     const [dateEnd, setDateEnd] = useState(dayjs(new Date()));
     const [data, setData] = useState([]);
     const [search, setSearch] = useState("");
     const [status, setStatus] = useState(1);

     const getClaimDMHT = async () => {
          try {
               setLoading(true);
               const payload = { date_start: dateStart, date_end: dateEnd, status_c: status, text: search };
               let res = await POST(GETDMHTALL, payload);
               if (res.success) {
                    let data = [];
                    let data_claim = res.result;

                    data_claim.map((e, i) =>
                         data.push({
                              ...e,
                              check: false,
                              status: 0,
                              index: i,
                         })
                    );
                    setData(data);
                    setLoading(false);
               } else {
                    setLoading(false);
                    Swal.fire({
                         icon: "warning",
                         text: `${res.message}`,
                         showConfirmButton: true,
                    });
               }
          } catch (error) {
               setLoading(false);
               Swal.fire({
                    icon: "warning",
                    text: `${error}`,
                    confirmButtonText: "ตกลง",
               });
          }
     };

     const handleSendClaim = async () => {
          let res_claim = data.filter((e) => e.check === true);
          let data_send_claim = [];
          // console.log(res_claim);
          res_claim.map((e) => data_send_claim.push({ seq: e.seq, date: e.date, hn: e.hn, idcard: e.idcard }));

          if (data_send_claim.length > 0) {
               Swal.fire({
                    title: "ส่งออกข้อมูล",
                    text: "คุณต้องการส่งออกข้อมูลหรือไม่?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "ยืนยัน",
                    confirmButtonColor: "green",
                    cancelButtonText: "ยกเลิก",
                    reverseButtons: true,
               }).then((result) => {
                    if (result.isConfirmed) {
                         SendClaim(data_send_claim);
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                         Swal.fire({
                              text: "ยกเลิกรายการแล้ว",
                              confirmButtonText: "ตกลง",
                         });
                    }
               });
          } else {
               Swal.fire({
                    icon: "warning",
                    title: `ไม่มีข้อมูลส่งออก`,
                    text: "กรุณาเลือกข้อมูลส่งออกอย่างน้อย 1 รายการ",
                    confirmButtonText: "ตกลง",
               });
          }
     };

     const SendClaim = async (obj) => {
          // console.log(obj);
          try {
               setLoading(true);
               let res = await POST("/claim/dmht_claim_by", { date_start: dateStart, date_end: dateEnd, data: obj });
               if (res.success) {
                    setLoading(false);
                    Swal.fire({
                         icon: "success",
                         text: res.result,
                         confirmButtonText: "ตกลง",
                    }).then((result) => {
                         if (result.isConfirmed) {
                              // window.location.reload();
                              getClaimDMHT();
                         }
                    });
               } else {
                    setLoading(false);
                    Swal.fire({
                         icon: "warning",
                         text: `${res.message}`,
                         showConfirmButton: true,
                    });
               }
          } catch (error) {
               setLoading(false);
               Swal.fire({
                    icon: "warning",
                    text: `${error}`,
                    confirmButtonText: "ตกลง",
               });
          }
     };

     const handleChangeCheckbox = (event) => {
          let id = event.target.value;
          // let name = event.target.name;
          // console.log(event.target.name);

          if (event.target.name === "ทั้งหมด") {
               // let data = []
               data.map((e) =>
                    setData((prevData) =>
                         prevData.map((item) =>
                              Number(item.index) === Number(e.index)
                                   ? { ...item, check: event.target.checked, status: event.target.checked === true ? 1 : 0 }
                                   : item
                         )
                    )
               );
               // setData(data)
          } else {
               setData((prevData) =>
                    prevData.map((item) =>
                         Number(item.index) === Number(id)
                              ? { ...item, check: event.target.checked, status: event.target.checked === true ? 1 : 0 }
                              : item
                    )
               );
          }
     };

     const fetch = useMemo(
          () =>
               debounce(async (request, date_start, date_end, status) => {
                    try {
                         setLoading(true);
                         const payload = { date_start: date_start, date_end: date_end, status_c: status, text: request };
                         let res = await POST(GETDMHTALL, payload);
                         if (res.success) {
                              let data = [];
                              let data_claim = res.result;
                              data_claim.map((e, i) =>
                                   data.push({
                                        ...e,
                                        check: false,
                                        status: 0,
                                        index: i,
                                   })
                              );
                              setData(data);
                              setLoading(false);
                         } else {
                              setLoading(false);
                              Swal.fire({
                                   icon: "warning",
                                   text: `${res.message}`,
                                   showConfirmButton: true,
                              });
                         }
                    } catch (error) {
                         setLoading(false);
                         Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
                    }
               }, 1500),
          []
     );

     useEffect(() => {
          if (search.length > 2) {
               fetch(search, dateStart, dateEnd, status);
          } else if (!search) {
               getClaimDMHT();
               return undefined;
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [search, fetch, dateStart, dateEnd, status]);

     const checkStatusClaim = async () => {
          try {
               setLoading(true);
               let res = await POST("/claim/transactions_moph_dmht", {});
               if (res.success) {
                    setLoading(false);
                    Swal.fire({
                         icon: "success",
                         text: res.result,
                         confirmButtonText: "ตกลง",
                    }).then((result) => {
                         if (result.isConfirmed) {
                              // window.location.reload();
                              getClaimDMHT();
                         }
                    });
               } else {
                    setLoading(false);
                    Swal.fire({
                         icon: "warning",
                         text: `${res.message}`,
                         showConfirmButton: true,
                    });
               }
          } catch (error) {
               setLoading(false);
               Swal.fire({
                    icon: "warning",
                    text: `${error}`,
                    confirmButtonText: "ตกลง",
               });
          }
     };
     return (
          <>
               <BackdropLoad loading={loading} />
               <ClaimCard
                    header={headerTable.claim_dmht}
                    data={data}
                    handleChangeCheckbox={handleChangeCheckbox}
                    checkAll={data.findIndex((x) => x.status === 0)}
                    dateStart={dateStart}
                    dateEnd={dateEnd}
                    setDateStart={(e) => setDateStart(e)}
                    setDateEnd={(e) => setDateEnd(e)}
                    handleSendClaim={handleSendClaim}
                    setStatus={setStatus}
                    status={status}
                    setSearch={setSearch}
                    handleStatusClaim={checkStatusClaim}
                    search={search}
               />
          </>
     );
}

export default DMHT;
