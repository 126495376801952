import { Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";

import { StyledTableCell } from "../../../Theme";

export default function GridTable({ header, subHeader, menu, menu2, header_end }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((column, index) => (
                                <StyledTableCell rowSpan={3} style={{ minWidth: 300, textAlign: "center" }} key={index}>
                                    {column.label}
                                </StyledTableCell>
                            ))}
                            {subHeader.map((item, index) => (
                                <StyledTableCell colSpan={3} style={{ minWidth: 300, textAlign: "center" }} key={index}>
                                    {item.label}
                                </StyledTableCell>
                            ))}
                            {header_end.map((item, index) => (
                                <StyledTableCell colSpan={2} style={{ minWidth: 300, textAlign: "center" }} key={index}>
                                    {item.label}
                                </StyledTableCell>
                            ))}
                            {menu2.map((item, index) => (
                                <StyledTableCell rowSpan={3} style={{ minWidth: 150, textAlign: "center" }} key={index}>
                                    {item.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            {Array(2)
                                .fill()
                                .map((_, round) =>
                                    menu.map((item, index) => (
                                        <StyledTableCell style={{ minWidth: 300, textAlign: "center" }} key={`${round}-${index}`}>
                                            {item.label}
                                        </StyledTableCell>
                                    ))
                                )}
                            {Array(4)
                                .fill()
                                .map((_, round) =>
                                    menu2.map((item, index) => (
                                        <StyledTableCell style={{ minWidth: 100, textAlign: "center" }} key={`${round}-${index}`}>
                                            {item.label}
                                        </StyledTableCell>
                                    ))
                                )}
                        </TableRow>
                        <TableRow>
                            {/* {header.datas?.map((item) => (
                                <>
                                    {item.vaccineData.map((vaccine_item) => {
                                        return (
                                            <>
                                                <StyledTableCell style={{ minWidth: 180, textAlign: "center" }}>{vaccine_item.qty}</StyledTableCell>
                                                <StyledTableCell style={{ minWidth: 180, textAlign: "center" }}>
                                                    {vaccine_item.qty_received}
                                                </StyledTableCell>
                                                <StyledTableCell style={{ minWidth: 180, textAlign: "center" }}>{vaccine_item.avg}</StyledTableCell>
                                            </>
                                        );
                                    })}
                                </>
                            ))} */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            return (
                                <TableRow key={index + 1}>
                                    <StyledTableCell align="center">{index + 1}</StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.name} {row.lastname}
                                    </StyledTableCell>
                                </TableRow>
                            );
                        })} */}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* {data.length === 0 ? (
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                </Grid>
            ) : (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            )} */}
        </Paper>
    );
}
