import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";

import { StyledTableCell, StyledTblK103 } from "../../../Theme";
import { tofieds } from "../../../untils/shortcut";

export default function GridTable({ header, headerSub, data, total }) {
    // console.log("data:", data);
    return (
        <Paper sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: 800, overflow: "auto" }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell rowSpan={1} colSpan={4} style={{ backgroundColor: "#ffffff", border: "none" }}></StyledTableCell>
                            {headerSub.map((column) => (
                                <StyledTblK103
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    colSpan={2}
                                    rowSpan={1}
                                    sx={{ top: 0, zIndex: 20 }}
                                >
                                    {column.label}
                                </StyledTblK103>
                            ))}
                        </TableRow>
                        <TableRow>
                            {header.map((column) => (
                                <StyledTblK103
                                    key={column.id}
                                    align={column.align}
                                    rowSpan={1}
                                    style={{ minWidth: column.minWidth, textAlign: "center", zIndex: 20, top: 58 }}
                                >
                                    {column.label}
                                </StyledTblK103>
                            ))}
                            {Array.from({ length: 3 }).map((_, index) => (
                                <React.Fragment key={index}>
                                    <StyledTblK103 colSpan={1} style={{ minWidth: 50, textAlign: "center", zIndex: 20, top: 58 }}>
                                        เดบิต
                                    </StyledTblK103>
                                    <StyledTblK103 colSpan={1} style={{ minWidth: 50, textAlign: "center", zIndex: 20, top: 58 }}>
                                        เครดิต
                                    </StyledTblK103>
                                </React.Fragment>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item, index) => (
                            <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? "#FAFAFA" : "#eeeeee" }}>
                                <StyledTblK103 align="left">{item.account_name}</StyledTblK103>
                                <StyledTblK103 align="left">{item.account_code}</StyledTblK103>
                                <StyledTblK103 align="left">{item.account_type}</StyledTblK103>
                                <StyledTblK103 align="left">{null}</StyledTblK103>
                                <StyledTblK103 align="left">{tofieds(item.quoted_debit)}</StyledTblK103>
                                <StyledTblK103 align="left">{tofieds(item.quoted_credit)}</StyledTblK103>
                                <StyledTblK103 align="left">{tofieds(item.between_debit)}</StyledTblK103>
                                <StyledTblK103 align="left">{tofieds(item.between_credit)}</StyledTblK103>
                                <StyledTblK103 align="left">{tofieds(item.debit)}</StyledTblK103>
                                <StyledTblK103 align="left">{tofieds(item.credit)}</StyledTblK103>
                            </TableRow>
                        ))}
                        <TableRow style={{ backgroundColor: "#eeeeee", color: "#fff", position: "sticky", bottom: 0, zIndex: 1 }}>
                            <StyledTblK103 align="left" colSpan={4} style={{ textAlign: "center" }}>
                                รวม
                            </StyledTblK103>
                            <StyledTblK103 align="left">{tofieds(total.totalQuotedDebit)}</StyledTblK103>
                            <StyledTblK103 align="left">{tofieds(total.totalQuotedCredit)}</StyledTblK103>
                            <StyledTblK103 align="left">{tofieds(total.totalBetweenDebit)}</StyledTblK103>
                            <StyledTblK103 align="left">{tofieds(total.totalBetweenCredit)}</StyledTblK103>
                            <StyledTblK103 align="left">{tofieds(total.totalDebit)}</StyledTblK103>
                            <StyledTblK103 align="left">{tofieds(total.totalCredit)}</StyledTblK103>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
