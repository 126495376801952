import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { GETSTATEMENTVACCINE, POST } from "../../service";
import { getToken } from "../../untils/shortcut";
import GridCard from "./DashboardStatementKrungthaiDigital/GridCard";

function DashboardStatementKrungthaiDigital() {
    const startYear = 2024;
    const year_length = startYear;
    const yearsData = Array.from({ length: 1 }, (_, index) => {
        return { value: year_length + index };
    });
    const [year, setYear] = useState(dayjs().year());
    const [cleared, setCleared] = useState(false);
    const [dataVaccineRepDK, setDataVaccineRepDK] = useState([]);
    const [hcode, setHcode] = useState("");
    const [hname, setHname] = useState("");
    const [dataHosp, setDataHosp] = useState([]);
    const [loading, setLoading] = useState(false);
    const profile = getToken(localStorage.getItem("profile"));

    const getDataStateMentVaccine = async () => {
        try {
            setLoading(true);
            let res = await POST(GETSTATEMENTVACCINE, { hcode, year });
            // console.log("res", res.result);
            if (res.success) {
                if (profile.role_id !== 1 && profile.role_id !== 2) {
                    setDataHosp(res.result.forSelect[0]);
                    setDataVaccineRepDK(res.result.vaccineRepDK[0]);
                    setLoading(false);
                }
                setDataHosp(res.result.forSelect);
                setDataVaccineRepDK(res.result.vaccineRepDK);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `ค้นหาไม่สำเร็จ`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getDataStateMentVaccine();
    }, [hcode, year]);

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => {};
    }, [cleared]);

    return (
        <>
            <GridCard
                cleared={cleared}
                setCleared={setCleared}
                yearsData={yearsData}
                year={year}
                setYear={setYear}
                dataVaccineRepDK={dataVaccineRepDK}
                hcode={hcode}
                hname={hname}
                setHcode={setHcode}
                setHname={setHname}
                dataHosp={dataHosp}
                profile={profile}
            />
        </>
    );
}

export default DashboardStatementKrungthaiDigital;
