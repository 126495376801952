import { Box, Grid, Typography } from "@mui/material";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

export default function PieChartDashBoard({ pDeath }) {
    const options = {
        plugins: {
            datalabels: {
                display: function (context) {
                    return context.chart.isDatasetVisible(context.datasetIndex);
                },
                borderRadius: 4,
                align: "center",
                anchor: "center",
                color: "black",
                backgroundColor: "#BEBEBD",
            },
            legend: {
                display: true,
                position: "right",
                labels: {
                    // This more specific font property overrides the global property
                    font: {
                        size: 16,
                        family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    },
                },
            },
        },
    };

    const data = {
        labels: pDeath.map((e) => e.location),
        datasets: [
            {
                label: "จำนวน",
                data: pDeath.map((e) => e.death),
                percent: pDeath.map((e) => e.dPercent),
                backgroundColor: ["rgba(255, 99, 132)", "rgba(54, 162, 235)", "rgba(255, 206, 86)"],
            },
        ],
    };
    return (
        <Grid container columns={12}>
            <Grid item xs={12} className="flex-center" style={{ overflowX: "auto", overflowY: "auto", maxHeight: "450px", width: "100%" }}>
                {pDeath.length > 0 ? (
                    <Doughnut data={data} options={options} />
                ) : (
                    <Box className="census-info-empty">
                        <Typography>* ไม่มีข้อมูล *</Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}
