import { Container } from "@mui/material";
import React from "react";
// import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import GridCard from "./DashboardHDC/GridCard";

function DashboardHDC() {
    // const [data, setData] = useState([]);
    // const [loading, setLoading] = useState(false);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            {/* <BackdropLoad loading={loading} /> */}
            <GridCard />
        </Container>
    );
}

export default DashboardHDC;