import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef, useState } from "react";
import { GeoJSON, MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import MarkerJS from "./Marker.js";

import L from 'leaflet';
import 'leaflet.locatecontrol'
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css'
import { CloseIcn } from "../../../untils/icons.js";
import { ButtonTeal, StyledTableCell, StyledTblK103 } from "../../../Theme.jsx";
import SearchIcon from '@mui/icons-material/Search';
import { CREATEPERSON, POST, SEARCHADDRESS, SEARCHINSCL } from '../../../service'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Swal from "sweetalert2";

const defaultIcon = L.icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  shadowSize: [41, 41],
});

const center = window.location.origin.includes("kk")
  ? [16.43192755724492, 102.82856609781523]
  : window.location.origin.includes("mdh")
    ? [16.59738946623057, 104.51104253757008]
    : window.location.origin.includes("spb")
      ? [14.651579065119135, 99.93989999520554]
      : [16.43192755724492, 102.82856609781523];

function MapChart({ data, subdistrictObj, home, getReloadMap }) {
  const [map, setMap] = useState(null);
  const [showGeoJSON, setShowGeoJSON] = useState(true);
  const [showGeoJSON2, setShowGeoJSON2] = useState(true);
  const [showMarker, setShowMarker] = useState(false);
  const timer = useRef();
  const [showBaby, setShowBaby] = useState(true);
  const [showKid, setShowKid] = useState(true);
  const [showTeens, setShowTeens] = useState(true);
  const [showAdult, setShowAdult] = useState(true);
  const [showMenopause, setShowMenopause] = useState(true);
  const [showOld, setShowOld] = useState(true);
  const [showMale, setShowMale] = useState(true);
  const [showFemale, setShowFemale] = useState(true);

  const [open, setOpen] = useState(false);
  const [state, setState] = useState({ province: 'ขอนแก่น' });
  const [datains, setData] = useState({});
  const [latlng, setLatLng] = useState({ lat: '', lng: '' });
  const [message, setMessage] = useState('')
  const [markerPosition, setMarkerPosition] = useState(null);
  const formRef = useRef();
  const [selectedOption, setSelectedOption] = useState('custom');
  const [selectedHome, setSelectedHome] = useState(home[1]);
  const [birthDate, setBirthDate] = useState(dayjs(new Date()));
  const [ampur, setAmpur] = useState([])
  const [tambon, setTambon] = useState([])

  const genderOptions = [
    { label: 'ชาย', value: 'ชาย' },
    { label: 'หญิง', value: 'หญิง' },
  ];

  const handleGenderChange = (event) => {
    setState({ ...state, sex: event.target.value });
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSearchins = async () => {
    try {
      let obj = {
        person_id: state.idCard,
      }
      let res = await POST(SEARCHINSCL, obj)
      if (res.success) {
        setMessage(res.result.message ? res.result.message : '')
        setData(res.result.data)
      }
    } catch (error) {

    }
  }

  const handleSavecustom = async () => {

    try {
      let res = await POST(CREATEPERSON, {
        latlng: latlng,
        fullName: state.fullName,
        idCard: state.idCard,
        birthDate: birthDate,
        sex: state.sex,
        nationDescription: state.nationDescription,
        mainInscl: state.mainInscl,
        hospSub: state.hospSub,
        selectedHome: selectedHome,
        house: state.house,
        village: state.village,
        soimain: state.soimain,
        road: state.road,
        tambon: state.tambon,
        ampur: state.ampur,
        province: state.province
      });

      if (res.success) {
        setOpen(false)
        setMarkerPosition(null)
        Swal.fire({
          icon: "success",
          text: 'บันทึกสำเร็จ',
          confirmButtonText: "ตกลง",

        }).then((result) => {
          getReloadMap();
        });
      } else {
        setOpen(false)
        setMarkerPosition(null)
        Swal.fire({
          icon: "warning",
          text: 'บันทึกไม่สำเร็จ',
          confirmButtonText: "ตกลง",
        });
      }
    } catch (error) {
      setOpen(false)
      console.error('เกิดข้อผิดพลาด:', error);
    }
  };

  const handleSaveother = async () => {

    // const isConfirmed = window.confirm('คุณต้องการบันทึกหรือไม่?');
    // if (!isConfirmed) {
    //   return;
    // }

    try {
      let { year, month, day } = datains.birthDateNew
      let res = await POST(CREATEPERSON, {
        latlng: latlng,
        fullName: datains.fullName,
        idCard: datains.pid,
        birthDate: `${year}${String(month).length === 1 ? `0${month}` : month}${String(day).length === 1 ? `0${day}` : day}`,
        sex: datains.sex,
        nationDescription: datains.nationDescription,
        mainInscl: datains.mainInscl,
        hospSub: datains.hospSub,
        selectedHome: selectedHome,
        house: state.house,
        village: state.village,
        soimain: state.soimain,
        road: state.road,
        tambon: state.tambon,
        ampur: state.ampur,
        province: state.province
      });

      if (res.success) {
        setOpen(false)
        setMarkerPosition(null)
        Swal.fire({
          icon: "success",
          text: 'บันทึกสำเร็จ',
          confirmButtonText: "ตกลง",

        }).then((result) => {
          getReloadMap();
        });
      } else {
        setOpen(false)
        setMarkerPosition(null)
        Swal.fire({
          icon: "warning",
          text: 'บันทึกไม่สำเร็จ',
          confirmButtonText: "ตกลง",
        });
      }
    } catch (error) {
      setOpen(false)
      console.error('เกิดข้อผิดพลาด:', error);
    }
  };

  const searchaddress = async (select) => {
    try {
      let res = await POST(SEARCHADDRESS, {
        select,
        tambon: state.tambon,
        ampur: state.ampur,
      })
      if (res.success) {
        if (select === '') {
          setAmpur(res.result)
        }
        if (select === 'ampur') {
          setTambon(res.result)
        }
      }
    } catch (error) {

    }
  }

  const MapEventHandler = () => {
    const longPressDuration = 1000;
    let clickTimer = 0;

    const handleMapClick = (e) => {
      if (clickTimer) {
        clearTimeout(clickTimer);
      }
      clickTimer = setTimeout(() => {
        setMarkerPosition(e.latlng);
        setOpen(true);
        setLatLng(e.latlng);
        searchaddress('');
      }, longPressDuration);
    };

    useMapEvents({
      contextmenu: handleMapClick
    });

    return null;
  };


  const popupFeature = (e) => {
    let layer = e.target;
    const { count, tam_th } = e.target.feature.properties;

    if (count > 0) {
      const popupOptions = {
        minWidth: 100,
        maxWidth: 250,
        className: "popup-classname",
      };

      layer
        .bindTooltip(() => {
          return `<b>${tam_th} : จำนวนผู้ป่วย ${count}</b>`;
        }, popupOptions)
        .openTooltip();
    }

    const originalStyle = {
      weight: layer.options.weight,
      color: layer.options.color,
      dashArray: layer.options.dashArray,
      fillOpacity: layer.options.fillOpacity,
    };

    layer.options.originalStyle = originalStyle;

    layer.setStyle({
      weight: 3,
      color: "#f00",
      dashArray: "",
      fillOpacity: 0.7,
    });
    layer.bringToFront();
  };

  const popupFeatureOut = (e) => {
    let layer = e.target;
    const originalStyle = layer.options.originalStyle;

    if (originalStyle) {
      layer.setStyle(originalStyle);
    }
    layer.bringToBack();
  };

  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: popupFeature,
      mouseout: popupFeatureOut,
    });
  };

  const mapPolygonColorToDensity = (d) => {
    if (d > 10000) return "#800026";
    if (d > 5000) return "#BD0026";
    if (d > 2000) return "#E31A1C";
    if (d > 1000) return "#FC4E2A";
    if (d > 500) return "#FD8D3C";
    if (d > 200) return "#FEB24C";
    if (d > 100) return "#FED976";
    if (d >= 1) return "#FFEDA0";
    return "#000";
  };

  const style = (feature) => {
    return {
      fillColor: mapPolygonColorToDensity(feature.properties.count),
      weight: 1,
      opacity: 1,
      color: "white",
      dashArray: "2",
      fillOpacity: 0.5,
      transition: "5s ease-in-out",
      className: "marker marker-fade-in",
    };
  };

  const filteredMarkers = data.filter((marker) => {
    const age = parseInt(marker.age);
    const isMale = marker.sex === "ชาย";
    const isFemale = marker.sex === "หญิง";

    if ((isMale && !showMale) || (isFemale && !showFemale)) return false;

    if (age >= 0 && age <= 1 && showBaby) return true;
    if (age > 1 && age <= 12 && showKid) return true;
    if (age >= 13 && age <= 20 && showTeens) return true;
    if (age >= 21 && age <= 40 && showAdult) return true;
    if (age >= 41 && age < 60 && showMenopause) return true;
    if (age >= 60 && showOld) return true;



    if (age >= 0 && age <= 1 && showBaby) return true;
    if (age > 1 && age <= 12 && showKid) return true;
    if (age >= 13 && age <= 20 && showTeens) return true;
    if (age >= 21 && age <= 40 && showAdult) return true;
    if (age >= 41 && age < 60 && showMenopause) return true;
    if (age >= 60 && showOld) return true;


    return false;
  });

  // useEffect(() => {
  //   if (map) {
  //     map.on("zoomend", () => {
  //       const currentZoom = map.getZoom();
  //       if (currentZoom < 12) {
  //         setShowGeoJSON(currentZoom < 12);
  //         setShowMarker(currentZoom >= 12);
  //         timer.current = setTimeout(() => {
  //           setShowGeoJSON2(true);
  //         }, 300);
  //       } else {
  //         setShowGeoJSON2(currentZoom < 12);
  //         setTimeout(() => {
  //           setShowGeoJSON(false);
  //           setShowMarker(true);
  //         }, 300);
  //       }
  //     });
  //   }
  //   return () => {
  //     clearTimeout(timer.current);
  //   };
  // }, [map]);
  useEffect(() => {
    if (map) {
      // สร้าง locate control
      const locateControl = L.control
        .locate({
          position: "topleft",
          flyTo: true,
          keepCurrentZoomLevel: false,
          initialZoomLevel: 20,
          drawCircle: false,
          showPopup: false,
        })
        .addTo(map);

      // ตั้งค่า event zoomend สำหรับการเปลี่ยนแปลงการซูม
      const handleZoomEnd = () => {
        const currentZoom = map.getZoom();
        if (currentZoom < 12) {
          setShowGeoJSON(true);
          setShowMarker(false);

          clearTimeout(timer.current); // เคลียร์ timer ก่อนการตั้งค่าใหม่
          timer.current = setTimeout(() => {
            setShowGeoJSON2(true);
          }, 300);
        } else {
          setShowGeoJSON2(false);

          clearTimeout(timer.current); // เคลียร์ timer ก่อนการตั้งค่าใหม่
          timer.current = setTimeout(() => {
            setShowGeoJSON(false);
            setShowMarker(true);
          }, 300);
        }
      };

      map.on("zoomend", handleZoomEnd);

      // Cleanup function เมื่อ component ถูก unmounted หรือ map มีการเปลี่ยนแปลง
      return () => {
        map.removeControl(locateControl);
        map.off("zoomend", handleZoomEnd);
        clearTimeout(timer.current); // ลบ timer ที่ยังค้างอยู่
      };
    }
  }, [map]);

  useEffect(() => {
    if (state.ampur) {
      searchaddress('ampur')
    }
  }, [state.ampur])

  return (
    <>
      <Grid container columns={12}>
        <Box className="box-info" sx={{ background: "#333333" }} width="100%">
          <Stack spacing={1} className="flex-center" width="100%">
            <Typography style={{ margin: 5 }}>แผนที่แสดงประชากรแบบมาตรฐาน</Typography>
          </Stack>
        </Box>
        <Grid item xs={12} className={showGeoJSON2 ? "n" : ""}>
          <MapContainer
            scrollWheelZoom={true}
            center={center}
            zoom={9}
            className="mapContainer"
            ref={setMap}
            attributionControl={false}
          // fadeAnimation={true}
          // markerZoomAnimation={true}
          // zoomAnimation={true}
          // zoomAnimationThreshold={100}
          // zoomSnap={0.25}
          // zoomDelta={0.25}
          >
            <TileLayer
              // ตรวจสอบสถานะ satelliteView
              maxZoom={22}
              minZoom={8}
              maxNativeZoom={19}
              url={"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              }
            />
            {showGeoJSON && (
              <GeoJSON
                data={subdistrictObj}
                style={(e) => ({
                  ...style(e),
                })}
                onEachFeature={onEachFeature}
              />
            )}

            {showMarker && !open && <MarkerJS filteredMarkers={filteredMarkers} />}
            {/* <MarkerJS filteredMarkers={filteredMarkers} /> */}

            <MapEventHandler />
            {markerPosition && (
              <Marker position={markerPosition} icon={defaultIcon}
                eventHandlers={{
                  click: () => { setOpen(true); searchaddress('') }, // ตั้งค่า event เมื่อคลิก Marker
                }} />
            )}
          </MapContainer>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography>
              ทารก
              <Checkbox checked={showBaby} onChange={(e) => setShowBaby(e.target.checked)} color="success" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              | เด็ก
              <Checkbox checked={showKid} onChange={(e) => setShowKid(e.target.checked)} color="success" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              | วัยรุ่น
              <Checkbox
                checked={showTeens}
                onChange={(e) => setShowTeens(e.target.checked)}
                color="success"
              />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              | ผู้ใหญ่
              <Checkbox
                checked={showAdult}
                onChange={(e) => setShowAdult(e.target.checked)}
                color="success"
              />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              | วัยทอง
              <Checkbox
                checked={showMenopause}
                onChange={(e) => setShowMenopause(e.target.checked)}
                color="success"
              />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              | ผู้สูงอายุ
              <Checkbox checked={showOld} onChange={(e) => setShowOld(e.target.checked)} color="success" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              | เพศชาย
              <Checkbox checked={showMale} onChange={(e) => setShowMale(e.target.checked)} color="success" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              | เพศหญิง
              <Checkbox
                checked={showFemale}
                onChange={(e) => setShowFemale(e.target.checked)}
                color="success"
              />
            </Typography>
          </Grid>
        </Grid>

        <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)} className="bg-dialog">
          <Box className='bg-gradiant'>
            {selectedOption === 'custom' && (
              <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={() => setOpen(false)}
                className="bg-dialog"
              >
                <Box className='bg-gradiant'>
                  <DialogActions className="flex-end">
                    <CloseIcn className="close-icn" onClick={() => setOpen(false)} />
                  </DialogActions>
                  <DialogTitle className="text-header">
                    เพิ่มประชากร
                  </DialogTitle>
                  <Grid item xs={12} className="flex-center">
                    <RadioGroup row value={selectedOption} onChange={handleOptionChange}>
                      <FormControlLabel value="custom" control={<Radio />} label="กำหนดเอง" />
                      <FormControlLabel value="other" control={<Radio />} label="อื่นๆ" />
                    </RadioGroup>
                  </Grid>
                  <DialogContent className="flex-center">
                    <form ref={formRef} className="flex-center">
                      <DialogContentText className="container-bg" component={'span'}>
                        <Grid>
                          {/* <Grid item xs={12} className="flex-center">
                                  <Grid item xs={12}>
                                  <TextField
                                  required
                                  fullWidth
                                  variant="outlined"
                                  value={`${latlng.lat}, ${latlng.lng}`}
                                  />
                                  </Grid>
                                  </Grid> */}
                          <Grid >
                            <DialogTitle style={{ color: 'black' }}>
                              ข้อมูลส่วนตัว
                            </DialogTitle>
                          </Grid>
                          <Grid container spacing={1} item xs={12} className="flex-center">
                            {/* {console.log('state', state)} */}
                            <Grid item xs={12} >
                              <TextField
                                required
                                fullWidth
                                label="ชื่อ - นามสกุล"
                                name="fullName"
                                placeholder="กรุณากรอกชื่อ-นามสกุล"
                                variant="outlined"
                                value={state['fullName']}
                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                              />
                            </Grid>
                            <Grid item xs={12} >
                              <TextField
                                required
                                fullWidth
                                label="เลขบัตรประชาชน"
                                name="idCard"
                                placeholder="กรุณากรอกเลขบัตรประชาชน"
                                variant="outlined"
                                value={state['idCard']}
                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                inputProps={{ maxLength: 13 }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(birthDate)}>
                                  <DatePicker
                                    value={birthDate}
                                    label="วัน/เดือน/ปี เกิด"
                                    onChange={(e) => setBirthDate(e)}
                                  />
                                </LocalizationProvider>
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth variant="outlined" required>
                                <InputLabel>เพศ</InputLabel>
                                <Select
                                  value={state.sex || ''}
                                  onChange={handleGenderChange}
                                  label="เพศ"
                                >
                                  {genderOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={6} >
                              <TextField
                                required
                                fullWidth
                                label="สัญชาติ"
                                name="nationDescription"
                                placeholder="กรุณากรอกสัญชาติ"
                                variant="outlined"
                                value={state['nationDescription']}
                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                              />
                            </Grid>
                          </Grid>
                          <Grid >
                            <DialogTitle style={{ color: 'black' }}>
                              ที่อยู่
                            </DialogTitle>
                          </Grid>
                          <Grid container spacing={1} item xs={12} className="flex-center">
                            <Grid item xs={12} sm={12} md={12}>
                              <Autocomplete
                                getOptionLabel={(option) => option.type}
                                options={home}
                                onChange={(event, value) => {
                                  setSelectedHome(value);
                                }}
                                value={selectedHome}
                                noOptionsText="No data"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="ถิ่นที่อยู่อาศัย"
                                    InputLabelProps={{ shrink: true }}
                                  />
                                )}
                                clearOnEscape
                                autoHighlight
                                disableClearable
                              />
                            </Grid>
                            {/* {console.log('state', state)} */}
                            <Grid item xs={6} >
                              <TextField
                                required
                                fullWidth
                                label="บ้านเลขที่"
                                name="house"
                                placeholder="กรุณากรอกบ้านเลขที่"
                                variant="outlined"
                                value={state['house']}
                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                              />
                            </Grid>
                            <Grid item xs={6} >
                              <TextField
                                required
                                fullWidth
                                label="หมู่ที่"
                                name="village"
                                placeholder="กรุณากรอกหมู่บ้าน"
                                variant="outlined"
                                value={state['village']}
                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                              />
                            </Grid>
                            <Grid item xs={6} >
                              <TextField
                                required
                                fullWidth
                                label="ซอย"
                                name="soimain"
                                placeholder="กรุณากรอกซอย"
                                variant="outlined"
                                value={state['soimain']}
                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                              />
                            </Grid>
                            <Grid item xs={6} >
                              <TextField
                                required
                                fullWidth
                                label="ถนน"
                                name="road"
                                placeholder="กรุณากรอกถนน"
                                variant="outlined"
                                value={state['road']}
                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                              />
                            </Grid>
                            <Grid item xs={12} >
                              <TextField
                                required
                                fullWidth
                                label="จังหวัด"
                                name="province"
                                placeholder="กรุณากรอกจังหวัด"
                                variant="outlined"
                                value={state['province']}
                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                              />
                            </Grid>
                            <Grid item xs={6} >
                              <FormControl fullWidth>
                                <InputLabel>อำเภอ</InputLabel>
                                <Select
                                  name="ampur"
                                  value={state.ampur || ''}
                                  onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                  label="อำเภอ"
                                >
                                  {ampur.map((ampur) => (
                                    <MenuItem key={ampur.amp_th} value={ampur.amp_code}>
                                      {ampur.amp_th}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={6} >
                              <FormControl fullWidth>
                                <InputLabel>ตำบล</InputLabel>
                                <Select
                                  name="tambon"
                                  value={state.tambon || ''}
                                  onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                  label="ตำบล"
                                >
                                  {tambon.map((tambon) => (
                                    <MenuItem key={tambon.tam_th} value={tambon.tam_code}>
                                      {tambon.tam_th}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Button
                          sx={{ textAlign: "left", marginTop: "2%" }}
                          variant="contained"
                          color="success"
                          onClick={handleSavecustom}
                        >
                          บันทึก
                        </Button>
                      </DialogContentText>
                    </form >
                  </DialogContent>
                  {/* <DialogActions className="flex-center">
                              <ButtonTeal
                                sx={{ width: { xs: "200px", sm: "50%", md: "30%" } }}
                                onClick={() => {
                                  checkValidation();
                                }}
                              >
                                แก้ไขข้อมูล
                              </ButtonTeal>
                            </DialogActions> */}
                </Box>
              </Dialog >
            )}

            {selectedOption === 'other' && (
              <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={() => setOpen(false)}
                className="bg-dialog"
              >
                <Box className='bg-gradiant'>
                  <DialogActions className="flex-end">
                    <CloseIcn className="close-icn" onClick={() => setOpen(false)} />
                  </DialogActions>
                  <DialogTitle className="text-header">
                    เพิ่มประชากร
                  </DialogTitle>
                  <Grid item xs={12} className="flex-center">
                    <RadioGroup row value={selectedOption} onChange={handleOptionChange}>
                      <FormControlLabel value="custom" control={<Radio />} label="กำหนดเอง" />
                      <FormControlLabel value="other" control={<Radio />} label="อื่นๆ" />
                    </RadioGroup>
                  </Grid>
                  <DialogContent className="flex-center">
                    <form ref={formRef} className="flex-center">
                      <DialogContentText mt={2} className="container-bg" component={'span'}>
                        <Grid>
                          {/* <Grid item xs={12} className="flex-center">
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          value={`${latlng.lat}, ${latlng.lng}`}
                        />
                      </Grid>
                    </Grid> */}
                          <Grid container spacing={1} item xs={12} className="flex-center">
                            {/* {console.log('state', state)} */}
                            <Grid item xs={10} >
                              <TextField
                                required
                                fullWidth
                                label="เลขบัตรประชาชน"
                                name="idCard"
                                placeholder="กรุณากรอกเลขบัตรประชาชน"
                                variant="outlined"
                                value={state['idCard']}
                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                inputProps={{ maxLength: 13 }}
                              />
                            </Grid>
                            <Grid item xs={2} >
                              <ButtonTeal sx={{ width: '100%', height: '100%' }} onClick={handleSearchins}>
                                <SearchIcon />
                              </ButtonTeal>
                            </Grid>
                            {message && <strong style={{ marginTop: '1%', color: message.includes("สำเร็จ") ? "green" : 'red' }}>*{message}</strong>}
                          </Grid>
                          <Grid item xs={12} className="flex-center">
                            <TableContainer sx={{ maxHeight: 800 }}>
                              <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                  <TableRow>
                                    <StyledTblK103 align="center">หัวข้อ</StyledTblK103>
                                    <StyledTblK103 align="center">รายละเอียดข้อมูล</StyledTblK103>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <>
                                    <TableRow>
                                      <StyledTableCell align="left">ชื่อ - นามสกุล</StyledTableCell>
                                      <StyledTableCell align="left">{datains.fullName ? datains.fullName : "N/A"}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                      <StyledTableCell align="left">หมายเลขบัตร</StyledTableCell>
                                      <StyledTableCell align="left">{datains.pid ? datains.pid : "N/A"}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                      <StyledTableCell align="left">วัน/เดือน/ปี เกิด</StyledTableCell>
                                      <StyledTableCell align="left">{datains.birthDate ? datains.birthDate : "N/A"}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                      <StyledTableCell align="left">เพศ</StyledTableCell>
                                      <StyledTableCell align="left">{datains.sex ? datains.sex : "N/A"}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                      <StyledTableCell align="left">อายุ</StyledTableCell>
                                      <StyledTableCell align="left">{datains.age ? datains.age : "N/A"}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                      <StyledTableCell align="left">สัญชาติ</StyledTableCell>
                                      <StyledTableCell align="left">{datains.nationDescription ? datains.nationDescription : "N/A"}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                      <StyledTableCell align="left">สิทธิการรักษา</StyledTableCell>
                                      <StyledTableCell align="left">{datains.mainInscl ? datains.mainInscl : "N/A"}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                      <StyledTableCell align="left">หน่วยบริการปฐมภูมิ</StyledTableCell>
                                      <StyledTableCell align="left">
                                        {datains.hospSub ? datains.hospSub : datains.hospNew ? datains.hospNew["hname"] : "N/A"}
                                      </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                      <StyledTableCell align="left">หน่วยบริการหลัก</StyledTableCell>
                                      <StyledTableCell align="left">
                                        {datains.hospMain ? datains.hospMain : datains.hospNew ? datains.hospNew["hname"] : "N/A"}
                                      </StyledTableCell>
                                    </TableRow>

                                    <TableRow>
                                      <StyledTableCell align="left">จังหวัด</StyledTableCell>
                                      <StyledTableCell align="left">{datains.provinceName ? datains.provinceName : "N/A"}</StyledTableCell>
                                    </TableRow>
                                  </>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                          <Grid >
                            <DialogTitle style={{ color: 'black' }}>
                              ที่อยู่
                            </DialogTitle>
                          </Grid>
                          <Grid container spacing={1} item xs={12} className="flex-center">
                            <Grid item xs={12} sm={12} md={12}>
                              <Autocomplete
                                getOptionLabel={(option) => option.type}
                                options={home}
                                onChange={(event, value) => {
                                  setSelectedHome(value);
                                }}
                                value={selectedHome}
                                noOptionsText="No data"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="ถิ่นที่อยู่อาศัย"
                                    InputLabelProps={{ shrink: true }}
                                  />
                                )}
                                clearOnEscape
                                autoHighlight
                                disableClearable
                              />
                            </Grid>
                            <Grid item xs={6} >
                              <TextField
                                required
                                fullWidth
                                label="บ้านเลขที่"
                                name="house"
                                placeholder="กรุณากรอกบ้านเลขที่"
                                variant="outlined"
                                value={state['house']}
                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                              />
                            </Grid>
                            <Grid item xs={6} >
                              <TextField
                                required
                                fullWidth
                                label="หมู่ที่"
                                name="village"
                                placeholder="กรุณากรอกหมู่บ้าน"
                                variant="outlined"
                                value={state['village']}
                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                              />
                            </Grid>
                            <Grid item xs={6} >
                              <TextField
                                required
                                fullWidth
                                label="ซอย"
                                name="soimain"
                                placeholder="กรุณากรอกซอย"
                                variant="outlined"
                                value={state['soimain']}
                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                              />
                            </Grid>
                            <Grid item xs={6} >
                              <TextField
                                required
                                fullWidth
                                label="ถนน"
                                name="road"
                                placeholder="กรุณากรอกถนน"
                                variant="outlined"
                                value={state['road']}
                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                              />
                            </Grid>
                            <Grid item xs={12} >
                              <TextField
                                required
                                fullWidth
                                label="จังหวัด"
                                name="province"
                                placeholder="กรุณากรอกจังหวัด"
                                variant="outlined"
                                value={state['province']}
                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                              />
                            </Grid>
                            <Grid item xs={6} >
                              <FormControl fullWidth>
                                <InputLabel>อำเภอ</InputLabel>
                                <Select
                                  name="ampur"
                                  value={state.ampur || ''}
                                  onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                  label="อำเภอ"
                                >
                                  {ampur.map((ampur) => (
                                    <MenuItem key={ampur.amp_th} value={ampur.amp_code}>
                                      {ampur.amp_th}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={6} >
                              <FormControl fullWidth>
                                <InputLabel>ตำบล</InputLabel>
                                <Select
                                  name="tambon"
                                  value={state.tambon || ''}
                                  onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                  label="ตำบล"
                                >
                                  {tambon.map((tambon) => (
                                    <MenuItem key={tambon.tam_th} value={tambon.tam_code}>
                                      {tambon.tam_th}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Button
                          sx={{ textAlign: "left", marginTop: "2%" }}
                          variant="contained"
                          color="success"
                          onClick={handleSaveother}
                        >
                          บันทึก
                        </Button>
                      </DialogContentText>
                    </form >
                  </DialogContent>
                  {/* <DialogActions className="flex-center">
                  <ButtonTeal
                    sx={{ width: { xs: "200px", sm: "50%", md: "30%" } }}
                    onClick={() => {
                      checkValidation();
                    }}
                  >
                    แก้ไขข้อมูล
                  </ButtonTeal>
                </DialogActions> */}
                </Box>
              </Dialog >
            )}
          </Box>
        </Dialog>
      </Grid>
    </>
  );
}

export default MapChart;
