import React from "react";
import { Button, Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { StyledTableCell } from "../../../Theme";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

export default function PersonTable({ header, data, handleChangePage, handleChangeRowsPerPage, rowsPerPage, page, lengthData }) {
    const sex = { 1: { label: "ชาย", value: "1" }, 2: { label: "หญิง", value: "2" } };
    const navigate = useNavigate();

    const handleRoute = (path, detail) => {
        return () => {
            navigate(path, { state: { detail } });
        };
    };

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((column) => (
                                <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => {
                            return (
                                <TableRow key={index + 1}>
                                    <StyledTableCell align="left">{row.NAME}</StyledTableCell>
                                    <StyledTableCell align="left">{row.LNAME}</StyledTableCell>
                                    <StyledTableCell align="center">{row.CID}</StyledTableCell>
                                    <StyledTableCell align="center">{dayjs(row.BIRTH).format("DD/MM/YYYY")}</StyledTableCell>
                                    <StyledTableCell align="right">{row.age}</StyledTableCell>
                                    <StyledTableCell align="right">{sex[row.SEX].label}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Button onClick={handleRoute("INSCL", row)}>ตรวจสอบ</Button>
                                    </StyledTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length === 0 ? (
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                </Grid>
            ) : (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    page={page}
                    count={lengthData}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            )}
        </Paper>
    );
}
