import { Container, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";

import { StyledTableCell, StyledTblK103 } from "../../../Theme";

export default function GridTable({ header, data, message }) {
    return (
        <Container maxWidth="md" sx={{ position: "relative" }}>
            {/* {Object.keys(data).length > 0 && */}
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTblK103 align="center">หัวข้อ</StyledTblK103>
                            <StyledTblK103 align="center">รายละเอียดข้อมูล</StyledTblK103>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            <TableRow>
                                <StyledTableCell align="left">ชื่อ - นามสกุล</StyledTableCell>
                                <StyledTableCell align="left">{data.fullName ? data.fullName : "N/A"}</StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left">หมายเลขบัตร</StyledTableCell>
                                <StyledTableCell align="left">{data.pid ? data.pid : "N/A"}</StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left">วัน/เดือน/ปี เกิด</StyledTableCell>
                                <StyledTableCell align="left">{data.birthDate ? data.birthDate : "N/A"}</StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left">เพศ</StyledTableCell>
                                <StyledTableCell align="left">{data.sex ? data.sex : "N/A"}</StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left">อายุ</StyledTableCell>
                                <StyledTableCell align="left">{data.age ? data.age : "N/A"}</StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left">สัญชาติ</StyledTableCell>
                                <StyledTableCell align="left">{data.nationDescription ? data.nationDescription : "N/A"}</StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left">สิทธิการรักษา</StyledTableCell>
                                <StyledTableCell align="left">{data.mainInscl ? data.mainInscl : "N/A"}</StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left">หน่วยบริการปฐมภูมิ</StyledTableCell>
                                <StyledTableCell align="left">
                                    {data.hospSub ? data.hospSub : data.hospNew ? data.hospNew["hname"] : "N/A"}
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left">หน่วยบริการหลัก</StyledTableCell>
                                <StyledTableCell align="left">
                                    {data.hospMain ? data.hospMain : data.hospNew ? data.hospNew["hname"] : "N/A"}
                                </StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell align="left">จังหวัด</StyledTableCell>
                                <StyledTableCell align="left">{data.provinceName ? data.provinceName : "N/A"}</StyledTableCell>
                            </TableRow>
                        </>
                    </TableBody>
                </Table>
            </TableContainer>
            {/* } */}
            {/* {(Object.keys(data).length === 0) &&
                <Grid justifyContent="center" my={3}>
                    <Box className="census-info-frame-tbl">
                        <Typography className="text-info-header" >{message ? message : '* ค้นหาข้อมูล *'}</Typography>
                    </Box>
                </Grid>
            } */}
        </Container>
    );
}
