import { useEffect, useState } from "react";
import DialogAdd from "./components/DialogAdd";
import HRCard from "./components/HRCard";
import { ADDHR, GET, GETDATAHR, POST, SELECTORHR } from "../../service";
import Swal from "sweetalert2";
import axios from "axios";

function HumanResources() {
    const [loading, setLoading] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [selectHR, setSelectHR] = useState();
    const [selectLevel, setSelectLevel] = useState();
    const [selectParent, setSelectParent] = useState();
    const [dataHR, setDataHR] = useState([]);
    const [name, setName] = useState("");
    const [position, setPosition] = useState("");
    const [level, setLevel] = useState();
    const [salary, setSalary] = useState();
    const [tel, setTel] = useState("");
    const [parent, setParent] = useState();
    const [address, setAddress] = useState("");
    const [fileUpload, setFileUpload] = useState(null);
    const token = localStorage.getItem("uuid");
    const pathapi = process.env.REACT_APP_SERVICE;

    const getDataHR = async () => {
        try {
            setLoading(true);
            let res = await POST(GETDATAHR);
            if (res.success) {
                setDataHR(res.result.treeDiagram);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const getSelectHR = async () => {
        try {
            setLoading(true);
            let res = await GET(SELECTORHR);
            if (res.success) {
                setSelectHR(res.result.position);
                setSelectLevel(res.result.level);
                setSelectParent(res.result.parent);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleAdd = async () => {
        setLoading(true); // ตั้งค่าให้เป็น true เมื่อเริ่มการอัปโหลด

        const formData = new FormData();
        formData.append("image_file_hr", fileUpload);
        formData.append("name", name);
        formData.append("position", position);
        formData.append("level", level);
        formData.append("salary", salary);
        formData.append("tel", tel);
        formData.append("address", address);
        if (parent) {
            formData.append("parent", parent);
        }
        try {
            const res = await axios.post(`${pathapi}/api/hr/insert_data_hr`, formData, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            console.log("res:", res);
            if (res && res.data.success) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: `เพิ่มข้อมูลสำเร็จ`,
                    confirmButtonText: "ตกลง",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: res?.data.message || "Unknown error occurred",
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || "Something went wrong.";
            Swal.fire({
                icon: "warning",
                text: errorMessage,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false); // เมื่อเสร็จสิ้นการทำงานทั้งในกรณี success หรือ error
        }
        // try {
        //     setLoading(true);
        //     let res = await POST(ADDHR, {
        //         name,
        //         position,
        //         level,
        //         salary,
        //         tel,
        //         parent,
        //         address,
        //     });
        //     if (res.success) {
        //         // window.localStorage.setItem("screen", JSON.stringify(res.result.screen_new));
        //         setLoading(false);
        //         Swal.fire({
        //             icon: "success",
        //             text: `เพิ่มข้อมูลสำเร็จ`,
        //             confirmButtonText: "ตกลง",
        //         }).then((result) => {
        //             if (result.isConfirmed) {
        //                 window.location.reload();
        //             }
        //         });
        //     } else {
        //         Swal.fire({
        //             icon: "warning",
        //             text: `${res.message}`,
        //             confirmButtonText: "ตกลง",
        //         });
        //     }
        // } catch (error) {
        //     Swal.fire({
        //         icon: "warning",
        //         text: `${error}`,
        //         confirmButtonText: "ตกลง",
        //     });
        // } finally {
        //     setLoading(false);
        // }
    };

    const handleClickOpenAdd = () => {
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
    };

    const handleFileChange = (e) => {
        setFileUpload(e.target.files[0]);
    };

    useEffect(() => {
        getSelectHR();
        getDataHR();
    }, []);

    return (
        <>
            <HRCard handleClickOpenAdd={handleClickOpenAdd} data={dataHR} />
            <DialogAdd
                handleClose={handleCloseAdd}
                handleAdd={handleAdd}
                handleFileChange={handleFileChange}
                open={openAdd}
                name={name}
                setName={setName}
                selectHR={selectHR}
                selectLevel={selectLevel}
                selectParent={selectParent}
                position={position}
                setPosition={setPosition}
                level={level}
                setLevel={setLevel}
                salary={salary}
                setSalary={setSalary}
                tel={tel}
                setTel={setTel}
                parent={parent}
                setParent={setParent}
                address={address}
                setAddress={setAddress}
                dataHR={dataHR}
                isRoot={dataHR.length > 0}
            />
        </>
    );
}

export default HumanResources;
