import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import React, { useRef } from "react";

import { ButtonTeal } from "../../../Theme";
import { CloseIcn } from "../../../untils/icons";

export default function DialogAddType({ open, handleClose, handleAdd, setType, type, name, setName, iconName, setIconName }) {
    const formRef = useRef();

    const checkValidation = () => {
        if (formRef.current.reportValidity()) {
            handleClose();
            handleAdd();
        }
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} className="bg-dialog">
            <Box className="bg-gradiant">
                <DialogActions className="flex-end">
                    <CloseIcn className="close-icn" onClick={handleClose} />
                </DialogActions>
                <DialogTitle className="text-header">เพิ่มประเภทของหน้า</DialogTitle>
                <DialogContent className="flex-center">
                    <form ref={formRef} className="flex-center">
                        <DialogContentText mt={2} className="container-bg" component={"span"}>
                            <Grid container columns={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        // required
                                        fullWidth
                                        label="เลขประเภทของหน้า"
                                        name="type_id"
                                        variant="outlined"
                                        value={type}
                                        onChange={(e) => setType(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        // required
                                        fullWidth
                                        label="ประเภทของหน้า"
                                        name="type"
                                        variant="outlined"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        // required
                                        fullWidth
                                        label="ไอค่อน"
                                        name="icon"
                                        variant="outlined"
                                        value={iconName}
                                        onChange={(e) => setIconName(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions className="flex-center">
                    <ButtonTeal
                        sx={{ width: { xs: "200px", sm: "50%", md: "30%" } }}
                        onClick={checkValidation}

                        // onClick={() => {
                        //     handleClose();
                        //     handleAdd();
                        // }}
                    >
                        เพิ่มข้อมูล
                    </ButtonTeal>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
