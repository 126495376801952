import { Box, Grid, Typography } from "@mui/material";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export default function BarChartHos({ information }) {
    const options = {
        indexAxis: "x",
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            datalabels: {
                display: function (context) {
                    return context.chart.isDatasetVisible(context.datasetIndex);
                },
                borderRadius: 4,
                align: "end",
                anchor: "end",
                color: "black",
                backgroundColor: "#BEBEBD",
            },
            title: {
                display: true,
                text: "",
            },
            legend: {
                display: false,
            },
        },
    };

    const labels = information.map((e) => e.hname);

    const data = {
        labels,
        datasets: [
            {
                label: "คน",
                data: information.map((e) => e.count),
                backgroundColor: "rgba(54, 162, 235, 0.5)",
                borderColor: "rgba(54, 162, 235)",
                // borderColor: 'rgb(61,90,254)',
                // backgroundColor: 'rgba(61,90,254, 0.5)',
            },
        ],
    };
    return (
        <Grid container columns={12}>
            <Grid item xs={12} className="flex-center">
                {information.length > 0 ? (
                    <Bar data={data} options={options} style={{ overflowX: "auto", overflowY: "auto", maxHeight: "500px", width: "100%" }} />
                ) : (
                    <Box className="census-info-empty">
                        <Typography>* ไม่มีข้อมูล *</Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}
