import React, { useEffect, useMemo, useState } from "react";
import { debounce } from "@mui/material";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { GET, GETAMPHUR, GETOPD, POST, SEARCHLOCATION } from "../../service";
import { getToken } from "../../untils/shortcut";
import GridCard from "./DashboardOPD/GridCard";

function DashboardOPD() {
    const [dateStart, setDateStart] = useState(dayjs().startOf("year").utc(true));
    const [dateEnd, setDateEnd] = useState(dayjs());
    const [dataLocation, setDataLocation] = useState([]);
    const [location, setLocation] = useState("");
    const [cleared, setCleared] = useState(false);
    const [searchLoca, setSearchLoca] = useState("");
    const [inputLoca, setInputLoca] = useState("");
    const [data, setData] = useState([]);
    const [dataOPD, setDataOPD] = useState([]);
    const [dataPerHosp, setDataPerHosp] = useState([]);
    const [dataPerMonthNotPass, setDataPerMonthNotPass] = useState([]);
    const [dataPerMonthAll, setDataPerMonthAll] = useState([]);
    const [dataSent, setDataSent] = useState([]);
    const [OPDClaimByHosp, setOPDClaimByHosp] = useState([]);
    const [auditPass, setAuditPass] = useState([]);
    const [auditNotPass, setAuditNotPass] = useState([]);
    const [dataAmphur, setDataAmphur] = useState([]);
    const [amphur, setAmphur] = useState("");
    const [amphurcode, setAmphurCode] = useState("");
    const [loading, setLoading] = useState(false);
    const profile = getToken(localStorage.getItem("profile"));

    // console.log(profile);

    const getOPD = async () => {
        try {
            setLoading(true);
            let res = await POST(GETOPD, { hcode: location, date_start: dateStart, date_end: dateEnd, amphurcode });
            if (res.success) {
                // console.log(res.result);
                setData(res.result);
                setDataOPD(res.result.namePreAudit);
                setDataPerHosp(res.result.graphPerHosp.all);
                setDataPerMonthNotPass(res.result.graphPerMonth.notPass);
                setDataPerMonthAll(res.result.graphPerMonth.allOPD);
                setDataSent(res.result.graphPerMonth.sent);
                setOPDClaimByHosp(res.result.tableOPDClaimofHosp);
                setAuditPass(res.result.pass);
                setAuditNotPass(res.result.notpass);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `ค้นหาไม่สำเร็จ`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const getAmphur = async () => {
        try {
            let res = await POST(GETAMPHUR, { amphurcode });
            if (res.success) {
                if (profile.role_id !== 1 && profile.role_id !== 2) {
                    setAmphur(res.result.amphur[0].ampurname);
                    setSearchLoca(res.result.hospital[0]);
                }
                setDataLocation(res.result.hospital);
                setDataAmphur(res.result.amphur);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `ค้นหาไม่สำเร็จ`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => {};
    }, [cleared]);

    useEffect(() => {
        getOPD();
        getAmphur();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateStart, dateEnd, location, amphurcode]);

    return (
        <GridCard
            data={data}
            dataOPD={dataOPD}
            dataPerHosp={dataPerHosp}
            dataPerMonthNotPass={dataPerMonthNotPass}
            dataPerMonthAll={dataPerMonthAll}
            dataSent={dataSent}
            OPDClaimByHosp={OPDClaimByHosp}
            profile={profile}
            dataLocation={dataLocation}
            searchLoca={searchLoca}
            setSearchLoca={setSearchLoca}
            setLocation={setLocation}
            setInputLoca={setInputLoca}
            dateStart={dateStart}
            dateEnd={dateEnd}
            setDateStart={setDateStart}
            setDateEnd={setDateEnd}
            setCleared={setCleared}
            dataAmphur={dataAmphur}
            setDataAmphur={setDataAmphur}
            amphur={amphur}
            setAmphur={setAmphur}
            setAmphurCode={setAmphurCode}
            auditPass={auditPass}
            auditNotPass={auditNotPass}
        />
    );
}

export default DashboardOPD;
