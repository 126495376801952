import React, { useEffect, useState } from "react";
import { GET, GETDASHBOARD } from "../../service";
import { Container, Box, Paper, Grid, Stack, Typography } from "@mui/material";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import Swal from "sweetalert2";
import { Dashboard } from "../../untils/icons";

function DashboardLocation() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const getDashboard = async () => {
        try {
            setLoading(true);
            let res = await GET(GETDASHBOARD);
            if (res.success) {
                setData(res.result);
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    useEffect(() => {
        getDashboard();
    }, []);

    return (
        <>
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <BackdropLoad loading={loading} />
                <Box className="flex-center">
                    <Paper className="paper-bg" elevation={0}>
                        <Grid container columns={12}>
                            <Grid item xs={8} sm={6} md={6} className="flex-start">
                                <Stack direction="row" spacing={2}>
                                    <Dashboard fontSize="medium" />
                                    <Typography className="main-header">Dashboard รพ. สต.</Typography>
                                </Stack>
                            </Grid>
                            {data?.url ? (
                                <Grid item xs={12} my={3} className="flex-center box-iframe">
                                    <iframe
                                        src={`${data.url}?:showAppBanner=false&:showVizHome=no&:embed=true&:origin=viz_share_link`}
                                        title="Dashboard รพ. สต."
                                    />
                                    {/* <iframe src="https://public.tableau.com/views/___16982774441010/Dashboard1?:showAppBanner=false&:showVizHome=no&:embed=true&:origin=viz_share_link" /> */}
                                </Grid>
                            ) : (
                                <Grid item xs={12} mt="20%" className="flex-center ">
                                    <Typography className="text-subtitle">*ไม่มีข้อมูลสรุป รพ. สต.*</Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Paper>
                </Box>
            </Container>
        </>
    );
}

export default DashboardLocation;
