import React, { useState, useEffect } from "react";
import { Box, Card, CardContent, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { POST, CHECKKIOSK, KIOSKSAVE, CHECKAUTH, GETKIOSK, GET } from "../../../service";
import Swal from "sweetalert2";
import BackdropLoad from "../../../components/Backdrop/BackdropLoad.js";
import { ButtonTealSubmit, BtnLoading } from "../../../Theme.jsx";

function Kiosk() {
    const [token, setToken] = useState("");
    const [auth_token, setAuth_token] = useState("");
    const [loading, setLoading] = useState(false);

    const [msgKiosk, setMsgKiosk] = useState("");
    const [msgAuth, setMsgAuth] = useState("");
    const [statusKiosk, setStatusKiosk] = useState("");
    const [statusAuth, setStatusAuth] = useState("");
    const [loadingBtnToken, setLoadingBtnToken] = useState(false);
    const [loadingBtnAuth, setLoadingBtnAuth] = useState(false);

    const getDataKiosk = async () => {
        try {
            setLoading(true);
            let res = await GET(GETKIOSK);
            if (res.success) {
                if (res.result.length > 0) {
                    setToken(res.result[0].kiosk_token);
                    setAuth_token(res.result[0].auth_token);
                    setLoading(false);
                } else {
                    setToken("");
                    setAuth_token("");
                    setLoading(false);
                }
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const checkTokenKiosk = async () => {
        setMsgKiosk("");
        setStatusKiosk("");
        try {
            // setLoading(true);
            setLoadingBtnToken(true);
            let res = await POST(CHECKKIOSK, { token });
            if (res.success) {
                setMsgKiosk(res.result.message);
                setStatusKiosk(res.result.status);

                // setLoading(false);
                setLoadingBtnToken(false);
            } else {
                // setLoading(false);
                setLoadingBtnToken(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            // setLoading(false);
            setLoadingBtnToken(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const checkTokenAuthen = async () => {
        setMsgAuth("");
        setStatusAuth("");
        try {
            // setLoading(true);
            setLoadingBtnAuth(true);
            let res = await POST(CHECKAUTH, { token: auth_token });
            // let res = await POST(CHECKAUTH, { token : auth_token });
            if (res.success) {
                setMsgAuth(res.result.message);
                setStatusAuth(res.result.status);
                // setLoading(false);
                setLoadingBtnAuth(false);
            } else {
                // setLoading(false);
                setLoadingBtnAuth(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            // setLoading(false);
            setLoadingBtnAuth(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            let res = await POST(KIOSKSAVE, { kiosk_token: token, auth_token });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: `บันทึกข้อมูลเรียบร้อย`,
                    confirmButtonText: "ตกลง",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    useEffect(() => {
        getDataKiosk();
    }, []);

    return (
        <>
            {/* <Container maxWidth="xl" sx={{ position: "relative" }}> */}
            <BackdropLoad loading={loading} />
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={3}>
                        <Grid item xs={12} sm={12} md={6} my={3} className="flex-center">
                            <Card className="box-card-vaccine">
                                <CardContent className="box-card-vaccine">
                                    <Stack spacing={2} width="100%">
                                        <Typography className="main-header">Kiosk Token</Typography>
                                        <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                                            <Grid item my={2} xs={12} sm={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Kiosk Token"
                                                    name="Token"
                                                    type="text"
                                                    placeholder="Kiosk Token"
                                                    value={token}
                                                    variant="outlined"
                                                    onChange={(e) => setToken(e.currentTarget.value)}
                                                />
                                            </Grid>
                                        </Stack>
                                        <Grid container columns={12} className="flex-start">
                                            <Grid item my={2} xs={4} sm={2} md={2.5}>
                                                <BtnLoading onClick={checkTokenKiosk} fullWidth loading={loadingBtnToken} disabled={loadingBtnAuth}>
                                                    <span>
                                                        ตรวจสอบ
                                                        <br />
                                                        รหัสใช้งาน
                                                    </span>
                                                </BtnLoading>
                                            </Grid>
                                            {msgKiosk && (
                                                <Grid item my={2} xs={1} sm={1} md={1} className="flex-center">
                                                    <Typography>:</Typography>
                                                </Grid>
                                            )}
                                            <Grid item my={2} xs={7} sm={9} md={8.5}>
                                                <Typography color={statusKiosk === true ? "green" : "red"}>{msgKiosk}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} my={3} className="flex-center">
                            <Card className="box-card-vaccine">
                                <CardContent className="box-card-vaccine">
                                    <Stack spacing={2} width="100%">
                                        <Typography className="main-header">Authentication Token</Typography>
                                        <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                                            <Grid item my={2} xs={12} sm={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Authentication Token"
                                                    name="email"
                                                    type="text"
                                                    placeholder="Authentication Token"
                                                    value={auth_token}
                                                    variant="outlined"
                                                    onChange={(e) => setAuth_token(e.currentTarget.value)}
                                                />
                                            </Grid>
                                        </Stack>
                                        <Grid container columns={12} className="flex-start">
                                            <Grid item my={2} xs={4} sm={2} md={2.5}>
                                                <BtnLoading onClick={checkTokenAuthen} fullWidth loading={loadingBtnAuth} disabled={loadingBtnToken}>
                                                    <span>
                                                        ตรวจสอบ
                                                        <br />
                                                        รหัสใช้งาน
                                                    </span>
                                                </BtnLoading>
                                            </Grid>
                                            {msgAuth && (
                                                <Grid item my={2} xs={1} sm={1} md={1} className="flex-center">
                                                    <Typography>:</Typography>
                                                </Grid>
                                            )}
                                            <Grid item my={2} xs={7} sm={9} md={8.5}>
                                                <Typography color={statusAuth === true ? "green" : "red"}>{msgAuth}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item my={2} xs={12} className="flex-end">
                            <ButtonTealSubmit onClick={handleSubmit} disabled={loadingBtnToken || loadingBtnAuth}>
                                บันทึกข้อมูล
                            </ButtonTealSubmit>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            {/* </Container > */}
        </>
    );
}

export default Kiosk;
