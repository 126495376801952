import { Grid } from "@mui/material";
import { CategoryScale, Chart as ChartJS, Filler, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from "chart.js";
import React from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

export default function LineChartStatement({ information }) {
     const options = {
          responsive: true,
          interaction: {
               mode: "index",
               intersect: false,
          },
          stacked: false,
          plugins: {
               datalabels: false,
               title: {
                    display: true,
                    // text: 'Chart.js Line Chart - Multi Axis',
               },
          },
          scales: {
               y: {
                    type: "linear",
                    display: true,
                    position: "left",
               },
               // y1: {
               //     type: 'linear',
               //     display: true,
               //     position: 'right',
               //     grid: {
               //         drawOnChartArea: false,
               //     },
               // },
          },
     };

     const labels = ["ต.ค.", "พ.ย.", "ธ.ค.", "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย."];

     const data = {
          labels,
          datasets: [
               {
                    label: information.text,
                    data: information.data,
                    borderColor: "rgb(0, 140, 238)",
                    backgroundColor: "rgba(0, 140, 238, 0.5)",
               },
               {
                    label: information.text2,
                    data: information.data2,
                    borderColor: "rgb(255, 99, 132)",
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
               },
          ],
     };
     return (
          // <Grid container columns={12} >
          //     <Grid item xs={12} >
          //         <Box className="census-info">
          //             <Typography>
          //                 จำนวนการเข้ารับบริการแยกตามสิทธิการรักษา (ครั้ง)
          //             </Typography>
          //         </Box>
          //     </Grid>
          //     <Grid item xs={12} style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '400px', width: '100%' }}>
          //         <Line data={data} options={options} />
          //     </Grid>
          // </Grid >
          <Grid container columns={12}>
               {/* <Grid item xs={12} >
                <Box className="census-info">
                    <Typography>
                        จำนวนเงินโอนเข้าประจำเดือน
                    </Typography>
                </Box>
            </Grid> */}
               <Grid item xs={12} className="census-info-chart-h">
                    {/* {information.length > 0 ?
                    // <Bar data={data} options={options} />
                    <Bar data={data} options={options} />
                    :
                    <Box className="census-info-empty">
                        <Typography>
                            * ไม่มีข้อมูล *
                        </Typography>
                    </Box>
                } */}
                    <Line data={data} options={options} />
               </Grid>
          </Grid>
     );
}
