import React, { useEffect, useState } from "react";

import { Autocomplete, Box, CircularProgress, Container, FormControl, Grid, Paper, Stack, TextField, Typography } from "@mui/material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { DASHBOARDPAYMENT, POST } from "../../service";
import { Dashboard } from "../../untils/icons";
import { fixAmount, getToken } from "../../untils/shortcut";
import BarChartVertical from "./DashboardPayment/BarChartVertical";
import LeafletMapComponent from "./DashboardPayment/LeafletMapComponent";
import LineChartPayment from "./DashboardPayment/LineChartPayment";

function DashboardPayment() {
    let checkmonth = dayjs().month()
    const [dateStart, setDateStart] = useState(checkmonth < 9 ? dayjs().month(9).subtract(1, "year").startOf("month") : dayjs().month(9).startOf("month"));
    const [dateEnd, setDateEnd] = useState(checkmonth < 9 ? dayjs().month(8).endOf('month') : dayjs().endOf('month'));
    const [cleared, setCleared] = useState(false);
    const [subdistrictObj, setSubdistrictObj] = useState([]);
    const [ampur, setAmpur] = useState([]);
    const [ampurcode, setAmpurCode] = useState('');
    const [hospital, setHospital] = useState([]);
    const [hospitalid, setHospitalId] = useState('')
    const [graph, setGraph] = useState([]);
    const [loading, setLoading] = useState(false);
    const profile = getToken(localStorage.getItem("profile"));

    const getDashboardPayment = async () => {
        setLoading(true)
        try {
            let res = await POST(DASHBOARDPAYMENT, { date_start: dateStart, date_end: dateEnd, hospitalid, ampurcode });
            if (res.success) {
                setAmpur(res.result.amphur)
                setHospital(res.result.hname.filter(e => ampurcode ? e.ampurcode === ampurcode : e))
                setSubdistrictObj(res.result.dataReturn.features);
                setGraph(res.result.graph)
                setLoading(false)
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `ค้นหาไม่สำเร็จ`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    // const getDataChart = async () => {
    //     try {
    //         let res = await POST(DASHBOARDPAYMENT, {});
    //         if (res.success) {
    //             setData(res.result);
    //         } else {
    //             Swal.fire({
    //                 icon: 'warning',
    //                 text: `ค้นหาไม่สำเร็จ`,
    //                 confirmButtonText: 'ตกลง',
    //             })
    //         }
    //     } catch (error) {
    //         Swal.fire({
    //             icon: 'warning',
    //             text: `${error}`,
    //             confirmButtonText: 'ตกลง',
    //         })
    //     }
    // }

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => { };
    }, [cleared]);

    useEffect(() => {
        getDashboardPayment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateStart, dateEnd, hospitalid, ampurcode]);

    // useEffect(() => {
    //     setLoading(true)
    //     setTimeout(() => {
    //         setLoading(false)
    //     }, 500);
    // }, [subdistrictObj])
    // useEffect(() => {
    //     getDataChart();
    // }, [dateStart, dateEnd, selectTambon]);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={3}>
                        <Grid item xs={8} sm={6} md={6} className="flex-start">
                            <Stack direction="row" spacing={2}>
                                <Dashboard fontSize="medium" />
                                <Typography className="main-header">Dashboard การเงิน</Typography>
                            </Stack>
                        </Grid>

                        {/* Select Data */}
                        <Grid item xs={12}>
                            <Grid container columns={12} spacing={3} className="flex-start">
                                {profile.role_id < 3 ?
                                    <>
                                        <Grid item xs={12} md={3}>
                                            <Autocomplete
                                                fullWidth
                                                getOptionLabel={(option) => (typeof option === "string" ? option : `${option.ampurname}`)}
                                                options={ampur}
                                                autoComplete
                                                includeInputInList
                                                filterSelectedOptions
                                                value={ampur.find(e => e.ampurcode === ampurcode) || null}
                                                isOptionEqualToValue={(option, value) => option.ampurcode === value.ampurcode}
                                                noOptionsText="ไม่พบอำเภอ"
                                                onChange={(event, value) => {
                                                    if (!value) {
                                                        setHospitalId('');
                                                        setAmpurCode('');
                                                    } else {
                                                        setAmpurCode(value.ampurcode);
                                                        setHospitalId('');
                                                    }
                                                }}
                                                renderInput={(params) => <TextField {...params} label="อำเภอ" />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <Autocomplete
                                                fullWidth
                                                getOptionLabel={(option) => (typeof option === "string" ? option : `${option.hcode} ${option.hname}`)}
                                                options={hospital}
                                                autoComplete
                                                includeInputInList
                                                filterSelectedOptions
                                                value={hospital.find(e => e.hcode === hospitalid) || null}
                                                isOptionEqualToValue={(option, value) => option.hcode === value.hcode}
                                                noOptionsText="No locations"
                                                onChange={(event, newValue) => {
                                                    if (!newValue) {
                                                        setHospitalId('');
                                                    } else {
                                                        setHospitalId(newValue.hcode);
                                                    }
                                                }}
                                                renderInput={(params) => <TextField {...params} label="รพ.สต." />}
                                            />
                                        </Grid>
                                    </>
                                    : null
                                }
                                <Grid item xs={6} md={profile.role_id < 3 ? 2 : 6}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateStart)}>
                                            <DatePicker
                                                value={dateStart}
                                                slotProps={{
                                                    field: { clearable: true, onClear: () => setCleared(true) },
                                                }}
                                                label="วันที่เริ่มต้น"
                                                onChange={(e) => setDateStart(e)}
                                                views={["year", "month"]}
                                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={profile.role_id < 3 ? 2 : 6}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateEnd)}>
                                            <DatePicker
                                                // fullWidth
                                                value={dateEnd}
                                                slotProps={{
                                                    field: { clearable: true, onClear: () => setCleared(true) },
                                                }}
                                                label="วันที่สิ้นสุด"
                                                onChange={(e) => setDateEnd(e)}
                                                views={["year", "month"]}
                                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {color_level.map((el, ind) => (
                                    <Grid item key={ind + 1} xs={4} md='auto'>
                                        <Stack direction="row" spacing={1} className="flex-start">
                                            <Box className="box-level" sx={{ backgroundColor: el.color }} />
                                            <Typography>
                                                {el.label !== 0 && `>`} {fixAmount(el.label)}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>

                        {/* MAP */}
                        <Grid item xs={12} >
                            {!loading ? (
                                <LeafletMapComponent subdistrictObj={subdistrictObj} /*setSelectTambon={setSelectTambon}*/ />
                            ) : (
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <CircularProgress />
                                </Box>
                            )}
                        </Grid>

                        {/* Chart */}
                        <Grid item xs={12}>
                            <LineChartPayment information={graph} />
                        </Grid>
                        <Grid item xs={12}>
                            <BarChartVertical information={graph} />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
}

const color_level = [
    { label: 5000000, color: "#1a237e" },
    { label: 999999, color: "#0d47a1" },
    { label: 99999, color: "#8bc34a" },
    { label: 9999, color: "#b71c1c" },
    { label: 1, color: "#f44336" },
    { label: 0, color: "#000" },
];

export default DashboardPayment;
