import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BackdropLoad from "../../../components/Backdrop/BackdropLoad";
import { POST } from "../../../service";
import { Statement } from "../../../untils/icons";
import { headerTable } from "../../../untils/static.js";
import GridTableDetails from "./GridTableDetails.js";

function StatementDetails() {
     const params = useParams();
     /* console.log('พารามมมม:' ,JSON.parse(params.detail).file_name); */
     let detail = JSON.parse(params.detail);
     const [data, setData] = useState([]);
     const [loading, setLoading] = useState(false);

     const GetStatement = async () => {
          try {
               setLoading(true);
               let res = await POST("/claim/get_statement_log", { file_name: detail.file_name, year: detail.year });
               if (res.success) {
                    setData(res.result);
                    setLoading(false);
               } else {
                    setLoading(false);
                    Swal.fire({
                         icon: "warning",
                         text: `${res.message}`,
                         confirmButtonText: "ตกลง",
                    });
               }
          } catch (error) {
               setLoading(false);
               Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
          }
     };

     useEffect(() => {
          GetStatement();
     }, []);

     return (
          <Box display="flex" justifyContent="center">
               <BackdropLoad loading={loading} />
               <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12}>
                         <Grid item xs={12} className="flex-start">
                              <Stack direction="row" spacing={2}>
                                   <Statement fontSize="medium" />
                                   <Typography className="main-header">รายละเอียด {detail.file_name}</Typography>
                              </Stack>
                         </Grid>
                         <Grid item xs={12} className="flex-start">
                              <GridTableDetails data={data} header={headerTable.statement_detail_header} />
                         </Grid>
                    </Grid>
               </Paper>
          </Box>
     );
}

export default StatementDetails;
