import React, { useState } from "react";
import { Typography, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Grid } from "@mui/material";

import { StyledTableCell } from "../../../../Theme";
import dayjs from "dayjs";
export default function GridTableDetail({ header, data, type }) {
     const [page, setPage] = useState(0);
     const [rowsPerPage, setRowsPerPage] = useState(10);

     const handleChangePage = (event, newPage) => {
          setPage(newPage);
     };

     const handleChangeRowsPerPage = (event) => {
          setRowsPerPage(+event.target.value);
          setPage(0);
     };

     return (
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
               <TableContainer sx={{ maxHeight: 800 }}>
                    <Table stickyHeader aria-label="sticky table">
                         <TableHead>
                              <TableRow>
                                   {header.map((column, i) => (
                                        <StyledTableCell key={i + 1} align={column.align} style={{ minWidth: column.minWidth }}>
                                             <Typography fontWeight="bold" fontSize="14px">
                                                  {column.label}
                                             </Typography>
                                        </StyledTableCell>
                                   ))}
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                   if (type === "adp") {
                                        return (
                                             <TableRow key={i + 1}>
                                                  {/* <StyledTableCell align="center">{row.DIAG}</StyledTableCell>
                                                  <StyledTableCell align="center">{row.OPER}</StyledTableCell>
                                                  <StyledTableCell align="left">{row.odx_name ? row.odx_name : "N/A"}</StyledTableCell>
                                                  <StyledTableCell align="left">{row.LABTEST ? row.LABTEST : "N/A"}</StyledTableCell>
                                                  <StyledTableCell align="center">{row.LABRESULT ? row.LABRESULT : "N/A"}</StyledTableCell>
                                                  <StyledTableCell align="right">{row.SERVPRICE ? tofieds(row.SERVPRICE) : "0.00"}</StyledTableCell>
                                                  <StyledTableCell align="left">{row.ins_name ? row.ins_name : "N/A"}</StyledTableCell> */}
                                                  <StyledTableCell align="center">{row.type === "ADP" ? 'ค่าบริการ' : row.type === "OOP" ? 'ค่าหัตถการ' : 'ค่ายา'}</StyledTableCell>
                                                  <StyledTableCell align="center">{dayjs(row.date).format("DD/MM/YYYY")}</StyledTableCell>
                                                  <StyledTableCell align="center">{row.CODE}</StyledTableCell>
                                                  <StyledTableCell align="center">{row.adp_type_name}</StyledTableCell>
                                                  <StyledTableCell align="right">{row.QTY}</StyledTableCell>
                                                  <StyledTableCell align="right">{row.cost}</StyledTableCell>
                                                  <StyledTableCell align="center">{row.dt_update ? dayjs(row.dt_update).format("DD/MM/YYYY HH:mm") : "N/A"}</StyledTableCell>
                                                  {/* <StyledTableCell align="left">
                                                            {row.pre_audit ? (
                                                                 <Chip label={row.pre_audit} color="error" />
                                                            ) : (
                                                                 <Chip label="Pass" color="success" />
                                                            )}
                                                       </StyledTableCell>
                                                       <StyledTableCell align="center">
                                                            <Chip
                                                                 label={
                                                                      row.status_claim === 0 || row.status_claim === null
                                                                           ? "รอส่งเคลม"
                                                                           : row.status_claim === 1
                                                                           ? "ส่งเคลมสำเร็จ"
                                                                           : "ส่งไม่ผ่าน"
                                                                 }
                                                                 color={
                                                                      row.status_claim === 0 || row.status_claim === null
                                                                           ? "default"
                                                                           : row.status_claim === 1
                                                                           ? "success"
                                                                           : "error"
                                                                 }
                                                            />
                                                       </StyledTableCell> */}
                                             </TableRow>
                                        );
                                   } else {
                                        return (
                                             <TableRow key={i + 1}>
                                                  <StyledTableCell align="center">{dayjs(row.date).format("DD/MM/YYYY")}</StyledTableCell>
                                                  <StyledTableCell align="center">{row.diag}</StyledTableCell>
                                                  <StyledTableCell align="center">{row.dxtype}</StyledTableCell>
                                                  <StyledTableCell align="center">{row.drdx}</StyledTableCell>
                                                  <StyledTableCell align="center">{row.dt_update ? dayjs(row.dt_update).format("DD/MM/YYYY HH:mm") : "N/A"}</StyledTableCell>
                                             </TableRow>
                                        );
                                   }
                              })}
                         </TableBody>
                    </Table>
               </TableContainer>
               {data.length === 0 ? (
                    <Grid container justifyContent="center" my={3}>
                         <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                    </Grid>
               ) : (
                    <TablePagination
                         rowsPerPageOptions={[10, 25, 100]}
                         component="div"
                         count={data.length}
                         rowsPerPage={rowsPerPage}
                         page={page}
                         onPageChange={handleChangePage}
                         onRowsPerPageChange={handleChangeRowsPerPage}
                         labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                    />
               )}
          </Paper>
     );
}
