import React from "react";
import { Grid, Box, Paper, Typography, Stack } from "@mui/material";

import { AddItemIcon } from "../../../untils/icons";
import { ButtonTeal } from "../../../Theme";
import GridTableList from "./GridTableList";

function TypeCard({
    header,
    data,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    handleClickOpenAdd,
    handleClickOpenEdit,
    handleDelete,
}) {
    // console.log("data:", data);
    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={8} sm={6} md={6} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <Typography className="main-header">จัดการ type</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} sm={6} md={6} className="flex-end">
                        <Stack direction="row" spacing={1}>
                            <ButtonTeal onClick={handleClickOpenAdd}>
                                <AddItemIcon fontSize="small" />
                                &nbsp;
                                <Typography sx={{ display: { xs: "none", sm: "block" }, cursor: "pointer" }}>เพิ่ม type</Typography>
                            </ButtonTeal>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <GridTableList
                            header={header}
                            data={data}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleClickOpenEdit={handleClickOpenEdit}
                            handleDelete={handleDelete}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default TypeCard;
