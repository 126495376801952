import { Grid } from "@mui/material";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React from "react";
import { Bar } from "react-chartjs-2";
import { tofieds } from "../../../untils/shortcut";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export default function ChartCaseOPD({ information }) {
    // console.log("information", information);

    const labels = information.map((e) => e.month);

    const options = {
        indexAxis: "x",
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,

        plugins: {
            interaction: {
                intersect: false,
                mode: "index",
            },
            datalabels: {
                display: function (context) {
                    return context.chart.isDatasetVisible(context.datasetIndex);
                },
                formatter: function (value) {
                    return `${value > 0 ? tofieds(value) : value}`;
                },
                borderRadius: 4,
                align: "end",
                anchor: "end",
                color: "black",
                // backgroundColor: "#BEBEBD",
            },
            tooltip: {
                mode: "index",
                intersect: false,
                callbacks: {
                    label: function (context) {
                        var label = context.dataset.label || "";
                        var value = context.formattedValue;
                        var value_case = context.dataset.data_case[context.dataIndex];
                        // console.log(context);
                        return `${label} : ${value > 0 ? tofieds(value) : value} % , จำนวน : ${value_case}`;
                        // return `${label} จำนวน : ${value_case}`;
                    },
                },
            },
        },
        legend: {
            display: false,
        },
        scales: {
            y: {
                min: 0,
                max: 100,
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: "ไม่ผ่าน",
                data: information.map((e) => e.percent),
                data_case: information.map((e) => e.count),
                borderColor: "rgb(23, 144, 255)",
                backgroundColor: "rgba(23, 144, 255, 0.5)",
            },
            // {
            //     label: "ไม่ผ่าน",
            //     data: [15, 25, 35, 45, 55, 45, 35, 25, 15, 10, 20, 30],
            //     data_case: [150, 250, 350, 450, 550, 450, 350, 250, 150, 100, 200, 300],
            //     borderColor: "rgb(14, 32, 142)",
            //     backgroundColor: "rgba(14, 32, 142, 0.5)",
            // },
        ],
    };
    return (
        <Grid container columns={12}>
            <Grid item xs={12} className="census-info-chart-h">
                {/* {information.length > 0 ?
                    <Bar data={data} options={options} />
                    :
                    <Box >
                        <Typography>
                            * ไม่มีข้อมูล *
                        </Typography>
                    </Box>
                } */}
                <Bar data={data} options={options} />
            </Grid>
        </Grid>
    );
}
