import {
    Box,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import React, { useRef } from "react";

import { ButtonTeal } from "../../../Theme";
import { CloseIcn } from "../../../untils/icons";

export default function DialogAdd({ open, handleClose, handleAdd, data, setScreenName, screenName, setType, type, setIcon, icon }) {
    const formRef = useRef();

    const checkValidation = () => {
        if (formRef.current.reportValidity()) {
            handleClose();
            handleAdd();
        }
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} className="bg-dialog">
            <Box className="bg-gradiant">
                <DialogActions className="flex-end">
                    <CloseIcn className="close-icn" onClick={handleClose} />
                </DialogActions>
                <DialogTitle className="text-header">เพิ่มข้อมูล type</DialogTitle>
                <DialogContent className="flex-center">
                    <form ref={formRef} className="flex-center">
                        <DialogContentText mt={2} className="container-bg" component={"span"}>
                            <Grid container columns={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="ชื่อหน้า"
                                        name="screen_name"
                                        variant="outlined"
                                        value={screenName}
                                        onChange={(e) => setScreenName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="ประเภทของหน้า"
                                        name="type"
                                        variant="outlined"
                                        value={type}
                                        onChange={(e) => setType(e.target.value)}
                                    ></TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        // required
                                        fullWidth
                                        label="ชื่อไอค่อน"
                                        name="icon"
                                        variant="outlined"
                                        value={icon}
                                        onChange={(e) => setIcon(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions className="flex-center">
                    <ButtonTeal sx={{ width: { xs: "200px", sm: "50%", md: "30%" } }} onClick={checkValidation}>
                        เพิ่มข้อมูล
                    </ButtonTeal>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
