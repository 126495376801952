import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import { ADD_MANAGEURL, ADDTYPE_MANAGEURL, GET, GETTYPE, MANAGEURL, POST, UPDATE_MANAGEURL } from "../../service";
import { headerTable } from "../../untils/static";
import DialogAdd from "./component/DialogAdd";
import DialogEdit from "./component/DialogEdit";
import UrlCard from "./component/UrlCard";
import DialogAddType from "./component/DialogAddType";

function ManageUrl() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [urlId, setUrlId] = useState("");
    const [name, setName] = useState("");
    const [path, setPath] = useState("");
    const [type, setType] = useState("");
    const [forSelect, setForSelect] = useState();
    const [iconName, setIconName] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openAddType, setOpenAddType] = useState(false);
    const [urlMasterId, setUrlMasterId] = useState("");
    const [checked, setChecked] = useState(false);

    const getUrlMaster = async () => {
        try {
            setLoading(true);
            let res = await GET(MANAGEURL);
            if (res.success) {
                setData(res.result);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    // const getDataUrlById = async (id) => {
    //     try {
    //         setLoading(true);
    //         let res = await GET(MANAGEURL, { screen_id: id });
    //         if (res.success) {
    //             setUrlId(res.result.screen_id)
    //             setName(res.result.screen_name)
    //             setPath(res.result.screen_path)
    //             setType(res.result.type)
    //             // setUrlMasterId(res.result.screen_master_id)
    //             setLoading(false)
    //         } else {
    //             setLoading(false);
    //             Swal.fire({
    //                 icon: "warning",
    //                 text: `${res.message}`,
    //                 confirmButtonText: "ตกลง",
    //             });
    //         }
    //     } catch (error) {
    //         setLoading(false);
    //         Swal.fire({
    //             icon: "warning",
    //             text: `${error}`,
    //             confirmButtonText: "ตกลง",
    //         });
    //     }
    // };

    const getType = async () => {
        try {
            setLoading(true);
            let res = await GET(GETTYPE);
            if (res.success) {
                //  console.log("type :", res.result);
                setForSelect(res.result);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleAdd = async () => {
        try {
            setLoading(true);
            let res = await POST(ADD_MANAGEURL, {
                screen_master_id: urlMasterId,
                screen_name: name,
                screen_path: path,
                type: type,
                icon: iconName,
                main: checked,
            });
            if (res.success) {
                window.localStorage.setItem("screen", JSON.stringify(res.result.screen_new));
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: `เพิ่มข้อมูลสำเร็จ`,
                    confirmButtonText: "ตกลง",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleUpdate = async () => {
        try {
            setLoading(true);
            let res = await POST(UPDATE_MANAGEURL, {
                screen_id: urlId,
                screen_name: name,
                screen_path: path,
                type,
                icon: iconName,
                main: checked,
            });
            if (res.success) {
                window.localStorage.setItem("screen", JSON.stringify(res.result.screen_new));
                // console.log("res.result.screen_new:", res.result.screen_new);
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: `แก้ไขข้อมูลสำเร็จ`,
                    confirmButtonText: "ตกลง",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleAddType = async () => {
        try {
            setLoading(true);
            let res = await POST(ADDTYPE_MANAGEURL, {
                screen_name: name,
                type,
                icon: iconName,
            });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: `เพิ่มข้อมูลสำเร็จ`,
                    confirmButtonText: "ตกลง",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClickOpenAdd = () => {
        setName("");
        setPath("");
        setType("");
        setUrlMasterId("");
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setName("");
        setPath("");
        setType("");
        setUrlMasterId("");
        setOpenAdd(false);
    };

    const handleClickOpenEdit = (data) => {
        console.log("data:", data);
        // getDataUrlById(id);
        setUrlId(data.screen_id);
        setName(data.screen_name);
        setPath(data.screen_path);
        setType(data.type);
        setIconName(data.icon);
        setChecked(data.main);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleCheck = (event) => {
        setChecked(event.target.checked);
    };

    const handleClickOpenAddType = () => {
        setName("");
        setType("");
        setIconName("");
        setOpenAddType(true);
    };

    const handleCloseAddType = () => {
        setName("");
        setType("");
        setIconName("");
        setOpenAddType(false);
    };

    useEffect(() => {
        getUrlMaster();
        getType();
    }, []);

    return (
        <>
            <BackdropLoad loading={loading} />
            <UrlCard
                header={headerTable.manage_url}
                data={data}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleClickOpenAdd={handleClickOpenAdd}
                handleClickOpenEdit={handleClickOpenEdit}
                handleClickOpenAddType={handleClickOpenAddType}
            />
            <DialogAdd
                open={openAdd}
                handleClose={handleCloseAdd}
                handleAdd={handleAdd}
                name={name}
                setName={setName}
                data={data}
                path={path}
                setPath={setPath}
                type={type}
                setType={setType}
                handleCheck={handleCheck}
                checked={checked}
                setIconName={setIconName}
                iconName={iconName}
                forSelect={forSelect}
                setForSelect={setForSelect}
            />
            <DialogEdit
                open={openEdit}
                handleClose={handleCloseEdit}
                handleUpdate={handleUpdate}
                name={name}
                setName={setName}
                data={data}
                path={path}
                setPath={setPath}
                type={type}
                setType={setType}
                urlId={urlId}
                handleCheck={handleCheck}
                checked={checked}
                setIconName={setIconName}
                iconName={iconName}
                forSelect={forSelect}
                setForSelect={setForSelect}
            />
            <DialogAddType
                open={openAddType}
                handleClose={handleCloseAddType}
                handleAdd={handleAddType}
                name={name}
                setName={setName}
                type={type}
                setType={setType}
                checked={checked}
                handleCheck={handleCheck}
                iconName={iconName}
                setIconName={setIconName}
            />
        </>
    );
}
export default ManageUrl;
