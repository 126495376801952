import { Airplay, ChildFriendly, Dataset, DirectionsWalk, Home, InsertDriveFile, Redo } from "@mui/icons-material";
import WcIcon from "@mui/icons-material/Wc";
import { Box, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import {
    ItemDashboard2,
    ItemDashboard3,
    ItemDashboard4,
    ItemDashboard5,
    ItemDashboard6,
    ItemDashboard7,
    ItemDashboard8,
    ItemDashboard9,
} from "../../../Theme";
import { Dashboard } from "../../../untils/icons";
import BarChartErrorData from "./BarChartErrorData";
import DataTable from "./DataTable";
import ErrorDataCard from "./ErrorDataCard";
import LineChartErrorData from "./LineChartErrorData";
import PieChartErrorData from "./PieChartErrorData";
import QualityDataCard from "./QualityDataCard";
import TableErrorData from "./TableErrorData";

function GridTable({ 
    subdistrictObj, 
    setSelectTambon,
 }) {
    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={5}>
                        <Grid item xs={12} className="flex-start">
                            <Stack direction="row" spacing={2}>
                                <Dashboard fontSize="medium" />
                                <Typography className="main-header">Health Data Analytics (HDA)</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} className="flex-start">
                            <Stack width="100%" direction={{ xs: "column", sm: "column", md: "row" }} spacing={3}>
                                <ItemDashboard2 elevation={3}>
                                    <Grid container columns={12}>
                                        <Grid item xs={6}>
                                            <Stack direction="row" className="flex-start" spacing={3}>
                                                <WcIcon transform="scale(2)" style={{ color: "white" }} />
                                                <Grid>
                                                    <Typography className="font-dashboard-white" style={{ color: "white" }}>
                                                        PERSON
                                                    </Typography>
                                                    <Typography className="font-dashboard-white">4.85%</Typography>
                                                    {/* <Typography className="font-dashboard">{fixAmount(dataValueService.countPersonWoman ? dataValueService.countPersonWoman : 0)}</Typography> */}
                                                </Grid>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </ItemDashboard2>
                                <ItemDashboard3 elevation={3}>
                                    <Stack direction="row" className="flex-start" spacing={{ xs: 3, sm: 5 }}>
                                        <Redo transform="scale(2)" style={{ color: "white" }} />
                                        <Grid>
                                            <Typography className="font-dashboard-white">ANC</Typography>
                                            <Typography className="font-dashboard-white">14.64%</Typography>
                                            {/* <Typography className="font-dashboard">{fixAmount(dataValueService.countService ? dataValueService.countService : 0)}</Typography> */}
                                        </Grid>
                                    </Stack>
                                </ItemDashboard3>
                                <ItemDashboard4 elevation={3}>
                                    <Stack direction="row" className="flex-start" spacing={{ xs: 3, sm: 5 }}>
                                        <InsertDriveFile transform="scale(2)" style={{ color: "white" }} />
                                        <Grid>
                                            <Typography className="font-dashboard-white">LABFU</Typography>
                                            <Typography className="font-dashboard-white">1.33%</Typography>
                                            {/* <Typography className="font-dashboard">{fixAmount(dataValueService.countPerson ? dataValueService.countPerson : 0)}</Typography> */}
                                        </Grid>
                                    </Stack>
                                </ItemDashboard4>
                                <ItemDashboard5 elevation={3}>
                                    <Stack direction="row" className="flex-start" spacing={{ xs: 3, sm: 5 }}>
                                        <DirectionsWalk transform="scale(2)" style={{ color: "white" }} />
                                        <Grid>
                                            <Typography className="font-dashboard-white">EPI</Typography>
                                            <Typography className="font-dashboard-white">27.21%</Typography>
                                            {/* <Typography className="font-dashboard">{fixAmount(dataValueService.countPerson ? dataValueService.countPerson : 0)}</Typography> */}
                                        </Grid>
                                    </Stack>
                                </ItemDashboard5>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} className="flex-start">
                            <Stack width="100%" direction={{ xs: "column", sm: "column", md: "row" }} spacing={3}>
                                <ItemDashboard6 elevation={3}>
                                    <Grid container columns={12}>
                                        <Grid item xs={6}>
                                            <Stack direction="row" className="flex-start" spacing={3}>
                                                <ChildFriendly transform="scale(2)" style={{ color: "white" }} />
                                                <Grid>
                                                    <Typography className="font-dashboard-white">NEWBORN</Typography>
                                                    <Typography className="font-dashboard-white">13.24%</Typography>
                                                    {/* <Typography className="font-dashboard">{fixAmount(dataValueService.countPersonWoman ? dataValueService.countPersonWoman : 0)}</Typography> */}
                                                </Grid>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </ItemDashboard6>
                                <ItemDashboard7 elevation={3}>
                                    <Stack direction="row" className="flex-start" spacing={{ xs: 3, sm: 5 }}>
                                        <Airplay transform="scale(2)" style={{ color: "white" }} />
                                        <Grid>
                                            <Typography className="font-dashboard-white">LABOR</Typography>
                                            <Typography className="font-dashboard-white">17.50%</Typography>
                                            {/* <Typography className="font-dashboard">{fixAmount(dataValueService.countService ? dataValueService.countService : 0)}</Typography> */}
                                        </Grid>
                                    </Stack>
                                </ItemDashboard7>
                                <ItemDashboard8 elevation={3}>
                                    <Stack direction="row" className="flex-start" spacing={{ xs: 3, sm: 5 }}>
                                        <Dataset transform="scale(2)" style={{ color: "white" }} />
                                        <Grid>
                                            <Typography className="font-dashboard-white">CHRONIC</Typography>
                                            <Typography className="font-dashboard-white">13.08%</Typography>
                                            {/* <Typography className="font-dashboard">{fixAmount(dataValueService.countPerson ? dataValueService.countPerson : 0)}</Typography> */}
                                        </Grid>
                                    </Stack>
                                </ItemDashboard8>
                                <ItemDashboard9 elevation={3}>
                                    <Stack direction="row" className="flex-start" spacing={{ xs: 3, sm: 5 }}>
                                        <Home transform="scale(2)" style={{ color: "white" }} />
                                        <Grid>
                                            <Typography className="font-dashboard-white">DIAGNOSIS_OPD</Typography>
                                            <Typography className="font-dashboard-white">2.93%</Typography>
                                            {/* <Typography className="font-dashboard">{fixAmount(dataValueService.countPerson ? dataValueService.countPerson : 0)}</Typography> */}
                                        </Grid>
                                    </Stack>
                                </ItemDashboard9>
                            </Stack>
                        </Grid>
                        {/* Table */}
                        <Grid item xs={12}>
                            <Grid container row={12} spacing={3}>
                                <Grid item xs={12} sm={12} md={3}>
                                    <Box className="census-info-frame-header">
                                        <Typography className="text-info-header">ความผิดปกติของข้อมูล (อำเภอ)</Typography>
                                    </Box>
                                    <Box className="census-info-frame">
                                        <DataTable />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Stack direction="column" mb={1}>
                                        <Box className="census-info-frame-header">
                                            <Typography className="text-info-header">ความผิดปกติของข้อมูล (43 แฟ้ม)</Typography>
                                        </Box>
                                        <Box className="census-info-frame">
                                            <ErrorDataCard />
                                        </Box>
                                    </Stack>
                                    <Stack>
                                        <Box className="census-info-frame-header">
                                            <Typography className="text-info-header">คุณภาพของข้อมูล (43 แฟ้ม)</Typography>
                                        </Box>
                                        <Box className="census-info-frame">
                                            <QualityDataCard />
                                        </Box>
                                    </Stack>
                                </Grid>

                                <Grid item xs={12} sm={12} md={5}>
                                    <Box className="census-info-frame-header">
                                        <Typography className="text-info-header">GIS DATA จ.มุกดาหาร</Typography>
                                    </Box>
                                    <Box className="census-info-frame">
                                        {/* <LeafletMapComponent subdistrictObj={subdistrictObj} setSelectTambon={setSelectTambon} /> */}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Chart */}
                        <Grid item xs={12}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">ข้อมูลที่ผิดปกติรายอำเภอ</Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <BarChartErrorData />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">ข้อมูลที่ผิดปกติภาพรวมอำเภอ</Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <PieChartErrorData />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">ข้อมูลที่ผิดปกติรายแฟ้ม</Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <LineChartErrorData />
                            </Box>
                        </Grid>
                        {/* Table */}
                        <Grid item xs={12}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">%ข้อมูลรายแฟ้มที่ผิดปกติ</Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <TableErrorData />
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
}

export default GridTable;
