import React from "react";
import { Button, Chip, Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";

import { StyledTableCell } from "../../../Theme";
import { MANAGEURL_TYPE } from "../../../untils/static";

export default function GridTableList({ header, data, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, handleClickOpenEdit }) {
    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((column) => (
                                <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            return (
                                <TableRow key={index + 1}>
                                    <StyledTableCell align="left">{row.screen_name}</StyledTableCell>
                                    <StyledTableCell align="left">{row.screen_path}</StyledTableCell>
                                    <StyledTableCell align="center">{row.type_screen_name}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Button variant="text" onClick={() => handleClickOpenEdit(row)}>
                                            แก้ไข
                                        </Button>
                                    </StyledTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length === 0 ? (
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                </Grid>
            ) : (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    // count={count}
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            )}
        </Paper>
    );
}
