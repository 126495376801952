import {
    Box,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import React, { useRef } from "react";

import { ButtonTeal } from "../../../Theme";
import { CloseIcn } from "../../../untils/icons";

export default function DialogEdit({
    open,
    handleClose,
    handleUpdate,
    urlId,
    name,
    path,
    type,
    setName,
    setPath,
    setType,
    data,
    handleCheck,
    checked,
    setIconName,
    iconName,
    forSelect,
    setForSelect,
}) {
    const formRef = useRef();

    const checkValidation = (id) => {
        if (formRef.current.reportValidity()) {
            handleClose();
            handleUpdate(id);
        }
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} className="bg-dialog">
            <Box className="bg-gradiant">
                <DialogActions className="flex-end">
                    <CloseIcn className="close-icn" onClick={handleClose} />
                </DialogActions>
                <DialogTitle className="text-header">แก้ไขข้อมูล</DialogTitle>
                <DialogContent className="flex-center">
                    <form ref={formRef} className="flex-center">
                        <DialogContentText mt={2} className="container-bg" component={"span"}>
                            <Grid container columns={12} spacing={2}>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox checked={checked} onChange={handleCheck} inputProps={{ "aria-label": "controlled" }} />}
                                        label="Main Url"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="ชื่อหน้า"
                                        name="name"
                                        variant="outlined"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="url"
                                        name="path"
                                        variant="outlined"
                                        value={path}
                                        onChange={(e) => setPath(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>ประเภทของหน้า</InputLabel>
                                        <Select
                                            name="type"
                                            value={type}
                                            label="เลือกประเภทของหน้า"
                                            required
                                            onChange={(e) => setType(e.target.value)}
                                        >
                                            {Array.isArray(forSelect) &&
                                                forSelect.map((item, index) => (
                                                    <MenuItem key={index} value={item.type}>
                                                        {item.screen_name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        // required
                                        fullWidth
                                        label="ชื่อไอค่อน"
                                        name="icon"
                                        variant="outlined"
                                        value={iconName}
                                        onChange={(e) => setIconName(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>

                <DialogActions className="flex-center">
                    <ButtonTeal
                        sx={{ width: { xs: "200px", sm: "50%", md: "30%" } }}
                        onClick={() => {
                            checkValidation(urlId);
                        }}
                    >
                        แก้ไขข้อมูล
                    </ButtonTeal>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
