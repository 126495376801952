import { Container } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import { GETSTATEMENT, POST } from "../../service";
import StatementList from "./DashboardStatement/StatementList";

function DashboardStatement() {
    let checkmonth = dayjs().month()

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState(checkmonth < 9 ? dayjs().year() : dayjs().add(1, 'year').year());

    const GetStatement = async () => {
        try {
            setLoading(true);
            let res = await POST(GETSTATEMENT, { year });
            if (res.success) {
                setData(res.result);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    const updateStatement = async () => {
        try {
            setLoading(true);
            let res = await POST("/claim/statement", { year, type: "ALL" });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: res.result,
                    confirmButtonText: "ตกลง",
                }).then((result) => {
                    if (result.isConfirmed) {
                        // window.location.reload();
                        GetStatement();
                    }
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        } finally {
            setLoading(false);
        }
    };
    const year_length = new Date().getFullYear() - 2024;

    const yearsData = Array.from({ length: year_length + 2 }, (_, index) => {
        return { value: 2024 + index };
    });

    useEffect(() => {
        GetStatement();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year]);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <StatementList data={data} yearsData={yearsData} year={year} setYear={setYear} updateStatement={updateStatement} />
        </Container>
    );
}
// const data = [
//     {},
// ]
export default DashboardStatement;
