import React, { useState } from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Button,
    Stack,
    Chip,
    Grid,
    Typography,
} from "@mui/material";

import { StyledTableCell } from "../../../Theme";
import { deviceStatus } from '../../../untils/static';
import { DeleteIcon } from '../../../untils/icons';

export default function GridTableList({ header, data, handleUpdateDevice, deleteAlert }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow key={index + 1}>
                                        <StyledTableCell align="center">{index + 1}</StyledTableCell>
                                        <StyledTableCell align="left">{row.name} {row.lastname}</StyledTableCell>
                                        <StyledTableCell align="left">{row.location_name}</StyledTableCell>
                                        <StyledTableCell align="left">{row.phone}</StyledTableCell>
                                        <StyledTableCell align="left">{row.device_mac_address}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Chip sx={{ width: '70px', background: `${deviceStatus[row.status_device !== null ? row.status_device : 3].color}`, color: "var(--white)" }} label={deviceStatus[row.status_device !== null ? row.status_device : 3].label} />
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Stack direction="row" spacing={2} className="flex-center">
                                                {
                                                    row.status_device === 0 ?
                                                        <>
                                                            <Button variant="outlined" color="success" onClick={() => { handleUpdateDevice(row.id_device, row.user_id, 1) }}>
                                                                อนุมัติ
                                                            </Button>
                                                            <Button variant="outlined" color="error" onClick={() => { handleUpdateDevice(row.id_device, row.user_id, 2) }}>
                                                                ปฏิเสธ
                                                            </Button>
                                                        </>
                                                        :

                                                        <Button variant="contained" disabled>
                                                            ดำเนินการเรียบร้อย
                                                        </Button>

                                                }
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <DeleteIcon sx={{ cursor: "pointer" }} onClick={() => deleteAlert(row.device_mac_address)} />
                                        </StyledTableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length === 0 ?
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap >
                        * ไม่มีข้อมูลในตาราง *
                    </Typography>
                </Grid>
                :
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            }
        </Paper>
    );
}

