import React, { useEffect, useRef } from "react";
import Tree from "react-d3-tree";
import person from "../../../assets/images/choose-image.png";
import "../../../css/custom-tree.css";

export default function HRFamilyTree({ dataTree }) {
    const initialTranslate = { x: 700, y: 100 };
    const pathapi = process.env.REACT_APP_SERVICE;
    // console.log("pathapi:", pathapi);

    const straightPathFunc = (linkDatum, orientation) => {
        const { source, target } = linkDatum;
        return orientation === "horizontal" ? `M${source.y},${source.y}L${target.y},${target.x}` : `M${source.x},${source.y}L${target.x},${target.y}`;
    };

    if (dataTree && Array.isArray(dataTree) && dataTree.length > 0) {
        return (
            <div
                id="treeWrapper"
                style={{
                    display: "flex",
                    maxWidth: "100vw",
                    maxHeight: "100vh",
                    height: "90vh",
                    overflow: "hidden",
                }}
            >
                <Tree
                    data={dataTree}
                    rootNodeClassName="node__root"
                    branchNodeClassName="node__branch"
                    leafNodeClassName="node__leaf"
                    orientation="vertical"
                    nodeSize={{ x: 300, y: 200 }}
                    translate={initialTranslate}
                    scaleExtent={{ max: 5, min: 0.1 }}
                    // pathFunc={straightPathFunc}
                    renderCustomNodeElement={({ nodeDatum }) => {
                        // console.log("nodeDatum:", nodeDatum);
                        return (
                            <foreignObject width="150" height="150" x="-75" y="-75">
                                <div
                                    style={{
                                        marginBottom: "5px",
                                        fontSize: "10px",
                                        fontWeight: "bold",
                                        padding: "2px",
                                        backgroundColor: "#f0f0f0",
                                        border: "2px solid #000",
                                        borderRadius: "5px",
                                        textAlign: "center",
                                    }}
                                >
                                    {nodeDatum.name}
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <img
                                        src={`${pathapi}${nodeDatum.imgpath}`}
                                        alt="person"
                                        style={{
                                            width: "100px",
                                            height: "150px",
                                            objectFit: "fill",
                                        }}
                                    />
                                </div>
                            </foreignObject>
                        );
                    }}
                />
            </div>
        );
    }
}
