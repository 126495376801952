import React, { useState } from "react";

import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import BackdropLoad from "../../../components/Backdrop/BackdropLoad.js";
import { headerTable } from "../../../untils/static";
import GridDetail from "./components/GridDetail";
function DetailClaim13() {
    const params = useParams();
    const [data, setData] = useState({
        data_adp: [],
        data_odx: [],
    });
    const [loading, setLoading] = useState(false);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <GridDetail header={headerTable.claim13_detail} headerOdx={headerTable.headerOdx} data={data} detail={JSON.parse(params.detail)} />
        </Container>
    );
}

export default DetailClaim13;
