import { create } from "zustand";

export const useRoute = create((set) => ({
    type: null,
    setType: (type) => set({ type }),

    menu: [],
    setMenu: (menu) => set({ menu }),

    system: null,
    setSystem: (system) => set({ system }),
}));
