import { useEffect, useState } from "react";
import GridCard from "./DashboardMapChronic/GridCard";
import { CHRONIC, POST } from "../../service";
import Swal from "sweetalert2";
import { getToken } from "../../untils/shortcut";
import dayjs from "dayjs";

function DashboardMapChronic() {
    const [loading, setLoading] = useState(false);
    const profile = getToken(localStorage.getItem("profile"));
    const [hcode, setHcode] = useState("");
    const [data, setData] = useState([]);
    const [dataChronic, setDataChronic] = useState([]);
    const [proLocationName, setProLocationName] = useState("ทั้งหมด");
    const [selectLocation, setSelectLocation] = useState("");
    const [dataLocation, setDataLocation] = useState([]);
    const [dateStart, setDateStart] = useState(dayjs(new Date()).startOf("year").utc(true));
    const [dateEnd, setDateEnd] = useState(dayjs(new Date()));
    const [subdistrictObj, setSubdistrictObj] = useState([]);
    const [group, setGroup] = useState([{ id: "", type: "ทั้งหมด" }]);
    const [idgroup, setIdGroup] = useState("");
    const [selectgroupname, setSelectGroupname] = useState(group[0]);
    const GetMapDashboard = async () => {
        try {
            setLoading(true);
            const res = await POST(CHRONIC, { hcode, dateStart, dateEnd, id: idgroup });
            if (res.success) {
                if (profile.role_id !== 1 && profile.role_id !== 2) {
                    setProLocationName(profile.location_name);
                    setHcode(profile.location_code);
                    setSubdistrictObj(res.result.countTambon.features);
                    setData(res.result.data);
                    setDataChronic(res.result.data_chonic)
                    const apiData = res.result.data_type || [];
                    setGroup([{ id: "", type: "ทั้งหมด" }, ...apiData]);
                } else {
                    setData(res.result.data);
                    setDataLocation(res.result.hname);
                    setSubdistrictObj(res.result.countTambon.features);
                    setDataChronic(res.result.data_chonic)
                    const apiData = res.result.data_type || [];
                    setGroup([{ id: "", type: "ทั้งหมด" }, ...apiData]);
                }
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        GetMapDashboard();
    }, [hcode, dateStart, dateEnd, idgroup]);

    return (
        <GridCard
            profile={profile}
            data={data}
            proLocationName={proLocationName}
            dataLocation={dataLocation}
            setProLocationName={setProLocationName}
            dateEnd={dateEnd}
            dateStart={dateStart}
            setDateStart={(e) => setDateStart(e)}
            setDateEnd={(e) => setDateEnd(e)}
            dataChronic={dataChronic}
            hcode={hcode}
            setHcode={(e) => setHcode(e)}
            subdistrictObj={subdistrictObj}
            loading={loading}
            group={group}
            setGroup={(e) => setGroup(e)}
            setIdGroup={(e) => setIdGroup(e)}
            selectGroupName={selectgroupname}
            setSelectedGroup={(e) => setSelectGroupname(e)}
        />
    );
}

export default DashboardMapChronic;