import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { Box, Grid, Typography } from "@mui/material";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function BarChartDashboard({ death }) {
    const options = {
        indexAxis: "y",
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            datalabels: false,
            legend: {
                display: false,
            },
            tooltip: {
                intersect: false,
                callbacks: {
                    label: function (context) {
                        var label = context.dataset.label || "";
                        var value = context.formattedValue;
                        return `${label} : ${value} `;
                    },
                },
            },
        },
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                ticks: {
                    callback: function (value) {
                        const label = this.getLabelForValue(value);
                        return label.length > 20 ? label.substr(0, 20).trim() + "..." : label;
                    },
                },
            },
        },
    };

    const labels = death.map((e) => e.cdeath_a_th);

    const data = {
        labels,
        datasets: [
            {
                label: "จำนวนครั้ง",
                data: death.map((e) => e.death),
                borderColor: "rgb(54, 162, 235)",
                backgroundColor: "rgba(54, 162, 235, 0.5)",
                barPercentage: 1,
            },
        ],
    };
    return (
        <Grid container columns={12}>
            <Grid item xs={12} className="flex-center" style={{ overflowX: "auto", overflowY: "auto", maxHeight: "400px", width: "100px" }}>
                {death.length > 0 ? (
                    <Bar data={data} options={options} />
                ) : (
                    <Box className="census-info-empty">
                        <Typography>* ไม่มีข้อมูล *</Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}
