import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import { useRef, useState } from "react";
import { CloseIcn } from "../../../untils/icons";
import { ButtonTeal } from "../../../Theme";

export default function DialogEdit({ open, handleClose, handleUpdate, data, setEdit }) {
    const formRef = useRef();

    const checkValidation = (id) => {
        if (formRef.current.reportValidity()) {
            handleClose();
            handleUpdate(id);
        }
    };

    console.log(data);

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} className="bg-dialog">
            {data && (
                <Box className="bg-gradiant">
                    <DialogActions className="flex-end">
                        <CloseIcn className="close-icn" onClick={handleClose} />
                    </DialogActions>
                    <DialogTitle className="text-header">แก้ไขข้อมูล</DialogTitle>
                    <DialogContent className="flex-center">
                        <form ref={formRef} className="flex-center">
                            <DialogContentText mt={2} className="container-bg" component={"span"}>
                                <Grid container columns={12} spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            label="อ้างอิงเอกสาร"
                                            name="refDoc"
                                            variant="outlined"
                                            value={data.ref_doc}
                                            onChange={(e) => setEdit({ ...data, ref_doc: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            label="เลขบัญชี"
                                            name="accountNo"
                                            variant="outlined"
                                            value={data.account_no}
                                            onChange={(e) => setEdit({ ...data, account_no: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            label="ชื่อบัญชี"
                                            name="accountName"
                                            variant="outlined"
                                            value={data.account_name}
                                            onChange={(e) => setEdit({ ...data, account_name: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="เดบิต"
                                            name="debit"
                                            variant="outlined"
                                            value={data.debit}
                                            onChange={(e) => setEdit({ ...data, debit: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="เครดิต"
                                            name="credit"
                                            variant="outlined"
                                            value={data.credit}
                                            onChange={(e) => setEdit({ ...data, credit: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            label="คำอธิบายรายการ"
                                            name="detail"
                                            variant="outlined"
                                            value={data.detail}
                                            onChange={(e) => setEdit({ ...data, detail: e.target.value })}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </form>
                    </DialogContent>
                    <DialogActions className="flex-center">
                        <ButtonTeal
                            sx={{ width: { xs: "200px", sm: "50%", md: "30%" } }}
                            onClick={() => {
                                checkValidation(data.id);
                            }}
                        >
                            แก้ไขข้อมูล
                        </ButtonTeal>
                    </DialogActions>
                </Box>
            )}
        </Dialog>
    );
}
