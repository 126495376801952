import React, { useState } from "react";
import {
    Typography,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StyledTableCell } from "../../../Theme.jsx";

export default function GridTable({ header, data, handleChangeCheckbox, checkAll, status }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function formatDate(dateString) {
        if (!dateString) return "N/A";  // ตรวจสอบว่า dateString มีค่า

        let year, month, day;

        // ถ้าวันที่มีรูปแบบ YYYYMMDD
        if (dateString.length === 8) {
            year = dateString.substring(0, 4);
            month = dateString.substring(4, 6);
            day = dateString.substring(6, 8);
        }
        // ถ้าวันที่มีรูปแบบ YYYY-MM-DD
        else if (dateString.includes('-') && dateString.split('-').length === 3) {
            [year, month, day] = dateString.split('-');
        }
        // ถ้าวันที่มีรูปแบบ DD/MM/YYYY
        else if (dateString.includes('/') && dateString.split('/').length === 3) {
            [day, month, year] = dateString.split('/');
        } else {
            return "N/A"; // ถ้าไม่ตรงกับรูปแบบที่คาดหวัง
        }

        // ตรวจสอบว่าเดือนมีค่าที่ถูกต้อง
        const monthNamesThai = [
            "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน",
            "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
        ];

        const monthName = monthNamesThai[parseInt(month, 10) - 1];

        if (!monthName) return "N/A";  // ถ้าเดือนไม่ถูกต้อง

        return `${day} ${monthName} ${year}`;
    }

    const handleRowClick = (row) => {
        setSelectedRow(row);
    };

    const handleCloseDialog = () => {
        setSelectedRow(null);
    };

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((column, i) => (
                                <StyledTableCell key={i + 1} align={column.align} style={{ minWidth: column.minWidth }}>
                                    <Typography fontWeight="bold" fontSize="14px">
                                        {column.label}
                                    </Typography>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                            return (
                                <TableRow key={i + 1} onClick={() => handleRowClick(row)} sx={{
                                    '&:hover': {
                                        backgroundColor: '#f5f5f5',
                                        cursor: "pointer"
                                    }
                                }
                                } >
                                    <StyledTableCell align="left">
                                        {row.PID ? row.PID : "N/A"}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.title ? row.title : "N/A"}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.th_name ? row.th_name : "N/A"}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {formatDate(row.DATE_DIAG)}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {row.data_array ? row.data_array.length : ""}
                                    </StyledTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                data.length === 0 ? (
                    <Grid container justifyContent="center" my={3}>
                        <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                    </Grid>
                ) : (
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                    />
                )
            }

            <Dialog open={!!selectedRow} onClose={handleCloseDialog}>
                <DialogTitle>ข้อมูลการเข้ารับการตรวจ</DialogTitle>
                <DialogContent sx={{ width: '500px' }}>
                    {selectedRow?.data_array?.length ? (
                        selectedRow.data_array.map((item, index) => (
                            <Accordion key={index} sx={{ marginBottom: 1 }}>
                                <AccordionSummary sx={{
                                    '&:hover': {
                                        backgroundColor: '#f5f5f5'
                                    },
                                }} expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="subtitle1">การตรวจครั้งที่ {index + 1}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>รหัสสถานพยาบาล: {item.HOSPCODE}</Typography>
                                    <Typography>รหัสผู้ป่วย: {item.PID}</Typography>
                                    <Typography>ลำดับข้อมูล: {item.SEQ}</Typography>
                                    {console.log('item.DATE_SERV:', item.DATE_SERV)}
                                    <Typography>วันที่ให้บริการ: {formatDate(item.DATE_SERV)}</Typography>
                                    <Typography>น้ำหนัก: {item.WEIGHT}</Typography>
                                    <Typography>ส่วนสูง: {item.HEIGHT}</Typography>
                                    <Typography>รอบเอว: {item.WAIST_CM}</Typography>
                                    <Typography>ความดันโลหิตตัวบน: {item.SBP}</Typography>
                                    <Typography>ความดันโลหิตตัวล่าง: {item.DBP}</Typography>
                                    <Typography>การตรวจเท้า: {item.FOOT}</Typography>
                                    <Typography>การตรวจจอประสาทตา: {item.RETINA}</Typography>
                                    <Typography>ผู้ให้บริการ: {item.PROVIDER}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    ) : (
                        <Typography>ไม่มีข้อมูลการเข้าตรวจ</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>ปิด</Button>
                </DialogActions>
            </Dialog>
        </Paper >
    );
}