import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import {
     Checkbox,
     Chip,
     FormControl,
     FormControlLabel,
     FormGroup,
     Grid,
     Paper,
     Table,
     TableBody,
     TableContainer,
     TableHead,
     TablePagination,
     TableRow,
     Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { StyledTableCell } from "../../../../Theme";
import { MoreIcon } from "../../../../untils/icons";
export default function GridTable({ header, data, handleChangeCheckbox, checkAll, statusSend, type }) {
     const [page, setPage] = useState(0);
     const [rowsPerPage, setRowsPerPage] = useState(10);

     const handleChangePage = (event, newPage) => {
          setPage(newPage);
     };

     const handleChangeRowsPerPage = (event) => {
          setRowsPerPage(+event.target.value);
          setPage(0);
     };

     const handleRoute = (path, detail) => {
          return () => {
               window.open(
                    `Claim13/${path}/${JSON.stringify({
                         HN: detail.HN,
                         SEQ: detail.SEQ,
                         DATEOPD: detail.DATEOPD,
                         HCODE: detail.HCODE,
                         TITLE: detail.TITLE,
                         NAMEPAT: detail.NAMEPAT,
                         INSCL: detail.INSCL,
                    })}`,
                    "_blank"
               );
          };
     };

     return (
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
               <TableContainer sx={{ maxHeight: 800 }}>
                    <Table stickyHeader aria-label="sticky table">
                         <TableHead>
                              <TableRow>
                                   <StyledTableCell align="left">
                                        {statusSend !== 2 && statusSend !== 3 ? (
                                             data.length > 0 ? (
                                                  <FormControlLabel
                                                       label="ทั้งหมด"
                                                       control={
                                                            <Checkbox
                                                                 checked={checkAll === -1 ? true : false}
                                                                 name="ทั้งหมด"
                                                                 onChange={(e) => handleChangeCheckbox(e)}
                                                            />
                                                       }
                                                  />
                                             ) : (
                                                  <FormControlLabel
                                                       label="ทั้งหมด"
                                                       control={<Checkbox checked={false} name="ทั้งหมด" onChange={(e) => handleChangeCheckbox(e)} />}
                                                  />
                                             )
                                        ) : null}
                                   </StyledTableCell>
                                   {header.map((column, i) => (
                                        <StyledTableCell key={i + 1} align={column.align} style={{ minWidth: column.minWidth }}>
                                             <Typography fontWeight="bold" fontSize="14px">
                                                  {column.label}
                                             </Typography>
                                        </StyledTableCell>
                                   ))}
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                   return (
                                        <TableRow key={i + 1}>
                                             <StyledTableCell align="left">
                                                  <FormControl component="fieldset" variant="standard">
                                                       <FormGroup>
                                                            {statusSend !== 2 && statusSend !== 3 && (
                                                                 <FormControlLabel
                                                                      control={
                                                                           <Checkbox
                                                                                onChange={(e) => handleChangeCheckbox(e)}
                                                                                checked={row.check}
                                                                                value={row.index}
                                                                                name={row.HN}
                                                                           />
                                                                      }
                                                                 />
                                                            )}
                                                       </FormGroup>
                                                  </FormControl>
                                                  <MoreIcon sx={{ cursor: "pointer", mt: "10px" }} onClick={handleRoute(`Detail`, row)} />
                                             </StyledTableCell>
                                             <StyledTableCell align="left">{row.HN ? row.HN : "N/A"}</StyledTableCell>
                                             <StyledTableCell align="left">
                                                  {row.TITLE} {row.NAMEPAT ? row.NAMEPAT.replace(`,${row.TITLE}`, "") : "N/A"}
                                             </StyledTableCell>
                                             <StyledTableCell align="left">{row.DETAIL}</StyledTableCell>
                                             <StyledTableCell align="left">
                                                  {row.medicine && row.medicine.length > 0
                                                       ? row.medicine.map((el, ind) => (
                                                            <Grid key={ind + 1} style={{ marginBottom: 3, marginTop: 3 }}>
                                                                 <Chip
                                                                      icon={<MedicalServicesIcon fontSize="small" />}
                                                                      label={el.name}
                                                                      variant="outlined"
                                                                      color="primary"
                                                                 />
                                                            </Grid>
                                                       ))
                                                       : null}
                                             </StyledTableCell>
                                             <StyledTableCell align="left">
                                                  {row.PERMITNO && row.NAMEPAT && row.pre_audit && row.pre_audit.length === 0 ? (
                                                       <Chip label="ผ่าน" color="success" />
                                                  ) : (
                                                       <>
                                                            {row.PERMITNO ? null : (
                                                                 <Chip
                                                                      sx={{ mb: 0.25 }}
                                                                      label={row.PERMITNO ? "พบเลขยืนยันตัวตน" : "ไม่พบเลขยืนยันตัวตน"}
                                                                      color={row.PERMITNO ? "success" : "error"}
                                                                 />
                                                            )}
                                                            {row.pre_audit && row.pre_audit.length === 0
                                                                 ? null
                                                                 : row.pre_audit.map((el, index) => (
                                                                      <Chip key={index + 1} label={el.name} color="error" />
                                                                 ))}
                                                       </>
                                                  )}
                                             </StyledTableCell>
                                             {(statusSend === 3 || statusSend === 99) && (
                                                  <StyledTableCell align="left">
                                                       {row.problem && <Chip label={row.problem} color="error" />}
                                                  </StyledTableCell>
                                             )}
                                             <StyledTableCell align="center">
                                                  {row.DATEOPD ? moment(row.DATEOPD).format("DD/MM/YYYY") : "N/A"}
                                             </StyledTableCell>
                                             {statusSend !== 3 && (
                                                  <StyledTableCell align="center">
                                                       {row.status_claim === 1 ? (
                                                            <Typography color="green">กำลังตรวจสอบ</Typography>
                                                       ) : row.status_claim === 2 ? (
                                                            <Typography color="green">ผ่านการตรวจสอบจาก สปสช.</Typography>
                                                       ) : row.status_claim === 3 ? (
                                                            <Typography color="red">ไม่ผ่านการตรวจสอบจาก สปสช.</Typography>
                                                       ) : (
                                                            <Typography color="green">รอส่ง</Typography>
                                                       )}
                                                  </StyledTableCell>
                                             )}
                                             <StyledTableCell align="right">
                                                  {Number(moment().year()) - Number(moment(row.DOB).year())}
                                             </StyledTableCell>
                                             <StyledTableCell align="center">{row.SEQ ? row.SEQ : "N/A"}</StyledTableCell>
                                        </TableRow>
                                   );
                              })}
                         </TableBody>
                    </Table>
               </TableContainer>
               {data.length === 0 ? (
                    <Grid container justifyContent="center" my={3}>
                         <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                    </Grid>
               ) : (
                    <TablePagination
                         rowsPerPageOptions={[10, 25, 100]}
                         component="div"
                         count={data.length}
                         rowsPerPage={rowsPerPage}
                         page={page}
                         onPageChange={handleChangePage}
                         onRowsPerPageChange={handleChangeRowsPerPage}
                         labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                    />
               )}
          </Paper>
     );
}
