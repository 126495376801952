import { Autocomplete, Box, Container, FormControl, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { Dashboard } from "../../../untils/icons";
import Barchart from "./Barchart";
import Barchart2 from "./Barchart2";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function GridCard({ dateStart, dateEnd, setCleared, setDateStart, setDateEnd, data, data2 }) {
    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={5}>
                        <Grid item xs={12} className="flex-start">
                            <Stack direction="row" spacing={2}>
                                <Dashboard fontSize="medium" />
                                <Typography className="main-header">Dashboard รายงานการเงิน</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <FormControl fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateStart)}>
                                    <DatePicker
                                        value={dateStart}
                                        slotProps={{
                                            field: { clearable: true, onClear: () => setCleared(true) },
                                        }}
                                        label="วันที่เริ่มต้น"
                                        onChange={(e) => setDateStart(e)}
                                        views={["year", "month", "day"]}
                                        renderInput={(params) => <TextField {...params} helperText={null} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <FormControl fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateEnd)}>
                                    <DatePicker
                                        // fullWidth
                                        value={dateEnd}
                                        slotProps={{
                                            field: { clearable: true, onClear: () => setCleared(true) },
                                        }}
                                        label="วันที่สิ้นสุด"
                                        onChange={(e) => setDateEnd(e)}
                                        views={["year", "month", "day"]}
                                        renderInput={(params) => <TextField {...params} helperText={null} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>

                        {/* Chart */}
                        <Grid item xs={12}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">รายได้ รพ.สต.</Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <Barchart information={data} />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">พึงรับจากโรงพยาบาล</Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <Barchart2 information={data2} />
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
}

export default GridCard;
