import React, { useState } from "react";
import { Container } from "@mui/material";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import ListDebtor from "./components/ListDebtor";

function Debtor() {
    const [loading, setLoading] = useState(false);
    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <ListDebtor />
        </Container>
    );
}

export default Debtor;
